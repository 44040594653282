import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { FetchWasteAuditCollection } from "../queries";

export function useWasteAuditCollection(collectionId: string) {
	const { data, loading } = useQuery(FetchWasteAuditCollection, {
		variables: {
			collectionId
		},
		skip: !collectionId
	});

	const collection = useMemo(() => {
		return data?.FetchWasteAuditCollection || null;
	}, [ data ]);

	return { collection, loading };
}
