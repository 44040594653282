import { useQuery } from "@apollo/client";
import { FindCountries } from "./queries";

export function useCountries() {
	const { data, loading, error } = useQuery(
		FindCountries
	);

	return {
		countries: data?.FindCountries ?? [],
		loading,
		error
	};
}