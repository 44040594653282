import React, { useMemo } from "react";
import { toProperCase } from "../../../../../../helpers";
import { WasteAuditZoneType } from "../../../../../../graphql/__generated__/graphql";
import { UseFormReturn } from "react-hook-form";
import { Box } from "grommet";
import { FormContainer, TextFieldElement, SelectElement } from "react-hook-form-mui";

export interface WasteAuditZoneFormContext {
	name: string;
	type: WasteAuditZoneType;
	totalWeight: number;
}

export const WasteAuditZoneForm: React.FC<{
	formContext: UseFormReturn<WasteAuditZoneFormContext>;
}> = ({ formContext }) => {
	const options = useMemo(() => {
		return Object.values(WasteAuditZoneType).map((value) => ({
			id: value,
			label: toProperCase(value),
		}));
	}, []);

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				<TextFieldElement
					required
					name="name"
					label="Zone Name"
					helperText="Enter a name for this zone. This name will be used in reporting and comparisons."
				/>
				<SelectElement
					required
					name="type"
					label="Zone Type"
					options={options}
					helperText="Select the type of this zone. Use Other if you don't see the type you need."
				/>
				<TextFieldElement
					name="totalWeight"
					label="Total Weight"
					type="number"
					inputMode="numeric"
					validation={{
						min: { value: 0, message: "Total weight must be greater than 0" },
					}}
					helperText="Use this field if you weighed all bags / waste from this zone at once, instead of individually by bag."
				/>
			</Box>
		</FormContainer>
	);
};