import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { loadFromStorage, SimpleAddress } from "../hooks";
import moment from "moment-timezone";
import { OnboardingStepId } from "../features/onboarding/components";
import { defaultThemeState } from "../provider";
import { v4 as uuid } from "uuid";
import { BusinessType, InstanceOnboardingIntent, IUtm } from "../graphql/__generated__/graphql";

export interface BusinessTypeDefinition {
	id: BusinessType;
	label: string;
	description: string;
}

export enum Disposition {
	REUSE = "Reuse",
	RESALE = "Resale",
	RECYCLE = "Recycle",
	LANDFILL = "Landfill",
	DONATION = "Donation"
}

export type Frequnecy =
	| "DAILY"
	| "WEEKLY"
	| "MONTHLY"
	| "QUARTERLY";

interface MaterialHandling {
	id: string;
	dispositions: Disposition[];
}

export interface BusinessBranding {
	logoMediaId: string;
	primaryColor: string;
	secondaryColor: string;
	headerColor: string;
	accentColor: string;
}

export interface Tracking {
	utm: IUtm,
	intent: InstanceOnboardingIntent;
}

export interface OnboardingState extends SimpleAddress, BusinessBranding {
	isPolling: boolean;
	isPollingTimeout: boolean;
	isSubmitting: boolean;
	submittedAtTimestamp: number;
	validatedAtTimestamp: number;
	isFailureState: boolean;
	failedAtTimestamp: number;
	instanceId: string;
	instanceDomain: string;
	onboardingId: string;
	requestId: string;
	subdomain: string;
	materials: MaterialHandling[];
	businessName: string;
	businessType: BusinessType | null;
	activeStep: OnboardingStepId;
	tracking: Tracking | null;
}

const initialState: OnboardingState = loadFromStorage("onboarding", {
	failedAtTimestamp: 0,
	submittedAtTimestamp: 0,
	validatedAtTimestamp: 0,
	isPolling: false,
	isPollingTimeout: false,
	isSubmitting: false,
	isFailureState: false,
	tracking: null,
	instanceId: "",
	instanceDomain: "",
	onboardingId: "",
	requestId: uuid(),
	subdomain: "",
	activeStep: "BUSINESS_INFORMATION",
	materials: [],
	businessName: "",
	businessType: null,
	addressLineOne: "",
	addressLineTwo: "",
	locality: "",
	administrativeArea: "",
	postalCode: "",
	lat: "",
	lng: "",
	timezone: moment.tz.guess(),
	logoMediaId: "",
	primaryColor: defaultThemeState.primaryColor,
	secondaryColor: defaultThemeState.secondaryColor,
	headerColor: defaultThemeState.headerColor,
	accentColor: defaultThemeState.accentColor,
	completedWasteProfile: false,
	completedBusinessInformation: false,
	completedReportingCustomization: false
});

export const onboardingSlice = createSlice({
	name: "onboarding",
	initialState,
	reducers: {
		setIsPolling: (state, action: PayloadAction<boolean>) => {
			state.isPolling = action.payload;
		},
		setIsSubmitting: (state, action: PayloadAction<boolean>) => {
			state.isSubmitting = action.payload;
		},
		setSubmittedAtTimestamp: (state, action: PayloadAction<number>) => {
			state.submittedAtTimestamp = action.payload;
		},
		setValidatedAtTimestamp: (state, action: PayloadAction<number>) => {
			state.validatedAtTimestamp = action.payload;
		},
		setIsFailureState: (state, action: PayloadAction<boolean>) => {
			state.isFailureState = action.payload;
		},
		setFailedAtTimestamp: (state, action: PayloadAction<number>) => {
			state.failedAtTimestamp = action.payload;
		},
		setTracking: (state, action: PayloadAction<Tracking>) => {
			state.tracking = action.payload;
		},
		setLogoMediaId: (state, action: PayloadAction<string>) => {
			state.logoMediaId = action.payload;
		},
		setBusinessName: (state, action: PayloadAction<string>) => {
			state.businessName = action.payload;
		},
		setBusinessType: (state, action: PayloadAction<BusinessType>) => {
			state.businessType = action.payload;
		},
		setCreateResult: (state, action: PayloadAction<{ instanceId: string; instanceDomain: string; onboardingId: string; }>) => {
			state.instanceId = action.payload.instanceId;
			state.instanceDomain = action.payload.instanceDomain;
			state.onboardingId = action.payload.onboardingId;
		},
		setBusinessAddress: (state, action: PayloadAction<SimpleAddress>) => {
			state.addressLineOne = action.payload.addressLineOne;
			state.addressLineTwo = action.payload.addressLineTwo;
			state.locality = action.payload.locality;
			state.administrativeArea = action.payload.administrativeArea;
			state.postalCode = action.payload.postalCode;
			state.lat = action.payload.lat;
			state.lng = action.payload.lng;
			state.timezone = action.payload.timezone;
		},
		toggleMaterialDisposition: (state, action: PayloadAction<{ id: string, disposition: Disposition; }>) => {
			const existing = state.materials.find((material) => material.id === action.payload.id);
			if(existing) {
				state.materials = state.materials.map((material) => {
					if(material.id === action.payload.id) {
						const index = material.dispositions.indexOf(action.payload.disposition);
						if(index === -1) {
							material.dispositions.push(action.payload.disposition);
						}
						else {
							material.dispositions.splice(index, 1);
						}
					}
					return material;
				});
			}
			else {
				state.materials.push({
					id: action.payload.id,
					dispositions: [ action.payload.disposition ]
				});
			}
		},
		updateMaterialDispositions: (state, action: PayloadAction<{ id: string; dispositions: Disposition[]; }>) => {
			const existing = state.materials.find((material) => material.id === action.payload.id);
			if(existing) {
				state.materials = state.materials.map((material) => {
					if(material.id === action.payload.id) {
						material.dispositions = action.payload.dispositions;
					}
					return material;
				});
			}
			else {
				state.materials = [
					...state.materials,
					{
						id: action.payload.id,
						dispositions: action.payload.dispositions
					}
				];
			}
		},
		setActiveStep: (state, action: PayloadAction<OnboardingStepId>) => {
			state.activeStep = action.payload;
		},
		upsertOnboardingState: (state, action: PayloadAction<Partial<OnboardingState>>) => {
			Object.assign(state, action.payload);
		},
		replaceOnboardingState: (state, action: PayloadAction<OnboardingState>) => {
			console.log("replaceOnboardingState", action.payload);
			state = {
				...state,
				...action.payload
			};
		}
	}
});

export const {
	setIsPolling,
	setIsSubmitting,
	setIsFailureState,
	setFailedAtTimestamp,
	setSubmittedAtTimestamp,
	setTracking,
	setCreateResult,
	setActiveStep,
	setLogoMediaId,
	setBusinessName,
	setBusinessType,
	setBusinessAddress,
	upsertOnboardingState,
	replaceOnboardingState,
	toggleMaterialDisposition,
	updateMaterialDispositions
} = onboardingSlice.actions;

export const selectOnboardingState = (state: RootState): OnboardingState => state.onboarding;

export const selectOnboardingAddress = (state: RootState): SimpleAddress => ({
	addressLineOne: state.onboarding.addressLineOne,
	addressLineTwo: state.onboarding.addressLineTwo,
	locality: state.onboarding.locality,
	administrativeArea: state.onboarding.administrativeArea,
	postalCode: state.onboarding.postalCode,
	lat: state.onboarding.lat,
	lng: state.onboarding.lng,
	timezone: state.onboarding.timezone
});

export default onboardingSlice.reducer;
