import { gql } from "../../../__generated__";

export const CreateWasteAudit = gql(/* GraphQL */ `
	mutation CreateWasteAudit(
		$name: String!
		$type: WasteAuditType!
		$scheduledDate: DateTime
		$partnerId: String!
		$instanceId: String!
	) {
		CreateWasteAudit(
			name: $name
			type: $type
			scheduledDate: $scheduledDate
			partnerId: $partnerId
			instanceId: $instanceId
		) {
			id
		}
	}
`);