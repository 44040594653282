import { gql } from "@apollo/client";

export const WasteAudit_CollectionsFragment = gql`
	fragment WasteAudit_CollectionsFragment on WasteAudit {
		collections {
			id
			createdAt
			label
			totalWeight
			disposition
			identificationStarted
			identificationCompleted
			media {
				id
				contentUrl
			}
			container {
				id
				name
			}
			metadata {
				roomName
				roomNumber
				floorName
				floorNumber
				officeName
				officeNumber
				containerName
				containerLocation
			}
		}
	}
`;