import { Box, Stack } from "grommet";
import React, { Fragment, useMemo, useState } from "react";
import { AddressDialog, BuildingAddressListItem, Pagination, PaymentMethodsList, StandardAddressListItem, Step, StepControls, UpdateAddressDTO, useSteps } from "../../../components";
import { CheckoutStepProps } from "../types";
import { CheckoutListItem, CheckoutTitleContainer, CheckoutViewContainer } from "../components";
import { Button, Chip, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState, setPaymentMethod } from "../../../store/checkout";
import { ItemReviewListItem } from "../steps";
import { ContactType, InstanceType, ProductIntent } from "../../../graphql/__generated__/graphql";
import { useInstance, useTimezone, useWindowDimensions } from "../../../hooks";
import { useResident, useUser } from "../../../auth";
import { useCheckoutSession, useCheckoutSessionAddress, useCheckoutSessionEstimate } from "../hooks";
import moment from "moment-timezone";
import { dateStringToMoment } from "./CheckoutScheduleUpfrontView";
import { formatContact, formatCurrency } from "../../../helpers";
import { Autorenew, Cancel, CheckCircle, Circle, CreditCard, Email, PhoneIphone, RemoveCircle } from "@mui/icons-material";
import { CreatePaymentMethodButton } from "../../payments";
import { useMutation } from "@apollo/client";
import { CreateAndSubmitOrder } from "../../../graphql";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { useSnackbar } from "notistack";
import { push } from "redux-first-history";
import { LoginInteruptController } from "../controller";
import { ContactFormController } from "../../user";
import { useCheckoutCustomizer } from "../../../hooks/customizer/useCustomizer";

export const ReviewPickupAddressContainer: React.FC = () => {
	const { instance } = useInstance();
	const { building } = useResident();
	const { referer } = useCheckoutSession();
	const { address, update } = useCheckoutSessionAddress();
	const [ isUpdatingAddress, setIsUpdatingAddress ] = useState(false);

	const addressListItem = useMemo(() => {
		if(!address) return null;

		switch(instance?.type) {
			case InstanceType.Residence:
			case InstanceType.ManagementCompany: {
				return (
					<BuildingAddressListItem
						onEdit={() => {
							setIsUpdatingAddress(true);
						}}
						onSelect={() => {
							setIsUpdatingAddress(true);
						}}
						onDelete={() => {
							//
						}}
						isLoading={false}
						selected={true}
						buildingName={building?.name || referer?.name || ""}
						unitPrefix={building?.unitPrefix ?? "Unit"}
						unit={address?.addressLineTwo ?? ""}
						addressId={address?.id ?? ""}
					/>
				);
			}
			default: {
				return (
					<StandardAddressListItem
						address={address as any}
						onEdit={() => {
							setIsUpdatingAddress(true);
						}}
						onSelect={() => {
							setIsUpdatingAddress(true);
						}}
						onDelete={() => {
							//
						}}
						isLoading={false}
						selected={true}
					/>
				);
			}

		}
	}, [ instance, building, address, isUpdatingAddress ]);


	function handleUpdateAddress(address: UpdateAddressDTO): void {
		update(address);
	}

	return (
		<Box gap="medium">
			{isUpdatingAddress && (
				<AddressDialog
					isResidenceAddress={!!building}
					address={address ?? undefined}
					onClose={() => setIsUpdatingAddress(false)}
					isCreating={false}
					isUpdating={false}
					createAddress={(address) => {
						return;
					}}
					updateAddress={(address) => {
						handleUpdateAddress({
							...address,
							id: address.id ?? ""
						});

						setIsUpdatingAddress(false);
					}}
				/>
			)}
			<Typography fontWeight="bold">
				Pickup Address
			</Typography>
			{address && (
				<>
					{addressListItem}
				</>
			)}
		</Box>
	);
};

export const ReviewCheckoutSessionEstimateContainer: React.FC = () => {
	const { products } = useAppSelector(selectCheckoutState);
	const { estimate } = useCheckoutSessionEstimate();

	const subtotalAmount = useMemo(() => {
		return estimate?.subtotalAmount ?? 0;
	}, [ estimate ]);

	const strikethroughSubtotal = useMemo(() => {
		const strikethroughAmount = subtotalAmount * (1 + .40);
		return strikethroughAmount + (5 - (strikethroughAmount % 5));
	}, [ estimate ]);

	return (
		<Box gap="medium">
			<Typography fontWeight="bold">
				Pickup Quote
			</Typography>
			<Box gap="small">
				<CheckoutListItem
					title="Subtotal"
					subtitle={`${products.reduce((acc, product) => product.quantity + acc, 0)} item(s)`}
					details={(
						<Box gap="xsmall">
							<Typography color="error" style={{ textDecoration: "line-through" }} fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
								{formatCurrency(strikethroughSubtotal ?? 0)}
							</Typography>
							<Typography sx={{ width: "80px", textAlign: "end" }}>
								{formatCurrency(estimate?.subtotalAmount ?? 0)}
							</Typography>
						</Box>
					)}
				/>
				{(estimate?.discountAmount || 0) > 0 && (
					<CheckoutListItem
						title="Discounts"
						subtitle="Description Here"
						details={(
							<Box direction="row" gap="small">
								<RemoveCircle />
								<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
									{formatCurrency(estimate?.discountAmount ?? 0)}
								</Typography>
							</Box>
						)}
					/>
				)}
				{(estimate?.creditUsedAmount || 0) > 0 && (
					<CheckoutListItem
						title="Credits Used"
						subtitle={`Remaining: ${formatCurrency(estimate?.creditRemainingAmount ?? 0)}`}
						details={(
							<Box direction="row" gap="small">
								<RemoveCircle />
								<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
									{formatCurrency(estimate?.creditUsedAmount ?? 0)}
								</Typography>
							</Box>
						)}
					/>
				)}
				<CheckoutListItem
					title="Total Amount"
					subtitle={`Taxes: ${formatCurrency(estimate?.taxAmount ?? 0)}`}
					details={(
						<Box direction="row" gap="small" justify="center">
							<Box justify="center">
								<Stack alignSelf="center">
									<Box justify="center">
										<Circle />
									</Box>
									<Box align="center" justify="center" flex>
										<Typography fontSize="large" fontWeight="bold" color="white">
											=
										</Typography>
									</Box>
								</Stack>
							</Box>
							<Box justify="center">
								<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
									{formatCurrency(estimate?.totalAmount ?? 0)}
								</Typography>
							</Box>
						</Box>
					)}
				/>
			</Box>
		</Box>
	);
};


export const ReviewCheckoutSessionPickupContainer: React.FC = () => {
	const { size } = useWindowDimensions();
	const { force } = useSteps("checkout");
	const timezone = useTimezone();
	const { pickup, cutoffDate, isUpfrontScheduleEnabled } = useAppSelector(selectCheckoutState);

	function getDaysUntilPickupMessage(date: string): string {
		const diff = moment.tz(date, "YYYY-MM-DD", timezone).diff(moment().startOf("day"), "days");
		if(diff === 0) return "Today";
		if(diff === 1) return "Tomorrow";
		return `In ${diff} days`;
	}

	const defaultFormat = useMemo(() => {
		switch(size) {
			case "small": {
				return "ddd, MMM Do";
			}
			default: {
				return "dddd, MMMM Do";
			}
		}
	}, [ size ]);

	const scheduledDateFormatted = useMemo(() => {
		return (pickup && pickup.scheduledDate)
			? dateStringToMoment(pickup.scheduledDate).format(defaultFormat)
			: (cutoffDate)
				? dateStringToMoment(cutoffDate).format(defaultFormat)
				: "";
	}, [ pickup ]);

	const daysFromNow = useMemo(() => {
		return (pickup && pickup.scheduledDate)
			? getDaysUntilPickupMessage(pickup.scheduledDate)
			: (cutoffDate)
				? getDaysUntilPickupMessage(cutoffDate)
				: "";
	}, [ pickup ]);

	return (
		<Fragment>
			{(pickup || cutoffDate) && (
				<Box gap="small">
					<Typography fontWeight="bold">
						Pickup Details
					</Typography>
					<CheckoutListItem
						title={isUpfrontScheduleEnabled
							? "Schedueled Date"
							: "Cutoff Date"
						}
						subtitle={daysFromNow}
						details={scheduledDateFormatted}
						onClick={() => {
							force(1);
						}}
					/>
				</Box>
			)}
		</Fragment>
	);
};


export const ReviewPaymentMethodContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const { estimate } = useCheckoutSessionEstimate();
	const { paymentMethod } = useAppSelector(selectCheckoutState);

	function handleSelectPaymentMethod(id: string): void {
		dispatch(setPaymentMethod(id));
	}

	return (
		<Fragment>
			{(estimate && estimate.totalAmount > 0) && (
				<Box gap="medium">
					<Box>
						<Typography fontWeight="bold">
							Payment Method
						</Typography>
						<Typography variant="caption">
							Select your preferred payment method for this pickup. Your card will be charged {formatCurrency(estimate.totalAmount)} once your pickup is completed.
						</Typography>
					</Box>
					<PaymentMethodsList
						showCheckboxSelection
						isSelectingId={""}
						selectedId={paymentMethod ?? ""}
						onSelect={(id) => {
							handleSelectPaymentMethod(id);
						}}
					/>
					<Box align="center">
						<CreatePaymentMethodButton
							label="Add Payment Method"
							icon={<CreditCard />}
							variant="outlined"
						/>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

export const ContactListItem: React.FC<{
	divider?: boolean;
	selected?: boolean;
	value: string;
	contactType: ContactType;
	isMissing: boolean;
	isVerified: boolean;
}> = ({ divider, selected, value, contactType, isMissing, isVerified }) => {
	const [ isSelecting, setIsSelecting ] = useState<"CREATE" | "SELECT">();

	return (
		<Fragment>
			{isSelecting && (
				<ContactFormController
					type={contactType}
					kind={isSelecting}
					onClose={() => setIsSelecting(undefined)}
				/>
			)}
			<ListItemButton
				divider={divider}
				selected={selected}
				sx={{ minHeight: "75px" }}
			>
				<ListItemIcon>
					{contactType === ContactType.Email
						? (
							<Email color="primary" />
						)
						: (
							<PhoneIphone color="primary" />
						)}
				</ListItemIcon>
				<ListItemText>
					{isMissing && (
						<Button
							color="warning"
							variant="contained"
							onClick={() => {
								setIsSelecting("CREATE");
							}}
						>
							Add {contactType === ContactType.Email ? "Email" : "Phone"}
						</Button>
					)}
					{!isMissing && (
						<Box gap="xxsmall">
							<Typography fontWeight="bold">
								{value ? formatContact(value) : ""}
							</Typography>
							<Box align="start">
								{isVerified
									? (
										<Chip
											size="small"
											color="success"
											label="Verified"
											icon={<CheckCircle />}
										/>
									)
									: (
										<Chip
											size="small"
											color="error"
											label="Not Verified"
											icon={<Cancel />}
										/>
									)}
							</Box>
						</Box>
					)}
				</ListItemText>
				<ListItemSecondaryAction>
					{!isMissing && (
						<IconButton onClick={() => {
							setIsSelecting("SELECT");
						}}>
							<Autorenew />
						</IconButton>
					)}
				</ListItemSecondaryAction>
			</ListItemButton>
		</Fragment>
	);
};

export const PrimaryEmailListItem: React.FC = () => {
	const { contacts } = useUser();

	const primaryEmail = useMemo(() => {
		return contacts.find((contact) => contact.isPrimary && contact.type === ContactType.Email);
	}, [ contacts ]);

	return (
		<ContactListItem
			selected
			value={primaryEmail?.value || ""}
			contactType={ContactType.Email}
			isMissing={!primaryEmail}
			isVerified={primaryEmail?.isVerified || false}
		/>
	);
};

export const PrimaryPhoneListItem: React.FC = () => {
	const { contacts } = useUser();

	const primaryPhone = useMemo(() => {
		return contacts.find((contact) => contact.isPrimary && contact.type === ContactType.Phone);
	}, [ contacts ]);

	return (
		<ContactListItem
			selected
			value={primaryPhone?.value || ""}
			contactType={ContactType.Phone}
			isMissing={!primaryPhone}
			isVerified={primaryPhone?.isVerified || false}
		/>
	);
};

export const CheckoutReviewView: React.FC<CheckoutStepProps> = (props) => {
	const { user } = useUser();
	const { instance } = useInstance();
	const { products, address, pickup, scheduledDate, scheduledWindow, paymentMethod, referer, isUpfrontPricingEnabled } = useAppSelector(selectCheckoutState);

	const { estimate } = useCheckoutSessionEstimate();

	const [
		submit,
		{ loading: isSubmitting }
	] = useMutation(CreateAndSubmitOrder, {
		refetchQueries: [

		]
	});

	const variables = useMemo((): VariablesOf<typeof CreateAndSubmitOrder> => {
		return {
			userId: user?.id || "",
			refererId: referer?.id || "",
			instanceId: instance?.id || "",
			pickupId: pickup?.id || "",
			scheduledDate: scheduledDate || "",
			scheduledWindow: scheduledWindow || undefined,
			paymentMethodId: paymentMethod || "",
			products: products.map((product) => ({
				intent: product.intent || ProductIntent.Donation,
				typeId: product.type?.id || "",
				sizeId: product.size?.id || "",
				quantity: product.quantity,
				quantityDisassembly: product.quantityDisassembly,
				media: product.media || []
			})),
			address: {
				countryCode: address?.countryCode || "US",
				addressLineOne: address?.addressLineOne || "",
				addressLineTwo: address?.addressLineTwo || "",
				locality: address?.locality || "",
				administrativeArea: address?.administrativeArea || "",
				postalCode: address?.postalCode || "",
				lat: address?.lat || "",
				lng: address?.lng || "",
				timezone: address?.timezone || "",
				userId: user?.id || "",
				floor: Number(address?.floor || 1) || 1,
				hasParking: address?.hasParking || false,
				hasElevator: address?.hasElevator || false,
			}
		};
	}, [ products, paymentMethod, estimate ]);

	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	function handleSubmitOrder(): void {
		if(!user?.primaryPhoneNumber) {
			snack.enqueueSnackbar(
				"Please add a phone number to your account before submitting your order.",
				{ variant: "error" }
			);
			return;
		}

		if(!user?.primaryEmailAddress) {
			snack.enqueueSnackbar(
				"Please add an email address to your account before submitting your order.",
				{ variant: "error" }
			);
			return;
		}

		submit({
			variables
		}).then((res) => {
			snack.enqueueSnackbar(
				"Your pickup request has been submitted! You'll receive a confirmation email shortly.",
				{ variant: "success" }
			);

			dispatch(push("/dashboard"));
			window.location.reload();
			localStorage.removeItem("checkout-session");
		}).catch(err => {
			console.error("Failed to submit order", err);
			snack.enqueueSnackbar(
				"We ran into an issue submitting your order. Please try again.",
				{ variant: "error" }
			);
		});
	}


	const {
		review,
		contact
	} = useCheckoutCustomizer();

	return (
		<LoginInteruptController loginRequired={props.loginRequired}>
			<Step stepName={props.stepName}>
				<CheckoutViewContainer disableOverflow>
					<Box gap="medium">
						<CheckoutTitleContainer
							heading={review.heading || "Order Review"}
							subheading={review.subheading || "Review your order details before submitting."}
						/>
						<Box style={{ display: "block" }} height="100%" overflow={{ vertical: "auto" }} gap="small" margin={{ top: "medium" }}>
							<Typography fontWeight="bold">
								{contact.heading || "Contact Details"}
							</Typography>
							{contact.subheading && (
								<Typography variant="caption">
									{contact.subheading}
								</Typography>
							)}
							<PrimaryEmailListItem />
							<PrimaryPhoneListItem />
							<Box gap="small">
								<ReviewPickupAddressContainer />
								<Typography fontWeight="bold">
									Your Items
								</Typography>
								<Pagination pageSize={5}>
									{products.map((product) => (
										<ItemReviewListItem
											typeId={product.type?.id || ""}
											sizeId={product.size?.id || ""}
											hideActions
										/>
									))}
								</Pagination>
							</Box>
							<ReviewCheckoutSessionPickupContainer

							/>
							{isUpfrontPricingEnabled && (
								<ReviewCheckoutSessionEstimateContainer

								/>
							)}
							{(isUpfrontPricingEnabled && (estimate?.totalAmount || 0 > 0)) && (
								<ReviewPaymentMethodContainer />
							)}
						</Box>
					</Box>
					<Box justify="end">
						<StepControls
							name="checkout"
							onSubmit={handleSubmitOrder}
							canProceed={isUpfrontPricingEnabled
								? !!estimate?.totalAmount && !!paymentMethod && !isSubmitting
								: !isSubmitting
							}
							isLoading={isSubmitting}
						/>
					</Box>
				</CheckoutViewContainer>
			</Step>
		</LoginInteruptController>
	);
};