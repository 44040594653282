import React, { useMemo } from "react";
import { Box, Grid } from "grommet";
import { Button, Chip, IconButton, ListItemButton, ListItemText, Tooltip, Typography, useTheme } from "@mui/material";
import { useWasteAuditZone } from "../../../../../../graphql";
import { useWindowDimensions } from "../../../../../../hooks";
import { Pagination } from "../../../../../../components";
import { Edit, NotificationAdd } from "@mui/icons-material";
import { ZoneMetricsContainer } from "./ZoneMetricsContainer";
import { CreateWasteAuditCollectionDialog, UpdateWasteAuditCollectionDialog } from "../collections";
import { toProperCase } from "../../../../../../helpers";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../../../../store";

export const ZoneTabContent: React.FC<{
	zoneId: string;
}> = ({ zoneId }) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { size, width } = useWindowDimensions();
	const { zone, loading } = useWasteAuditZone(zoneId);

	const collections = useMemo(() => {
		return zone?.collections || [];
	}, [ zone ]);

	const [ isCreating, setIsCreating ] = React.useState(false);
	const [ isUpdating, setIsUpdating ] = React.useState("");

	return (
		<Grid
			height="300px"
			columns={width < 1100 ? { count: 1, size: "auto" } : [ "2/3", "1/3" ]}
			gap="small"
			pad={{ right: "small" }}
		>
			{isCreating && (
				<CreateWasteAuditCollectionDialog
					zoneId={zoneId}
					onClose={() => setIsCreating(false)}
				/>
			)}
			{isUpdating && (
				<UpdateWasteAuditCollectionDialog
					collectionId={isUpdating}
					onClose={() => setIsUpdating("")}
				/>
			)}
			<Box gap="small" height="100%">
				{collections.length === 0 && (
					<Box gap="small" align="center" justify="center" flex>
						<Typography>
							no collections found
						</Typography>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => setIsCreating(true)}
						>
							Upload Now
						</Button>
					</Box>
				)}
				{collections.length > 0 && (
					<Pagination
						pageSize={3}
						listProps={{
							disablePadding: true
						}}
					>
						{collections.map((collection) => (
							<ListItemButton
								key={collection.id}
								onClick={() => {
									dispatch(push(`/admin/inspector/${collection.id}`));
								}}
								sx={{
									":hover": {
										background: theme.palette.grey[ 200 ]
									},
									border: "1px solid",
									background: theme.palette.grey[ 100 ],
									borderColor: theme.palette.grey[ 300 ],
									borderRadius: 0,
									":last-child": {
										borderBottomLeftRadius: theme.shape.borderRadius,
										borderBottomRightRadius: theme.shape.borderRadius,
									},
									":first-of-type": {

										borderTopLeftRadius: theme.shape.borderRadius,
										borderTopRightRadius: theme.shape.borderRadius
									},
									"&:not(:last-child)": {
										borderBottom: 0
									}
								}}
							>
								<Box width="100%">
									<Box direction="row" align="center" justify="between">
										<ListItemText
											primary={(
												<Typography
													variant="body1"
													className="text-max-1-line"
												>
													{collection.label}
												</Typography>
											)}
											secondary={toProperCase(collection.disposition)}
											secondaryTypographyProps={{
												className: "text-max-1-line"
											}}
										/>
										<Box direction="row" align="center" justify="end">
											{zone && size !== "small" && (zone?.totalWeight || 0) > 0 && (
												<Typography
													fontWeight="bold"
													fontSize="1.25rem"
													mr={1}
												>
													{zone.totalWeight} lb(s)
												</Typography>
											)}
											{collection.media.length === 0 && size !== "small" && (
												<Chip
													color="warning"
													label={(
														<Typography
															variant="body2"
														// fontWeight="bold"
														>
															Needs Image(s)
														</Typography>
													)}

												/>
											)}
											{collection.media.length === 0 && size === "small" && (
												<Tooltip
													title="Needs Image(s)"
												>
													<IconButton
														color="warning"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
														}}
													>
														<NotificationAdd />
													</IconButton>
												</Tooltip>
											)}
											<IconButton
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setIsUpdating(collection.id);
												}}
											>
												<Edit />
											</IconButton>
										</Box>
									</Box>
								</Box>
							</ListItemButton>
						))}
					</Pagination>
				)}
			</Box>
			{width >= 1100 && (
				<ZoneMetricsContainer
					zoneId={zoneId}
				/>
			)}
		</Grid>
	);
};