import { gql } from "@apollo/client";

export const WasteAudit_HeaderFragment = gql`
	fragment WasteAudit_HeaderFragment on WasteAudit {
		id
		name
		type
		createdAt
		partner { id name }
		published
		publishedAt
		scheduledDate
	}
`;