import React, { Fragment } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
	AccordionSummaryProps,
	accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ArrowForwardIosSharp, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, styled, Typography } from "@mui/material";
import { Box } from "grommet";
import { UpdateWasteAuditZoneDialog } from "./UpdateWasteAuditZoneDialog";
import { useWasteAuditZone } from "../../../../../../graphql";
import { useWindowDimensions } from "../../../../../../hooks";
import { CreateWasteAuditCollectionDialog } from "../collections";

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
		borderTopLeftRadius: theme.shape.borderRadius,
		borderTopRightRadius: theme.shape.borderRadius
	},
	"&:not(:first-of-type)": {
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderBottomRightRadius: theme.shape.borderRadius
	},
	"&::before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "1rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: "rgba(0, 0, 0, .03)",
	flexDirection: "row-reverse",
	[ `& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}` ]:
	{
		transform: "rotate(90deg)",
	},
	[ `& .${accordionSummaryClasses.content}` ]: {
		marginLeft: theme.spacing(1),
	},
	...theme.applyStyles("dark", {
		backgroundColor: "rgba(255, 255, 255, .05)",
	}),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface ZoneAccordionProps {
	zoneId: string;
	summary: string;
	children: React.ReactNode;
}

export const ZoneAccordion: React.FC<ZoneAccordionProps> = ({ zoneId, summary, children }) => {
	const { size } = useWindowDimensions();
	const [ isCreating, setIsCreating ] = React.useState(false);
	const [ isEditing, setIsEditing ] = React.useState(false);
	const [ isDeleting, setIsDeleting ] = React.useState(false);

	const { zone } = useWasteAuditZone(zoneId);

	return (
		<Fragment>
			{isEditing && (
				<UpdateWasteAuditZoneDialog
					zoneId={zoneId}
					onClose={() => setIsEditing(false)}
				/>
			)}
			{isCreating && (
				<CreateWasteAuditCollectionDialog
					zoneId={zoneId}
					onClose={() => setIsCreating(false)}
				/>
			)}
			<Accordion>
				<AccordionSummary>
					<Box direction="row" justify="between" align="center" flex>
						<Typography
							fontWeight="bold"
							fontSize="1.25rem"
							className="text-max-1-line"
						>
							{summary}
						</Typography>
						<Box direction="row" align="center" gap="xxsmall">
							{size !== "small" && zone && (zone?.totalWeight || 0) > 0 && (
								<Typography
									fontWeight="bold"
									fontSize="1.25rem"
									mr={1}
								>
									{zone.totalWeight} lb(s)
								</Typography>
							)}
							<Button
								size="small"
								variant="outlined"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setIsCreating(true);
								}}
							>
								Upload
							</Button>
							<IconButton
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setIsEditing(true);
								}}
							>
								<Edit />
							</IconButton>
							<IconButton color="error">
								<Delete />
							</IconButton>
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					{children}
				</AccordionDetails>
			</Accordion>
		</Fragment>
	);
};
