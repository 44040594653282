import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CreateWasteAuditCollection, FetchWasteAudit, FetchWasteAuditZone } from "../../../../../../graphql";
import { useSnackbar } from "notistack";
import { WasteAuditCollectionFormContext } from "./WasteAuditCollectionForm";
import { WasteAuditCollectionDialogCommon } from "./WasteAuditCollectionDialogCommon";
import { ProductDisposition } from "../../../../../../graphql/__generated__/graphql";

export const CreateWasteAuditCollectionDialog: React.FC<{
	zoneId: string;
	onClose: () => void;
}> = ({ zoneId, onClose }) => {
	const snack = useSnackbar();
	const formContext = useForm<WasteAuditCollectionFormContext>({
		defaultValues: {
			label: "",
			disposition: null,
			totalWeight: 0,
			roomName: "",
			containerName: "",
			media: [],
			mediaUploadQueue: []
		},
	});

	const [
		create,
		{ loading: isCreating }
	] = useMutation(CreateWasteAuditCollection, {
		refetchQueries: [
			FetchWasteAudit,
			FetchWasteAuditZone
		]
	});

	const onSubmit = useCallback(() => {
		const {
			label,
			disposition,
			mediaUploadQueue,
			totalWeight,
			roomName,
			containerName
		} = formContext.getValues();

		return create({
			variables: {
				zoneId,
				label,
				metadata: {
					roomName: roomName || "",
					roomNumber: "",
					containerNumber: "",
					containerName: containerName || "",
					containerLocation: "",
				},
				disposition: disposition || ProductDisposition.Landfill,
				media: (mediaUploadQueue || []).map(media => ({
					name: media.name,
					contentType: media.contentType,
					content: media.base64Content,
					context: media.context
				})),
				totalWeight: totalWeight || 0,
			}
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to create waste audit collection", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}, [ create, formContext, onClose, snack, zoneId ]);

	return (
		<WasteAuditCollectionDialogCommon
			title="Upload Data"
			loading={false}
			onClose={onClose}
			formContext={formContext}
			onSubmit={onSubmit}
			onSubmitLabel="Create"
			isSubmitting={isCreating}
		/>
	);
};