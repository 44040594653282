import { gql } from "../../../__generated__";

export const CreateWasteAuditZone = gql(/* GraphQL */ `
	mutation CreateWasteAuditZone (
		$name: String!
		$auditId: String!
		$totalWeight: Float!
		$type: WasteAuditZoneType!
	) {
		CreateWasteAuditZone(
			name: $name
			type: $type
			auditId: $auditId
			totalWeight: $totalWeight
		) {
			id
		}
	}
`);

export const UpdateWasteAuditZone = gql(/* GraphQL */ `
	mutation UpdateWasteAuditZone(
		$zoneId: String!
		$name: String
		$totalWeight: Float
		$type: WasteAuditZoneType
	) {
		UpdateWasteAuditZone(
			zoneId: $zoneId
			name: $name
			type: $type
			totalWeight: $totalWeight
		) {
			id
		}
	}
`);