import { gql } from "@apollo/client";

export const WasteAuditZone_MetricsFragment = gql`
	fragment WasteAuditZone_MetricsFragment on WasteAuditZone {
		metrics {
			aggregateBy
			diversionRate
			contaminationRate
			potentialDiversionRate
			potentialDiversionRate
			totalWeightDiverted
			totalWeightGenerated
			totalWeightContamination
			totalWeightDivertedPotential
		}
	}
`;