import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { Box, Grid, Heading } from "grommet";
import { Button, CircularProgress, Step, Stepper, Typography } from "@mui/material";
import { useSyncState, useWindowDimensions } from "../../../hooks";
import { useOnboardingFormContext } from "../hooks";
import { DomainNameElement, OnboardingStepLabel, OnboardingViewContainer, OrganizationNameElement, OrganizationTypeElement, StepContentRenderer, STEPS, useOnboardingSteps } from "../components";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectOnboardingState, selectOnboardingAddress } from "../../../store/onboarding";
import { BusinessInformationView, ReportingCustomizationView, WasteProfileView } from "../views";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CheckOnboardingStatus, CreateInstanceOnboardingRequest } from "../../../graphql";
import { UseFormReturn } from "react-hook-form";
import { OnboardingFormContext } from "../types";
import { useTracking } from "../hooks/useTracking";
import { LoginInteruptController } from "../../checkout/controller";
import { CallUsButton, DialogWithClose, StandardAddressListItem, TextUsButton } from "../../../components";
import { noop } from "../../../helpers";

export const FailureStateDialog: React.FC = () => {
	const { isPollingTimeout } = useAppSelector(selectOnboardingState);
	const failureMessage = useMemo(() => {
		if(isPollingTimeout) {
			return "It's taking longer than expected to get your account setup. We're currently working on it and will be in touch soon. In the meantime, if you have any questions, please give us a call or send us a text.";
		}

		return "We ran into an issue while setting up your account. We're currently working on it and will be in touch soon. In the meantime, if you have any questions, please give us a call or send us a text.";
	}, [ isPollingTimeout ]);

	return (
		<DialogWithClose
			hideTitle
			title="We ran into an issue"
			onClose={() => {
				//
			}}
			content={(
				<Box gap="medium">
					<Heading margin="none" level="3">
						Something Went Wrong
					</Heading>
					<Typography>
						{failureMessage}
					</Typography>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<CallUsButton />
					<TextUsButton />
				</Box>
			)}
		/>
	);
};

export function useOnboardingStatusPoller(formContext: UseFormReturn<OnboardingFormContext>) {
	const { onboardingId, submittedAtTimestamp, validatedAtTimestamp, isFailureState } = useAppSelector(selectOnboardingState);
	const [
		execute,
		{ loading, data, error }
	] = useLazyQuery(CheckOnboardingStatus, {
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		variables: { onboardingId }
	});

	useEffect(() => {
		if(error) {
			formContext.setValue("isPolling", false);
			formContext.setValue("isFailureState", true);
			formContext.setValue("failedAtTimestamp", Date.now());
		}
	}, [ error, formContext ]);

	useEffect(() => {
		if(data?.CheckOnboardingStatus.completedAt && !validatedAtTimestamp) {
			formContext.setValue("isPolling", false);
			formContext.setValue("isFailureState", false);
			formContext.setValue("validatedAtTimestamp", Date.now());
		}
	}, [ data, formContext, validatedAtTimestamp ]);

	const handlePollForStatus = useCallback(() => {
		if(submittedAtTimestamp && !validatedAtTimestamp && !isFailureState) {
			formContext.setValue("isPolling", true);

			const secondsSinceSubmitted = (Date.now() - submittedAtTimestamp) / 1000;
			if(secondsSinceSubmitted > 60 * 2) {
				formContext.setValue("isPolling", false);
				formContext.setValue("isPollingTimeout", true);
				formContext.setValue("isFailureState", true);
				formContext.setValue("failedAtTimestamp", Date.now());
				return;
			}

			execute({
				variables: { onboardingId }
			});

			return;
		}
	}, [ execute, formContext, isFailureState, onboardingId, submittedAtTimestamp, validatedAtTimestamp ]);

	useEffect(() => {
		const interval = setInterval(() => {
			handlePollForStatus();
		}, 5 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [ handlePollForStatus ]);
}

export const SubmitOnboardingContext = React.createContext(() => {
	//
});

export const SubmitOnboardingProvider: React.FC<{
	children: React.ReactElement<{ handleSubmit: () => void; formContext: UseFormReturn<OnboardingFormContext>; }>;
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ children, formContext }) => {
	useOnboardingStatusPoller(formContext);
	const dispatch = useAppDispatch();
	const tracking = useTracking();
	const state = useAppSelector(selectOnboardingState);
	const address = useAppSelector(selectOnboardingAddress);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	///@ts-ignore
	window.formContext = formContext;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	///@ts-ignore
	window.resetOnboardingState = function () {
		formContext.setValue("isPolling", false);
		formContext.setValue("isPollingTimeout", false);
		formContext.setValue("isSubmitting", false);
		formContext.setValue("submittedAtTimestamp", 0);
		formContext.setValue("validatedAtTimestamp", 0);
		formContext.setValue("isFailureState", false);
		formContext.setValue("failedAtTimestamp", 0);
		formContext.setValue("instanceId", "");
		formContext.setValue("instanceDomain", "");
		formContext.setValue("onboardingId", "");
	};

	const [
		create,
		{ data, error }
	] = useMutation(CreateInstanceOnboardingRequest, {

	});

	useEffect(() => {
		if(error) {
			formContext.setValue("isFailureState", true);
			formContext.setValue("failedAtTimestamp", Date.now());
		}
	}, [ dispatch, error, formContext ]);

	useEffect(() => {
		if(data?.CreateInstanceOnboardingRequest) {
			formContext.setValue("submittedAtTimestamp", Date.now());
			formContext.setValue("onboardingId", data.CreateInstanceOnboardingRequest.id);
			formContext.setValue("instanceId", data.CreateInstanceOnboardingRequest.instance.id);
			formContext.setValue("instanceDomain", data.CreateInstanceOnboardingRequest.instance.domain);
		}
	}, [ data, dispatch, formContext ]);

	function handleSubmit() {
		const { businessName, businessType, subdomain, logoMediaId, primaryColor, secondaryColor, materials } = state;
		if(!businessName || !businessType || !address.addressLineOne) {
			return;
		}

		formContext.setValue("isSubmitting", true);
		create({
			variables: {
				subdomain,
				businessName,
				businessType,
				tracking,
				logoMediaId: logoMediaId,
				primaryColor: primaryColor,
				secondaryColor: secondaryColor,
				address: {
					...address,
					userId: "",
					floor: 1,
					countryCode: address.countryCode || "US",
					hasParking: false,
					hasElevator: false
				},
				materials: materials.map(m => m.id),
			}
		}).catch(err => {
			console.error(err);
			formContext.setValue("isFailureState", true);
		}).finally(() => {
			formContext.setValue("isSubmitting", false);
		});
	}

	return (
		<SubmitOnboardingContext.Provider value={() => handleSubmit()}>
			{children}
		</SubmitOnboardingContext.Provider>
	);
};

export const OnboardingSubmittedDialog: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const { isFailureState, submittedAtTimestamp, validatedAtTimestamp } = useAppSelector(selectOnboardingState);
	return (
		<DialogWithClose
			hideActions
			title="Setup In Progress"
			onClose={noop}
			content={(
				<Fragment>
					{!!submittedAtTimestamp && !validatedAtTimestamp && !isFailureState && (
						<OnboardingLoadingContent formContext={formContext} />
					)}
					{!!validatedAtTimestamp && !isFailureState && (
						<OnboardingReadyContent formContext={formContext} />
					)}
				</Fragment>
			)}
		/>
	);
};

export const OnboardingLoadingContent: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	return (
		<Box gap="medium">
			<Heading level="3" margin="none">
				We're finishing up a few things
			</Heading>
			<Typography>
				We're currently setting up your dashboard and will be done in just a minute. We'll let you know when it's ready.
			</Typography>
			<Box height="small" align="center" justify="center">
				<CircularProgress
					thickness={1}
					size={120}
				/>
			</Box>
		</Box>
	);
};

export const OnboardingReadyContent: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const { instanceDomain } = useAppSelector(selectOnboardingState);

	function handleRedirect() {
		if(!instanceDomain) return;
		const domain = (window.location.hostname === "localhost" || window.location.hostname.includes("uat-"))
			? `uat-${instanceDomain}`
			: instanceDomain;

		window.location.replace(`https://${domain}/admin/dashboard`);
	}

	return (
		<Box gap="medium">
			<Heading level="3" margin="none">
				Your dashboard is ready!
			</Heading>
			<Typography>
				Your customized dashboard is ready to go. Click below to login and start tracking your waste!
			</Typography>
			<Box align="center">
				<Button onClick={handleRedirect} fullWidth variant="contained">
					View My Dashboard
				</Button>
			</Box>
		</Box>
	);
};

export const ReviewView: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const { isFailureState, submittedAtTimestamp } = useAppSelector(selectOnboardingState);
	const address = useAppSelector(selectOnboardingAddress);

	const {
		activeStepIndex
	} = useOnboardingSteps(formContext);

	return (
		<Fragment>
			{isFailureState && (
				<FailureStateDialog />
			)}
			{!!submittedAtTimestamp && (
				<OnboardingSubmittedDialog formContext={formContext} />
			)}
			<OnboardingViewContainer
				title={STEPS[ activeStepIndex ].label}
				subtitle={STEPS[ activeStepIndex ].description}
				loading={false}
			>
				<OrganizationNameElement />
				<OrganizationTypeElement
					formContext={formContext}
				/>
				<DomainNameElement
					fullWidth
					formContext={formContext}
				/>
				<StandardAddressListItem
					address={{
						...address,
						id: ""
					}}
					selected={true}
					onSelect={noop}
					isLoading={false}
				/>
			</OnboardingViewContainer>
		</Fragment>
	);
};

export const OnboardingController = () => {
	const dispatch = useAppDispatch();
	const { size, width } = useWindowDimensions();
	const formContext = useOnboardingFormContext();
	const state = useAppSelector(selectOnboardingState);

	const {
		activeStepIndex
	} = useOnboardingSteps(formContext);

	useSyncState(
		"onboarding",
		state
	);

	const { utm, intent } = useTracking();
	useEffect(() => {
		formContext.setValue("tracking", {
			utm,
			intent
		});
	}, [ utm, intent, dispatch, formContext ]);


	const columns = useMemo(() => {
		if(width > 1050) {
			return [ "1/4", "2/4", "1/4" ];
		}

		if(width > 700) {
			return [ "1/3", "2/3" ];
		}

		return [ "1/1" ];
	}, [ width ]);

	return (
		<Box style={{ display: "block" }} height="100%" gap="medium" overflow={{ vertical: "scroll", horizontal: "hidden" }}>
			<Grid columns={columns} gap="small" height="100%" width="100%">
				{size !== "small" && (
					<Box background="accent-3" pad="medium">
						<Stepper
							orientation="vertical"
							activeStep={activeStepIndex}
						>
							{STEPS.map((step, index) => (
								<Step key={step.label} completed={index < activeStepIndex}>
									<OnboardingStepLabel
										label={step.label}
										description={step.description}
									/>
								</Step>
							))}
						</Stepper>
					</Box>
				)}
				<Box>
					<SubmitOnboardingProvider formContext={formContext}>
						<SubmitOnboardingContext.Consumer>
							{handleSubmit => (
								<StepContentRenderer
									formContext={formContext}
									handleSubmit={handleSubmit}
									steps={[
										<BusinessInformationView
											key="business-information"
											formContext={formContext}
										/>,
										<WasteProfileView
											key="waste-profile"
											formContext={formContext}
										/>,
										<ReportingCustomizationView
											key="reporting-customizations"
											formContext={formContext}
										/>,
										<LoginInteruptController
											key="review"
											loginRequired={true}
											overrideText="Before we continue, we need to collect some contact information."
										>
											<ReviewView
												key="review"
												formContext={formContext}
											/>
										</LoginInteruptController>
									]}
								/>
							)}
						</SubmitOnboardingContext.Consumer>
					</SubmitOnboardingProvider>
				</Box>
				{width > 1050 && STEPS[ activeStepIndex ].helperTitle && (
					<Box height="100%" width="100%">
						<Box
							round
							width="85%"
							pad="medium"
							gap="medium"
							height="medium"
							background="accent-3"
							margin={{ vertical: "medium", right: "small" }}
						>
							<Typography fontWeight="bold">
								{STEPS[ activeStepIndex ].helperTitle}
							</Typography>
							<Typography>
								{STEPS[ activeStepIndex ].helperText}
							</Typography>
						</Box>
					</Box>
				)}
			</Grid>
		</Box>
	);
};