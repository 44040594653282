import React from "react";
import { Box, Spinner } from "grommet";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { UseFormReturn } from "react-hook-form";
import { DialogWithClose } from "../../../../../../components";
import { WasteAuditCollectionForm, WasteAuditCollectionFormContext } from "./WasteAuditCollectionForm";

export const WasteAuditCollectionDialogCommon: React.FC<{
	title: string;
	loading: boolean;
	onClose: () => void;
	formContext: UseFormReturn<WasteAuditCollectionFormContext>;
	onSubmit: () => void;
	onSubmitLabel: string;
	isSubmitting?: boolean;
}> = ({ title, loading, onClose, formContext, onSubmit, onSubmitLabel, isSubmitting }) => {
	return (
		<DialogWithClose
			title={title}
			onClose={onClose}
			content={
				(loading) ? (
					<Box height="small" align="center" justify="center">
						<Spinner />
					</Box>
				) : (
					<WasteAuditCollectionForm
						formContext={formContext}
					/>
				)
			}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={isSubmitting}
						onClick={formContext.handleSubmit(onSubmit)}
					>
						{onSubmitLabel}
					</LoadingButton>
				</Box>
			)}
		/>
	);
};