import React from "react";
import { TextFieldElement } from "react-hook-form-mui";

export const CityElement: React.FC = () => {
	return (
		<TextFieldElement
			fullWidth
			required
			name="locality"
			label="City"
		/>
	);
};

export const InternationalLocalityElement: React.FC = () => {
	return (
		<TextFieldElement
			fullWidth
			required
			name="locality"
			label="City / Locality"
		/>
	);
};