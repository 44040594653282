import { gql } from "@apollo/client";

export const WasteAuditZone_CollectionsFragment = gql`
	fragment WasteAuditZone_CollectionsFragment on WasteAuditZone {
		collections {
			id
			createdAt
			label
			disposition
			totalWeight
			media {
				id
				contentUrl
			}
			container {
				id
				name
			}
		}
	}
`;