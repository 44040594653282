import React, { useCallback } from "react";
import { WasteAuditZoneType } from "../../../../../../graphql/__generated__/graphql";
import { useForm } from "react-hook-form";
import { WasteAuditZoneDialogCommon } from "./WasteAuditZoneDialogCommon";
import { WasteAuditZoneFormContext } from "./WasteAuditZoneForm";
import { useMutation } from "@apollo/client";
import { CreateWasteAuditZone, FetchWasteAudit } from "../../../../../../graphql";
import { useSnackbar } from "notistack";

export const CreateWasteAuditZoneDialog: React.FC<{
	auditId: string;
	onClose: () => void;
}> = ({ auditId, onClose }) => {
	const snack = useSnackbar();
	const formContext = useForm<WasteAuditZoneFormContext>({
		defaultValues: {
			name: "",
			type: WasteAuditZoneType.Other,
			totalWeight: 0,
		},
	});

	const [
		create,
		{ loading: isCreating }
	] = useMutation(CreateWasteAuditZone, {
		refetchQueries: [
			FetchWasteAudit
		]
	});

	const onSubmit = useCallback(() => {
		const { name, type, totalWeight } = formContext.getValues();

		return create({
			variables: {
				auditId,
				name,
				type,
				totalWeight: totalWeight || 0,
			}
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to create waste audit zone", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}, [ auditId, create, formContext, onClose, snack ]);

	return (
		<WasteAuditZoneDialogCommon
			title="Create Zone"
			loading={false}
			onClose={onClose}
			formContext={formContext}
			onSubmit={onSubmit}
			onSubmitLabel="Create"
			isSubmitting={isCreating}
		/>
	);
};