import React, { useMemo } from "react";
import { Box, Grid, Image } from "grommet";
import { usePublicShareContainer, usePublicShareContainerNavigation } from "../hooks";
import { useRequiredPathParam } from "../../../hooks";
import { Avatar, Button, Chip, Divider, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, Typography, useTheme } from "@mui/material";
import { formatNumber } from "../../../helpers";
import { Gauge, gaugeClasses } from "@mui/x-charts";

export const PublicContainerView: React.FC = () => {
	const containerId = useRequiredPathParam("containerId", "/");
	const { instance, container, loading } = usePublicShareContainer(containerId);

	const chartData = [
		[ "From", "To", "Weight" ],
		[ "Generation", "Landfill", 70 ],
		[ "Generation", "Diversion", 30 ],
		[ "Diversion", "Reuse", 10 ],
		[ "Diversion", "Recycling", 10 ],
		[ "Diversion", "Composting", 10 ]
	];

	const theme = useTheme();
	const { back, forward, redirect } = usePublicShareContainerNavigation(containerId);


	return (
		// <Box margin="small" gap="medium" align="center">
		// 	<Heading
		// 		level="2"
		// 		margin="none"
		// 		color={instance?.customizations.primaryColor || undefined}
		// 	>
		// 		Where does our waste go?
		// 	</Heading>
		// 	<Chart
		// 		chartType="Sankey"
		// 		data={chartData}
		// 		width="100%"
		// 		height="400px"
		// 		legendToggle
		// 		options={{
		// 			sankey: {
		// 				link: {
		// 					colorMode: "gradient"
		// 				}
		// 			}
		// 		}}
		// 	/>
		// </Box>
		<Box
			gap="medium"
			margin={{ left: "auto", right: "auto" }}
			style={{ maxWidth: "500px" }}
		>
			<Box
				round
				gap="small"
				pad="medium"
				elevation="small"
				background="light-1"
				style={{ maxWidth: "500px" }}
				onClick={() => {
					redirect("diversion");
				}}
				hoverIndicator
			>
				<Box>
					<Typography
						fontSize="large"
						fontWeight="bold"
						color={theme.palette.primary.main}
					>
						Diversion Tracking
					</Typography>
					<Typography variant="caption">
						Compare diversion rates between different waste streams.
					</Typography>
				</Box>
				<Grid columns={{ count: 3, size: "auto" }} gap="medium">
					<Box align="center" gap="small">
						<Gauge
							value={60}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							This Building
						</Typography>
					</Box>
					<Box align="center" gap="small">
						<Gauge
							value={55}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							Campus Average
						</Typography>
					</Box>
					<Box align="center" gap="small">
						<Gauge
							value={55}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							National Average
						</Typography>
					</Box>
				</Grid>
				<Box>
					<Button
						color="primary"
						variant="outlined"
					>
						Learn More
					</Button>
				</Box>
			</Box>
			<Box
				round
				gap="small"
				pad="medium"
				elevation="small"
				background="light-1"
				style={{ maxWidth: "500px" }}
				onClick={() => {
					redirect("contamination");
				}}
				hoverIndicator
			>
				<Box>
					<Typography
						fontSize="large"
						fontWeight="bold"
						color={theme.palette.primary.main}
					>
						Contamination Tracking
					</Typography>
					<Typography variant="caption">
						Compare contamination rates (things that are not supposed to be there) across bins and locations.
					</Typography>
				</Box>
				<Grid columns={{ count: 3, size: "auto" }} gap="medium">
					<Box align="center" gap="small">
						<Gauge
							value={10}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							This Bin
						</Typography>
					</Box>
					<Box align="center" gap="small">
						<Gauge
							value={15}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							Campus Average
						</Typography>
					</Box>
					<Box align="center" gap="small">
						<Gauge
							value={10}
							valueMin={0}
							valueMax={100}
							endAngle={90}
							startAngle={-90}
							height={60}
							width={100}
							text={
								({ value }) => `${value}%`
							}
							sx={(theme) => ({
								[ `& .${gaugeClasses.valueText}` ]: {
									fontSize: 20,
								},
								[ `& .${gaugeClasses.referenceArc}` ]: {
									fill: theme.palette.text.disabled,
								},
							})}
						/>
						<Typography variant="caption">
							National Average
						</Typography>
					</Box>
				</Grid>
				<Box>
					<Button
						color="primary"
						variant="outlined"
					>
						Learn More
					</Button>
				</Box>
			</Box>
			<Box
				round
				gap="small"
				pad="medium"
				elevation="small"
				background="light-1"
			>
				<Box
					gap="small"
					direction="row"
					justify="between"
				>
					<Box>
						<Typography
							fontWeight="bold"
						>
							Recycling Bin
						</Typography>
						<Typography
							variant="caption"
						>
							Dining Hall
						</Typography>
					</Box>
					<Box align="end" justify="center">
						<Chip
							color="primary"
							label="Recycling"
						/>
					</Box>
				</Box>
				<Box
					gap="small"
					direction="row"
					justify="between"
				>
					<Box>
						<Typography

							variant="body2"
							fontWeight="bold"
						>
							{formatNumber(container?.weeklyCollectionWeight || 0, 2)} lb(s)
						</Typography>
						<Typography
							variant="caption"
						>
							Weight Per Week
						</Typography>
					</Box>
					<Box>
						<Typography
							textAlign="right"
							variant="body2"
							fontWeight="bold"
						>
							{formatNumber((container?.averageFillRate || 0) * 100, 2)}%
						</Typography>
						<Typography
							textAlign="right"
							variant="caption"
						>
							Average Fullness (%)
						</Typography>
					</Box>
				</Box>
				<Divider sx={{ mt: 1, mb: 1 }} />
				<Typography
				// color={theme.palette.secondary.contrastText}
				>
					Want to help {instance?.name} track more waste? Take a picture of the bin and share it with us!
				</Typography>
				<Button
					color="primary"
					variant="contained"
				>
					Upload Photo
				</Button>
				{/* <Box pad="small">
					<Box
						round
						gap="small"
						pad="medium"
						elevation="small"
						background={theme.palette.secondary.main}
					>
						<Typography
							color={theme.palette.secondary.contrastText}
						>
							Want to help {instance?.name} track more waste? Take a picture of the bin and share it with us!
						</Typography>
						<Button
							color="primary"
							variant="contained"
						>
							Upload Photo
						</Button>
					</Box>
				</Box> */}
			</Box>
			<Box
				round
				gap="small"
				pad="medium"
				elevation="small"
				background="light-1"
				style={{ maxWidth: "500px" }}
			>
				<Box>
					<Typography
						fontSize="large"
						fontWeight="bold"
						color={theme.palette.primary.main}
					>
						Upload History
					</Typography>
					<Typography variant="caption">
						Recently uploaded images of this bin.
					</Typography>
				</Box>
				<List>
					<UploadHistoryListItem
						imageUrl={binImage}
						uploadedAt={moment().subtract(12, "hours").toISOString()}
						userName="Joshua M."
						userAvatar="JM"
					/>
					<UploadHistoryListItem
						imageUrl={binImage}
						uploadedAt={moment().subtract(1, "day").subtract(4, "hours").toISOString()}
						userName="Brandon C."
						userAvatar="BC"
					/>
					<UploadHistoryListItem
						imageUrl={binImage}
						uploadedAt={moment().subtract(3, "days").toISOString()}
						userName="Anonymous"
						userAvatar="A"
					/>
				</List>
				<Box>
					<Button
						color="primary"
						variant="outlined"
					>
						Learn More
					</Button>
				</Box>
			</Box>
			<Box height="60px">
				{/* PADDING */}
			</Box>
		</Box>
	);
};

import binImage from "../bin_image.jpeg";
import moment from "moment";

export const UploadHistoryListItem: React.FC<{
	imageUrl: string;
	userName: string;
	userAvatar: string;
	uploadedAt: string;
}> = ({ imageUrl, uploadedAt, userAvatar, userName }) => {
	const timeAgo = useMemo(() => {
		return moment(uploadedAt).fromNow();
	}, [ uploadedAt ]);
	return (
		<ListItemButton
			divider
			onClick={(e) => {
				e.preventDefault();
				console.log("Clicked");
			}}
		>
			<ListItemAvatar>
				<Avatar>
					{userAvatar}
				</Avatar>
			</ListItemAvatar>
			<Box>
				<Typography fontWeight="bold">
					{userName}
				</Typography>
				<Typography variant="caption">
					{timeAgo}
				</Typography>
			</Box>
			<ListItemSecondaryAction>
				<Box
					width="50px"
					height="50px"
				>
					<Image
						src={imageUrl}
						fit="contain"
					/>
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};