import React, { Fragment } from "react";
import { OnboardingViewContainer, OrganizationNameElement, OrganizationTypeElement, STEPS, useOnboardingSteps } from "../components";
import { OnboardingFormContext } from "../types";
import { UseFormReturn } from "react-hook-form";
import { SimpleAddress, useAutocomplete } from "../../../hooks";
import { Box } from "grommet";
import { CityElement, InternationalPostalCodeElement, StreetAddressElement, SuiteUnitElement } from "../../../components";

export const BusinessInformationView: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const { activeStepIndex } = useOnboardingSteps(formContext);

	useAutocomplete(
		document.getElementById("address-element") as HTMLInputElement,
		(address: SimpleAddress) => {
			const values = formContext.getValues();
			formContext.reset({
				...values,
				addressLineOne: address.addressLineOne,
				locality: address.locality,
				administrativeArea: address.administrativeArea,
				postalCode: address.postalCode,
				lat: address.lat,
				lng: address.lng,
				timezone: address.timezone
			});
		}
	);

	return (
		<Fragment>
			<OnboardingViewContainer
				title={STEPS[ activeStepIndex ].label}
				subtitle={STEPS[ activeStepIndex ].description}
				loading={false}
			>
				<Box gap="medium">
					<OrganizationNameElement />
					<OrganizationTypeElement
						readonly={false}
						formContext={formContext}
					/>
					<StreetAddressElement />
					<SuiteUnitElement />
					<CityElement />
					<InternationalPostalCodeElement />
				</Box>
			</OnboardingViewContainer>
		</Fragment>
	);
};