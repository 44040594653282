import React from "react";
import { Dashboard, NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const BreadcrumbController: React.FC<{
	crumbs: { label: string; path: string; }[];
}> = ({ crumbs }) => {
	return (
		<Breadcrumbs
			separator={<NavigateNext fontSize="small" />}
			aria-label="breadcrumb"
		>
			<Link
				color="inherit"
				underline="hover"
				component={RouterLink}
				to={"/admin/dashboard"}
				sx={{ display: "flex", alignItems: "center" }}

			>
				<Dashboard sx={{ mr: 0.5 }} fontSize="inherit" />
				Dashboard
			</Link>
			{crumbs.map((crumb, index) => (
				<Link
					key={index}
					color="inherit"
					underline="hover"
					component={RouterLink}
					to={crumb.path}
					sx={{ display: "flex", alignItems: "center", color: "text.primary" }}

				>
					{crumb.label}
				</Link>
			))}
		</Breadcrumbs>
	);
};