import { gql } from "../../../__generated__";

export const FetchWasteAuditMetrics = gql(/* GraphQL */ `
	query FetchMetrics(
		$aggregateBy: WasteAuditAggregationType!,
		$auditId: String!
	) {
		FetchWasteAuditMetrics(
			aggregateBy: $aggregateBy,
			auditId: $auditId
		) {
			aggregateBy
			diversionRate
			contaminationRate
			potentialDiversionRate
			potentialDiversionRate
			totalWeightDiverted
			totalWeightGenerated
			totalWeightContamination
			totalWeightDivertedPotential
			materials {
				material {
					id 
					name 
					groupName 
				}
				totalWeightRatio
				totalWeightDiverted
				totalWeightGenerated
				totalWeightContamination
				totalWeightDivertedPotential
				diversionRate
				contaminationRate
				potentialDiversionRate
			}
			lineItems {
				aggregateBy
				totalWeightDiverted
				totalWeightGenerated
				totalWeightContamination
				totalWeightDivertedPotential
				diversionRate
				contaminationRate
				potentialDiversionRate
			}
		}
	}
`);