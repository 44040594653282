import { gql } from "../../../__generated__";

export const FetchWasteAuditTask = gql(/* GraphQL */ `
	query FetchWasteAuditTask($taskId: String!, $aggregateBy: WasteAuditAggregationType!) {
		FetchWasteAuditTask(taskId: $taskId) {
			id
			annotations {
				id
				material {
					id
					name
				}
				source
				segmentationArea
				contours
				isExcluded
				isContamination
			}
			media {
				id
				name
				contentUrl
			}
			metrics(
				aggregateBy: $aggregateBy
			) {
				aggregateBy
				totalWeightDiverted
				totalWeightGenerated
				totalWeightContamination
				totalWeightDivertedPotential
				contaminationRate
				diversionRate
				materials {
					material {
						id
						name
					}
					totalWeightRatio
					totalWeightGenerated
					totalWeightDiverted
					totalWeightDivertedPotential
					totalWeightContamination
					contaminationRate
				}
			}
		}
	}
`);