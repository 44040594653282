import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { FetchWasteAudit, FetchWasteAuditCollection, FetchWasteAuditZone, UpdateWasteAuditCollection, useWasteAuditCollection } from "../../../../../../graphql";
import { useSnackbar } from "notistack";
import { WasteAuditCollectionFormContext } from "./WasteAuditCollectionForm";
import { WasteAuditCollectionDialogCommon } from "./WasteAuditCollectionDialogCommon";
import { ProductDisposition } from "../../../../../../graphql/__generated__/graphql";

export const UpdateWasteAuditCollectionDialog: React.FC<{
	onClose: () => void;
	collectionId: string;
}> = ({ collectionId, onClose }) => {
	const snack = useSnackbar();
	const { collection, loading } = useWasteAuditCollection(collectionId);
	const formContext = useForm<WasteAuditCollectionFormContext>({
		defaultValues: {
			label: "",
			disposition: null,
			totalWeight: 0,
			roomName: "",
			containerName: "",
			media: [],
			mediaUploadQueue: []
		},
	});

	useEffect(() => {
		if(collection) {
			console.log(collection);
			formContext.reset({
				label: collection?.label || "",
				disposition: collection?.disposition || null,
				totalWeight: collection?.totalWeight || 0,
				roomName: collection?.metadata?.roomName || "",
				containerName: collection?.metadata?.containerName || "",
				media: collection?.media || [],
				mediaUploadQueue: []
			});
		}
	}, [ collection, formContext ]);

	const [
		update,
		{ loading: isUpdating }
	] = useMutation(UpdateWasteAuditCollection, {
		refetchQueries: [
			FetchWasteAudit,
			FetchWasteAuditZone,
			FetchWasteAuditCollection
		]
	});

	const onSubmit = useCallback(() => {
		const {
			label,
			disposition,
			mediaUploadQueue,
			totalWeight,
			roomName,
			containerName
		} = formContext.getValues();

		return update({
			variables: {
				collectionId,
				label,
				metadata: {
					roomName: roomName || "",
					roomNumber: "",
					containerNumber: "",
					containerName: containerName || "",
					containerLocation: "",
				},
				disposition: disposition || ProductDisposition.Landfill,
				media: (mediaUploadQueue || []).map(media => ({
					name: media.name,
					contentType: media.contentType,
					content: media.base64Content,
					context: media.context
				})),
				totalWeight: totalWeight || 0,
			}
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to update waste audit collection", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}, [ collectionId, formContext, onClose, snack, update ]);

	return (
		<WasteAuditCollectionDialogCommon
			title="Update Data"
			loading={loading}
			onClose={onClose}
			formContext={formContext}
			onSubmit={onSubmit}
			onSubmitLabel="Update"
			isSubmitting={isUpdating}
		/>
	);
};