import moment, { Moment } from "moment-timezone";
import { ProviderContext } from "notistack";
import { Stop_ProductsFragmentFragment } from "./graphql/__generated__/graphql";
import { MediaLike } from "./types";

export function noop(): void {
	// no operation
}

export function isNotNull<T>(value: T): value is NonNullable<T> {
	return value !== null && value !== undefined;
}

export function formatWindow(from: number | string, to: number | string) {
	return moment().minutes(0).hours(Number(from)).format("h:mm a") + " - " + moment().minutes(0).hours(Number(to)).format("h:mm a");
}

export function decodeDataUrl(input: string): string {
	let encoded = input.toString().replace(/^data:(.*,)?/, "");
	if((encoded.length % 4) > 0) {
		encoded += "=".repeat(4 - (encoded.length % 4));
	}

	return encoded;
}

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = (): void => {
		if(!reader.result) throw new Error("No content in File");
		let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
		if((encoded.length % 4) > 0) {
			encoded += "=".repeat(4 - (encoded.length % 4));
		}
		resolve(encoded);
	};
	reader.onerror = (error): void => reject(error);
});

export function copyToClipboard(value: string): Promise<void> {
	return navigator.clipboard.writeText(value);
}

export function handleCopyToClipboard(value: string, snack: ProviderContext): void {
	copyToClipboard(value)
		.then(() => {
			snack.enqueueSnackbar(`Copied ${value} to clipboard`, {
				variant: "success"
			});
		});
}

export function parseTimestampFromUTC(date: Date | string | null, timezone: string): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY hh:mm A");
}

export function parseDateFromUTC(date: Date | string | null, timezone: string): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY");
}

export function prettyPrintHour(time: string): string {
	return moment().set("hours", Number(time.split(":")[ 0 ])).set("minutes", Number(time.split(":")[ 1 ] ?? 0)).format("hh:mm A");
}

export function formatDateShort(date: Moment): string {
	return date.format("YYYY-MM-DD");
}

export const toProperCase = (str: string): string => {
	return str.replace(
		/\w\S*/g,
		function (txt: string) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
};

export function sortPickup(a: { scheduledDate?: string | null; }, b: { scheduledDate?: string | null; }) {
	if(!a.scheduledDate) {
		return 1;
	}

	if(!b.scheduledDate) {
		return -1;
	}

	return moment(a.scheduledDate).unix() - moment(b.scheduledDate).unix();
}

export function keys<T extends object>(value: T): (keyof T)[] {
	return Object.keys(value) as (keyof T)[];
}

//export function dayOfWeekToNumber(dayOfWeek: DayOfWeek): number {
//	switch(dayOfWeek) {
//		case DayOfWeek.SUNDAY: {
//			return 0;
//		}
//		case DayOfWeek.MONDAY: {
//			return 1;
//		}
//		case DayOfWeek.TUESDAY: {
//			return 2;
//		}
//		case DayOfWeek.WEDNESDAY: {
//			return 3;
//		}
//		case DayOfWeek.THURSDAY: {
//			return 4;
//		}
//		case DayOfWeek.FRIDAY: {
//			return 5;
//		}
//		case DayOfWeek.SATURDAY: {
//			return 6;
//		}
//	}
//}

//export function numberToDayOfWeek(number: number): DayOfWeek {
//	switch(number) {
//		case 0: {
//			return DayOfWeek.SUNDAY;
//		}
//		case 1: {
//			return DayOfWeek.MONDAY;
//		}
//		case 2: {
//			return DayOfWeek.TUESDAY;
//		}
//		case 3: {
//			return DayOfWeek.WEDNESDAY;
//		}
//		case 4: {
//			return DayOfWeek.THURSDAY;
//		}
//		case 5: {
//			return DayOfWeek.FRIDAY;
//		}
//		case 6: {
//			return DayOfWeek.SATURDAY;
//		}
//		default: {
//			throw new Error(`Invalid weekday number [${number}]`);
//		}
//	}
//}

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2
});

export function formatCurrency(value: number): string {
	return currencyFormatter.format(value);
}

export function formatNumber(
	value: number,
	round?: boolean | number
): string {
	if(typeof round === "number") {
		return Number(value.toFixed(round)).toLocaleString("en-US", {
			minimumFractionDigits: round
		});
	}

	if(round) {
		return Math.round(value).toLocaleString("en-US");
	}

	return value.toLocaleString("en-US");
}

export function appendLeadingZero(n: number | string): string {
	const value = Number(n);
	return value <= 9 ? "0" + value : String(value);
}

interface HasWindow {
	scheduledWindow?: {
		date?: string;
		from?: number;
		to?: number;
		timezone?: string;
	} | null;
}

export function sortByScheduledWindow<T extends HasWindow>(a: T, b: T): number {
	if(a.scheduledWindow?.date && b.scheduledWindow?.date) {
		return moment(new Date(a.scheduledWindow.date)).unix() - moment(new Date(b.scheduledWindow.date)).unix();
	}

	if(a.scheduledWindow?.date) {
		return -1;
	}

	if(b.scheduledWindow?.date) {
		return 1;
	}

	return 0;
}

export function formatDateFromWindow(date: string, timezone: string): string {
	return (date)
		? moment.tz(date, timezone).format("MM/DD/YYYY")
		: "";
}

export function formatTimeFromWindow(from: number, to: number): string {
	return [
		moment().hours(from).minutes(0).format("h:mm A"),
		moment().hours(to).minutes(0).format("h:mm A")
	].join(" - ");
}

export function isPartnersSite(): boolean {
	return window.location.hostname.includes("partner.liverego");
}

export function isResidentSite(): boolean {
	return window.location.hostname.includes("resident.liverego");
}

export function isPropertyManagerSite(): boolean {
	return window.location.hostname.includes("manager.liverego");
}

export function formRequiredValidation(value: unknown, fieldName?: string) {
	if(!value) {
		return `${fieldName || "This field"} is required`;
	}

	return undefined;
}

export function formNumberValidation(value: unknown, isRequired: boolean) {
	if(isRequired && isNaN(Number(value))) {
		return "Please enter a number";
	}
}

export function formRequiredValidationWithLabel(fieldName: string) {
	return (value: unknown) => {
		return formRequiredValidation(value, fieldName);
	};
}

export const isPhoneNumberRegExp = /^1{0,1}\d{10}$/;

export function isPhoneNumber(value: string): boolean {
	return isPhoneNumberRegExp.test(String(value));
}

export const isEmailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isEmailAddress(value: string): boolean {
	return isEmailRegExp.test(String(value));
}


export function formUsernameValidation(value: unknown) {
	if(isPhoneNumber(String(value)) || isEmailAddress(String(value))) {
		return undefined;
	}

	return "Please enter a valid email address or phone number";
}

export const standardFormValidations = [
	formRequiredValidation
];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace States {
	export const map = {
		"AL": "Alabama",
		"AK": "Alaska",
		"AZ": "Arizona",
		"AR": "Arkansas",
		"CA": "California",
		"CO": "Colorado",
		"CT": "Connecticut",
		"DE": "Delaware",
		"DC": "District Of Columbia",
		"FL": "Florida",
		"GA": "Georgia",
		"HI": "Hawaii",
		"ID": "Idaho",
		"IL": "Illinois",
		"IN": "Indiana",
		"IA": "Iowa",
		"KS": "Kansas",
		"KY": "Kentucky",
		"LA": "Louisiana",
		"ME": "Maine",
		"MD": "Maryland",
		"MA": "Massachusetts",
		"MI": "Michigan",
		"MN": "Minnesota",
		"MS": "Mississippi",
		"MO": "Missouri",
		"MT": "Montana",
		"NE": "Nebraska",
		"NV": "Nevada",
		"NH": "New Hampshire",
		"NJ": "New Jersey",
		"NM": "New Mexico",
		"NY": "New York",
		"NC": "North Carolina",
		"ND": "North Dakota",
		"OH": "Ohio",
		"OK": "Oklahoma",
		"OR": "Oregon",
		"PA": "Pennsylvania",
		"PR": "Puerto Rico",
		"RI": "Rhode Island",
		"SC": "South Carolina",
		"SD": "South Dakota",
		"TN": "Tennessee",
		"TX": "Texas",
		"UT": "Utah",
		"VT": "Vermont",
		"VA": "Virginia",
		"WA": "Washington",
		"WV": "West Virginia",
		"WI": "Wisconsin",
		"WY": "Wyoming"
	};

	export type Abbreviation = keyof typeof map;
	export type Name = typeof map[ Abbreviation ];

	export function getAbbreviations(): Abbreviation[] {
		return Object.keys(map) as (keyof typeof map)[];
	}

	export function getNames(): Name[] {
		return Object.values(map) as Name[];
	}

	export function byAbbreviation() {
		return map;
	}

	export function toArray(): { abbreviation: Abbreviation; name: string; }[] {
		return Object.entries(map).map(([ abbreviation, name ]) => ({ abbreviation: abbreviation as Abbreviation, name }));
	}
}

export function formatPhoneNumber(value: string): string {
	if(!isPhoneNumber(value)) {
		return value;
	}

	value = (value.length === 11)
		? value.slice(1)
		: value;

	return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function formatContact(value: string): string {
	if(isPhoneNumber(value)) {
		return formatPhoneNumber(value);
	}

	return value.toLocaleLowerCase();
}

export function padNumber(value: number): string {
	if(value >= 10) {
		return String(value);
	}

	return `0${value}`;
}

export function isOriginStop(stop: Stop_ProductsFragmentFragment): boolean {
	return stop.incomingCount > 0;
}

export function isDestinationStop(stop: Stop_ProductsFragmentFragment): boolean {
	return stop.outgoingCount > 0;
}

export function canStopComplete(stop: Stop_ProductsFragmentFragment): boolean {
	if(isOriginStop(stop)) {
		return stop.incoming.every((incoming) => incoming.originStopConfirmed);
	}

	if(isDestinationStop(stop)) {
		return stop.outgoing.every((outgoing) => outgoing.destinationStopConfirmed || outgoing.destinationStopDelayed);
	}

	return false;
}


export function toContentUrl(media: MediaLike & { content?: string; contentType?: string; }) {
	if(media.content && media.contentType) {
		const base64Str = media.content;
		const file = new File(
			[ Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0)!) ],
			media.name,
			{ type: media.contentType }
		);

		return URL.createObjectURL(file);
	}

	return media.contentUrl;
}

export function dataURLtoFile(dataurl: string, filename: string): File {
	const arr = dataurl.split(","),
		mime = arr[ 0 ].match(/:(.*?);/)?.[ 1 ],
		bstr = atob(arr[ arr.length - 1 ]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while(n--) {
		u8arr[ n ] = bstr.charCodeAt(n);
	}
	return new File([ u8arr ], filename, { type: mime });
}

export function toDataURL(
	name: string,
	content: string,
	contentType: string
) {
	return `data:${contentType};base64,${content}`;
}

export function enumerateQuery(params: URLSearchParams): object {
	const obj = {};
	params.forEach((value, key) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		///@ts-ignore - this is a valid operation
		obj[ key ] = value;
	});

	return obj;
}

interface SimpleAddress {
	addressLineOne: string;
	addressLineTwo?: string | null;
	locality: string;
	administrativeArea: string;
	postalCode: string;
}

export function formatAddress(address: SimpleAddress): string {
	return [
		[
			address.addressLineOne,
			address.addressLineTwo
				? `(${address.addressLineTwo})`
				: undefined,
		].filter(Boolean).join(" "),
		[
			address.locality,
			address.administrativeArea
		].join(", "),
		address.postalCode
	].filter(Boolean).join(" ");
}