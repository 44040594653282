import { Box } from "grommet";
import React, { useMemo } from "react";
import { CheckoutTitleContainer, CheckoutViewContainer } from "../components";
import { AddressDialog, Pagination, StandardAddressListItem, StepControls } from "../../../components";
import { useCheckoutSessionAddress } from "../hooks";
import { useUser } from "../../../auth";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export const CheckoutAddressView: React.FC = () => {
	const { addresses } = useUser();
	const [ isDialogOpen, setIsDialogOpen ] = React.useState(false);

	const {
		address,
		update
	} = useCheckoutSessionAddress();

	const mergedAddresses = useMemo(() => {
		const merged = [ ...addresses ];

		if(address && !addresses.find((a) => a.addressLineOne.toLocaleLowerCase() === address.addressLineOne.toLowerCase())) {
			merged.push(address as typeof addresses[ 0 ]);
		}

		return merged.sort((a, b) => {
			if(a.id === address?.id) return -1;
			if(a.addressLineOne.toLowerCase() === address?.addressLineOne.toLowerCase()) return -1;
			return 0;
		});
	}, [ addresses, address ]);

	return (
		<CheckoutViewContainer>
			<CheckoutTitleContainer
				heading="Pickup Address"
				subheading="Add an address or select a saved address. Let us know where to pick up your items and any important information we should know."
			/>
			{isDialogOpen && (
				<AddressDialog
					address={address}
					isCreating={false}
					isUpdating={false}
					onClose={() => {
						setIsDialogOpen(false);
					}}
					createAddress={(address) => {
						update({
							id: "",
							...address
						});
						setIsDialogOpen(false);
					}}
					updateAddress={(address) => {
						update(address);
						setIsDialogOpen(false);
					}}
				/>
			)}
			<Box>
				<Pagination pageSize={3}>
					{mergedAddresses.map((a) => (
						<StandardAddressListItem
							key={a.id}
							address={a}
							onEdit={() => {
								update({
									...a,
									countryCode: a.country.code
								});
								setIsDialogOpen(true);
							}}
							onSelect={() => {
								update({
									...a,
									countryCode: a.country.code
								});
							}}
							onDelete={() => {
								//
							}}
							isLoading={false}
							selected={a.id === address?.id || a.addressLineOne.toLowerCase() === address?.addressLineOne.toLowerCase()}
						/>
					))}
				</Pagination>
			</Box>
			<Box flex justify="end">
				<StepControls
					name="checkout"
					nextButtonLabel="Continue"
					canProceed={!!address}
					isLoading={false}
					additionalButtons={(
						<LoadingButton
							loading={false}
							startIcon={<Add />}
							variant="outlined"
							color="primary"
							onClick={() => {
								setIsDialogOpen(true);
							}}
						>
							Add Address
						</LoadingButton>
					)}
				/>
			</Box>
		</CheckoutViewContainer>
	);
};
