import { gql } from "../../../__generated__";

export const FetchContainerAssignmentPublic = gql(/* GraphQL */ `
	query FetchContainerAssignmentPublic($assignmentId: String!) {
		FetchWasteContainerAssignment(assignmentId: $assignmentId) {
			id
			partner {
				instance {
					id
					name
					customizations {
						headerColor
						primaryColor
						secondaryColor
						logoUrl
					}
				}
			}
			averageFillRate
			averageCollectionWeight
			weeklyCollectionWeight
			monthlyCollectionWeight
		}
	}
`);