import { gql } from "../../../__generated__";

export const UpdateWasteAudit = gql(/* GraphQL */ `
	mutation UpdateWasteAudit(
		$name: String
		$type: WasteAuditType
		$scheduledDate: DateTime
		$auditId: String!
	) {
		UpdateWasteAudit(
			name: $name
			type: $type
			auditId: $auditId
			scheduledDate: $scheduledDate
		) {
			id
		}
	}
`);