import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton, Link, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Carousel, Image, Page, PageContent } from "grommet";
import { useFullScreen, useInstance } from "../../../hooks";
import { DialogWithClose } from "../../../components/Dialog";
import { AddTask, Facebook, Instagram } from "@mui/icons-material";
import { FormContainer, TextFieldElement, TextareaAutosizeElement, useForm } from "react-hook-form-mui";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FetchProductWithListing, FindPendingProductListingByInstance } from "../../../graphql";
import { useSnackbar } from "notistack";
import { ProductDetailsList } from "../components/ProductDetailsList";
import { ProductCondition } from "../../../graphql/__generated__/graphql";
import { formatCurrency } from "../../../helpers";
import { LoadingButton } from "@mui/lab";
import { useLoginGuard } from "../../../auth";
import { ProductImageDialog } from "../../../components";

export function usePendingProductQueue() {
	const { instance } = useInstance();
	const [
		pendingProductsQuery,
		{ data: pendingProductsData, loading }
	] = useLazyQuery(FindPendingProductListingByInstance);

	useEffect(() => {
		if(instance) {
			pendingProductsQuery({
				variables: {
					instanceId: instance.id
				}
			}).catch(err => {
				console.error("Error while fetching pending products: ", err);
			});
		}
	}, [ instance, pendingProductsQuery ]);

	return {
		loading,
		products: pendingProductsData?.ListProductsPendingForSale ?? [],
	};
}

export const SellerDashboardView: React.FC = () => {
	useLoginGuard();
	const { products } = usePendingProductQueue();
	const [ selectedProductId, setSelectedProductId ] = useState("");
	const [ viewProductId, setViewProductId ] = useState("");

	return (
		<Page kind="narrow">
			<PageContent>
				<Box gap="medium" margin="medium">
					{!!selectedProductId && (
						<ProductApprovalDialog
							productId={selectedProductId}
							onClose={() => setSelectedProductId("")}
						/>
					)}
					{!!viewProductId && (
						<SellerProductDialog
							productId={viewProductId}
							price={products.filter(product => product.id === viewProductId)[ 0 ].listing?.price ?? 0}
							title={products.filter(product => product.id === viewProductId)[ 0 ].listing?.title ?? ""}
							description={products.filter(product => product.id === viewProductId)[ 0 ].listing?.description ?? ""}
							onClose={() => setViewProductId("")}
						/>
					)}
					<Typography variant="h5">
						Approval Queue
					</Typography>
					<Box gap="small">
						{products.map((product) => (
							<ApprovalQueueProductListItem
								key={product.id}
								productId={product.id}
								title={product.listing?.title ?? ""}
								price={product.listing?.price ?? 0}
								image={product.media[ 0 ]?.contentUrl ?? ""}
								onSelect={() => setViewProductId(product.id)}
								onApprove={() => setSelectedProductId(product.id)}
							/>
						))}
					</Box>
				</Box>
			</PageContent>
		</Page>
	);
};

interface SellerProductDialogProps {
	onClose: () => void;
	price: number;
	title: string;
	description: string;
	productId: string;
}

export const SellerProductDialog: React.FC<SellerProductDialogProps> = (props) => {
	const useFormReturn = useForm({
		defaultValues: {
			price: props.price,
			title: props.title,
			description: props.description
		}
	});

	function handleViewListing() {
		window.open(`/store/product/${props.productId}`, "_blank");
	}

	return (
		<DialogWithClose
			title="Listing"
			onClose={props.onClose}
			actions={(
				<Box direction="row" justify="between" flex>
					<Button
						color="error"
						variant="outlined"
					>
						Remove
					</Button>
					<Box direction="row" gap="small">
						<Button
							color="primary"
							variant="outlined"
							onClick={handleViewListing}
						>
							View in Store
						</Button>
						<Button
							color="primary"
							variant="contained"
						>
							Save
						</Button>
					</Box>
				</Box>
			)}
			content={(
				<FormContainer
					formContext={useFormReturn}
				>
					<Box gap="small">
						<Typography fontWeight="bold" fontSize="large" >
							Listing Details
						</Typography>
						<TextFieldElement
							name="title"
							label="Listing Title"
						/>
						<TextareaAutosizeElement
							name="description"
							label="Listing Description"
						/>
						<TextFieldElement
							name="price"
							label="Listing Price"
							InputProps={{ startAdornment: "$" }}
						/>
						<Box gap="small">
							<Typography fontWeight="bold" fontSize="large" >
								Listing Channels
							</Typography>
							<List disablePadding>
								<ListItemButton divider>
									<ListItemIcon>
										<Facebook />
									</ListItemIcon>
									<ListItemText
										primary="Facebook Marketplace"
										secondary={(
											<Box direction="row" gap="xsmall">
												<Typography>Status:</Typography>
												<Typography fontWeight="bold">Pending</Typography>
											</Box>

										)}
									/>
									<ListItemSecondaryAction>
										<Box align="center" justify="end" direction="row" gap="small">
											<Link>
												View Listing
											</Link>
										</Box>
									</ListItemSecondaryAction>
								</ListItemButton>
								<ListItemButton divider>
									<ListItemIcon>
										<Instagram />
									</ListItemIcon>
									<ListItemText
										primary="Instagram"
										secondary={(
											<Box direction="row" gap="xsmall">
												<Typography>Status:</Typography>
												<Typography fontWeight="bold">Pending</Typography>
											</Box>

										)}
									/>
									<ListItemSecondaryAction>
										<Box align="center" justify="end" direction="row" gap="small">
											<Link>
												View Listing
											</Link>
										</Box>
									</ListItemSecondaryAction>
								</ListItemButton>
							</List>
						</Box>
					</Box>
				</FormContainer>
			)}
		/>
	);
};

interface ApprovalQueueProductListItemProps {
	image: string;
	title: string;
	price: number;
	productId: string;
	onSelect: (productId: string) => void;
	onApprove: (productId: string) => void;
}

export const ApprovalQueueProductListItem: React.FC<ApprovalQueueProductListItemProps> = (props) => {
	const fullScreen = useFullScreen();
	return (
		<ListItemButton
			divider
			disableGutters
			onClick={() => props.onSelect(props.productId)}
		>
			<ListItemAvatar>
				<Box height="6em" width="6em">
					<Image
						src={props.image}
						fit="contain"
					/>
				</Box>
			</ListItemAvatar>
			<ListItemText
				primary={props.title}
				secondary={formatCurrency(props.price)}
				sx={{ marginLeft: "1em" }}
			/>
			<ListItemSecondaryAction>
				{fullScreen
					? (
						<Box flex align="end" justify="end" pad="small">
							<IconButton
								color="success"
								onClick={(event) => {
									event.stopPropagation();
									props.onApprove(props.productId);
								}}>
								<AddTask />
							</IconButton>
						</Box>
					)
					: (
						<Box pad="small">
							<Button
								color="primary"
								variant="contained"
								onClick={(event) => {
									event.stopPropagation();
									props.onApprove(props.productId);
								}}>
								Begin Approval
							</Button>
						</Box>
					)}

			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

interface ProductApprovalDialogProps {
	onClose: () => void;
	productId: string;
}

export const ProductApprovalDialog: React.FC<ProductApprovalDialogProps> = (props) => {
	const snack = useSnackbar();

	const {
		data: productData,
		loading: loadingProduct,
		error: loadingProductError
	} = useQuery(FetchProductWithListing, {
		variables: { productId: props.productId }
	});

	const product = useMemo(() => {
		return productData?.FetchProduct ?? null;
	}, [ productData ]);

	useEffect(() => {
		if(loadingProductError) {
			snack.enqueueSnackbar(
				"We ran into an issue loading this product. Please try again later.",
				{ variant: "error" }
			);
		}
	}, [ loadingProductError, snack ]);

	// const [
	// 	approveProductMutation,
	// 	{ data: approveProductData, loading: approveProductLoading, error: approveProductError }
	// ] = useMutation(ApproveProductListing, { refetchQueries: [ ListProductsPendingForSale ] });

	// useEffect(() => {
	// 	if(approveProductError) {
	// 		snack.enqueueSnackbar(
	// 			"We ran into an issue approving this product. Please try again later.",
	// 			{ variant: "error" }
	// 		);
	// 	}
	// }, [ approveProductError, snack ]);

	// useEffect(() => {
	// 	if(approveProductData?.ApproveProductListing) {
	// 		snack.enqueueSnackbar(
	// 			"Product approved successfully",
	// 			{ variant: "success" }
	// 		);
	// 		props.onClose();
	// 	}
	// }, [ approveProductData, snack, props ]);

	// const [
	// 	rejectProductMutation,
	// 	{ data: rejectProductData, loading: rejectProductLoading, error: rejectProductError }
	// ] = useMutation(RejectProductListing, { refetchQueries: [ ListProductsPendingForSale ] });

	// useEffect(() => {
	// 	if(rejectProductError) {
	// 		snack.enqueueSnackbar(
	// 			"We ran into an issue rejecting this product. Please try again later.",
	// 			{ variant: "error" }
	// 		);
	// 	}
	// }, [ rejectProductError, snack ]);

	// useEffect(() => {
	// 	if(rejectProductData?.RejectProductListing) {
	// 		snack.enqueueSnackbar(
	// 			"Product rejected successfully",
	// 			{ variant: "success" }
	// 		);
	// 		props.onClose();
	// 	}
	// }, [ rejectProductData, snack, props ]);

	const formContext = useForm({
		defaultValues: {
			approved: null,
			title: product?.listing?.title ?? "",
			description: product?.listing?.description ?? "",
			price: product?.listing?.price ?? 0,
			rejectionReason: ""
		}
	});

	const formValues = formContext.watch();

	function handleSubmit() {
		if(formValues.approved === true) {
			// approveProductMutation({
			// 	variables: { productId: props.productId }
			// }).catch(err => {
			// 	console.error("Failed to approve product", err);
			// });
		}

		if(formValues.approved === false) {
			if(!formValues.rejectionReason) {
				snack.enqueueSnackbar(
					"Please provide a reason for rejecting this product",
					{ variant: "warning" }
				);
				return;
			}

			// rejectProductMutation({
			// 	variables: { productId: props.productId, reason: formValues.rejectionReason }
			// }).catch(err => {
			// 	console.error("Failed to reject product", err);
			// });
		}

		return;
	}

	const [ showProductImageDialog, setShowProductImageDialog ] = useState(false);

	return (
		<DialogWithClose
			title="Product Approval"
			onClose={props.onClose}
			formContainer={{
				context: formContext,
				onSubmit: handleSubmit
			}}
			content={(
				<Box gap="medium">
					{showProductImageDialog && (
						<ProductImageDialog
							onClose={() => setShowProductImageDialog(false)}
							media={product?.media ?? []}
						/>
					)}
					<Typography fontWeight="bold" fontSize="large" >
						Listing Details
					</Typography>
					<TextFieldElement
						name="title"
						label="Listing Title"
						InputProps={{
							readOnly: true,
							value: product?.listing?.title ?? 0
						}}
					/>
					<TextFieldElement
						multiline
						name="description"
						label="Listing Description"
						maxRows={4}
						minRows={2}
						InputProps={{
							readOnly: true,
							value: product?.listing?.description ?? 0
						}}
					/>
					<TextFieldElement
						name="price"
						label="Listing Price"
						InputProps={{
							startAdornment: "$",
							readOnly: true,
							value: product?.listing?.price ?? 0
						}}
					/>
					<Box margin="small">
						<ProductDetailsList
							size={product?.size?.name ?? ""}
							type={product?.type?.name ?? ""}
							category={product?.type?.category?.name ?? ""}
							condition={product?.condition ?? ProductCondition.Unknown}
						/>
					</Box>
					<Box height="small">
						{(product?.media?.length ?? 0) > 0 && (
							<Carousel>
								{product?.media?.map((media, index) => (
									<Image
										fit="contain"
										key={media.id}
										src={media.contentUrl}
										onClick={() => setShowProductImageDialog(true)}
									/>
								))}
							</Carousel>
						)}
					</Box>
					<Typography fontWeight="bold">
						Decision
					</Typography>
					<ToggleButtonGroup
						color="primary"
						exclusive
						value={formValues.approved}
						onChange={(e, value) => {
							formContext.setValue("approved", value);
						}}
					>
						<ToggleButton
							value={true}
						>
							Approve
						</ToggleButton>
						<ToggleButton
							value={false}
						>
							Reject
						</ToggleButton>
					</ToggleButtonGroup>
					{formValues.approved === false && (
						<TextFieldElement
							required
							name="rejectionReason"
							label="Rejection Reason"
							onChange={event => formContext.setValue("rejectionReason", event.target.value)}
							InputProps={{
								value: formValues.rejectionReason
							}}
						/>
					)}
				</Box>
			)}
			actions={(
				<Box direction="row" justify="end" flex>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						disabled={formValues.approved === null}
					//loading={approveProductLoading || rejectProductLoading || loadingProduct}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
		/>
	);
};