
import React, { Fragment, useMemo } from "react";
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale,
	ScriptableContext,
	ArcElement
} from "chart.js";
import { Chart, Pie } from "react-chartjs-2";
import moment from "moment";
import "chartjs-adapter-moment";
import { Box, Heading } from "grommet";
import { Button, ButtonProps, Fab, ListItemButton, ListItemIcon, ListItemText, Menu } from "@mui/material";
import { useWindowDimensions } from "../../hooks";
import { FilterWasteDashboardDialog, LineItemLike, WasteReportingHook, useFilteredDate } from "../../features/admin/common/views/WasteDiversionDashboard";
import { Addchart, ArrowDropDown, Autorenew, FilterAlt, ImportExport, IntegrationInstructions } from "@mui/icons-material";
import { selectIsPartyMode, useAppDispatch, useAppSelector } from "../../store";
import { selectGraphType, selectTimePeriodFilter, selectTimePeriodGroup, setGraphType, setTimePeriodGroup } from "../../store/reporting";
import colorLib from "@kurkle/color";
import { DialogWithActivatorButton } from "../../components";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
	ArcElement,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale,
	ChartDataLabels
);

interface WasteDiversionChartProps {
	isMultifamily: boolean;
	report: WasteReportingHook;
}

const COLORS = [
	"#4dc9f6",
	"#f67019",
	"#f53794",
	"#537bc4",
	"#acc236",
	"#166a8f",
	"#00a950",
	"#58595b",
	"#8549ba"
];

export function color(index: number) {
	return COLORS[ index % COLORS.length ];
}

export function transparentize(value: string, opacity: number) {
	const alpha = opacity === undefined ? 0.5 : 1 - opacity;
	return colorLib(value).alpha(alpha).rgbString();
}


function colorize(opaque: boolean, hover: boolean, ctx: ScriptableContext<"pie">) {
	const v = ctx.parsed;
	const c = v < -50 ? "#D60000"
		: v < 0 ? "#F46300"
			: v < 50 ? "#0358B6"
				: "#44DE28";

	const opacity = hover ? 1 - Math.abs(v / 150) - 0.2 : 1 - Math.abs(v / 150);

	return opaque ? c : transparentize(c, opacity);
}

function hoverColorize(ctx: ScriptableContext<"pie">) {
	return colorize(false, true, ctx);
}

export function useLineItemMaterials(lineItems: LineItemLike[]) {
	const materials = useMemo(() => {
		return lineItems.reduce((acc, item) => {
			if(!item.material) return acc;

			if(!acc.find(value => value.materialName === item.material?.groupName)) {
				acc.push({ materialId: item.material.id, materialName: item.material.groupName, lineItems: [ item ] });
			}
			else {
				const index = acc.findIndex(value => value.materialName === item.material?.groupName);
				acc[ index ].lineItems.push(item);
			}

			return acc;
		}, [] as Array<{ materialId: string, materialName: string, lineItems: typeof lineItems; }>);
	}, [ lineItems ]);

	return useMemo(() => {
		return materials.map(item => {
			return {
				...item,
				totalWeightReusedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightReusedPounds;
					return acc;
				}, 0),
				totalWeightRecycledPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightRecycledPounds;
					return acc;
				}, 0),
				totalWeightCompostedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightCompostedPounds;
					return acc;
				}, 0),
				totalWeightDonatedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightDonatedPounds;
					return acc;
				}, 0),
				totalWeightIncineratedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightIncineratedPounds;
					return acc;
				}, 0),
				totalWeightLandfilledPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightLandfilledPounds;
					return acc;
				}, 0),
				totalWeightDivertedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightDivertedPounds;
					return acc;
				}, 0),
				totalWeightGeneratedPounds: item.lineItems.reduce((acc, item) => {
					acc += item.totalWeightGeneratedPounds;
					return acc;
				}, 0),
			};
		});
	}, [ materials ]);
}

export const WasteDiversionPieChart: React.FC<WasteDiversionChartProps> = (props) => {
	const lineItems = useMemo(() => {
		return props.report.lineItems ?? [];
	}, [ props ]);

	const materials = useLineItemMaterials(lineItems);

	const filteredMaterials = useMemo(() => {
		return materials.filter(material => material.totalWeightDivertedPounds > 0);
	}, [ materials ]);

	return (
		<Box width="100%" align="center">
			{lineItems.length === 0 || materials.length === 0 && (
				<Box flex align="center" justify="center">
					<Heading level="4">
						No data found
					</Heading>
				</Box>
			)}
			{lineItems.length > 0 && materials.length > 0 && (
				<Pie
					data={{
						labels: filteredMaterials.map(m => m.materialName),
						datasets: [
							{
								data: filteredMaterials.map(m => m.totalWeightDivertedPounds),
								label: "Waste Diverted",
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(255, 206, 86, 0.2)",
									"rgba(75, 192, 192, 0.2)",
									"rgba(153, 102, 255, 0.2)",
									"rgba(255, 159, 64, 0.2)",
								],
								borderColor: [
									"rgba(255, 99, 132, 1)",
									"rgba(54, 162, 235, 1)",
									"rgba(255, 206, 86, 1)",
									"rgba(75, 192, 192, 1)",
									"rgba(153, 102, 255, 1)",
									"rgba(255, 159, 64, 1)",
								],
								borderWidth: 1,
							}
						]
					}}
					title="Waste Diverted"
					options={{
						responsive: true,
						plugins: {
							datalabels: {
								display: "auto",
								formatter: (value, ctx) => {
									console.log(ctx);
									const sum = ctx.dataset.data.reduce((acc, item) => {
										const add = typeof item === "number" ? item : 0;

										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										///@ts-ignore
										return acc + add;
									}, 0 as number) as number;

									return (value * 100 / sum).toFixed(2) + "%";
								}
							},
							title: { display: true, text: "Waste Diverted (lbs)" },
							legend: { display: true }
						},
						elements: {
							arc: {
								backgroundColor: colorize.bind(null, false, false),
								hoverBackgroundColor: hoverColorize
							}
						}
					}}
				/>
			)}
		</Box>
	);
};



export const WasteGeneratedPieChart: React.FC<WasteDiversionChartProps> = (props) => {
	const lineItems = useMemo(() => {
		return props.report.lineItems ?? [];
	}, [ props ]);


	const materials = useLineItemMaterials(lineItems);

	const filteredMaterials = useMemo(() => {
		return materials.filter(material => material.totalWeightGeneratedPounds > 0);
	}, [ materials ]);

	return (
		<Box width="100%">
			{lineItems.length === 0 || materials.length === 0 && (
				<Box flex align="center" justify="center">
					<Heading level="4">
						No data found
					</Heading>
				</Box>
			)}
			{lineItems.length > 0 && materials.length > 0 && (
				<Pie
					data={{
						labels: filteredMaterials.map(m => m.materialName),
						datasets: [
							{
								data: filteredMaterials.map(m => m.totalWeightGeneratedPounds),
								label: "Waste Diverted",
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(255, 206, 86, 0.2)",
									"rgba(75, 192, 192, 0.2)",
									"rgba(153, 102, 255, 0.2)",
									"rgba(255, 159, 64, 0.2)",
								],
								borderColor: [
									"rgba(255, 99, 132, 1)",
									"rgba(54, 162, 235, 1)",
									"rgba(255, 206, 86, 1)",
									"rgba(75, 192, 192, 1)",
									"rgba(153, 102, 255, 1)",
									"rgba(255, 159, 64, 1)",
								],
								borderWidth: 1,
							}
						]
					}}
					options={{
						responsive: true,
						plugins: {
							datalabels: {
								display: "auto",
								formatter: (value, ctx) => {
									const sum = ctx.dataset.data.reduce((acc, item) => {
										const add = typeof item === "number" ? item : 0;

										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										///@ts-ignore
										return acc + add;
									}, 0 as number) as number;

									return (value * 100 / sum).toFixed(2) + "%";
								}
							},
							title: { display: true, text: "Waste Generated (lbs)" },
							legend: { display: true }
						},
						elements: {
							arc: {
								backgroundColor: colorize.bind(null, false, false),
								hoverBackgroundColor: hoverColorize
							}
						}
					}}
				/>
			)}
		</Box>
	);
};

export const WasteDiversionBarChart: React.FC<WasteDiversionChartProps> = (props) => {
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);

	const lineItems = useMemo(() => {
		return props.report.lineItems ?? [];
	}, [ props ]);

	const { startingDate, endingDate } = useFilteredDate();
	const labels = useMemo(() => {
		switch(timePeriodGroup) {
			case "HOUR": {
				return [
					"12:00 AM",
					"01:00 AM",
					"02:00 AM",
					"03:00 AM",
					"04:00 AM",
					"05:00 AM",
					"06:00 AM",
					"07:00 AM",
					"08:00 AM",
					"09:00 AM",
					"10:00 AM",
					"11:00 AM",
					"12:00 PM",
					"01:00 PM",
					"02:00 PM",
					"03:00 PM",
					"04:00 PM",
					"05:00 PM",
					"06:00 PM",
					"07:00 PM",
					"08:00 PM",
					"09:00 PM",
					"10:00 PM",
					"11:00 PM"
				];
			}
			case "DAY": {
				const days = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					days.push(start.format("MMM DD"));
					start.add(1, "day");
				}

				return days;
			}
			case "DAY_OF_WEEK": {
				return [
					"Sunday",
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday"
				];
			}
			case "MONTH": {
				const months = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					months.push(start.format("MMM YY"));
					start.add(1, "month");
				}

				return months;
			}
			case "QUARTER": {
				const quarters = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					quarters.push("Q" + start.format("Q YY"));
					start.add(3, "months");
				}

				return quarters;
			}
			case "WEEK": {
				const weeks = [];
				const start = moment(startingDate).endOf("week");
				const end = moment(endingDate).endOf("week");

				while(start.unix() < end.unix()) {
					weeks.push(start.format("MMM DD"));
					start.add(1, "week");
				}

				return weeks;
			}
		}
	}, [ startingDate, endingDate, timePeriodGroup ]);

	const dataset = useMemo(() => {
		switch(timePeriodGroup) {
			case "HOUR": {
				const periods = labels ?? [];

				return periods.map(period => {
					const periodTimestamp = moment(period, "hh:mm A");

					return lineItems.filter(item => {
						const date = moment(item.date);
						return date.hour() === periodTimestamp.hour();
					});
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: reduced.weightGenerated
							? Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2))
							: 0,
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "DAY": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM DD").unix() - moment(b, "MMM DD").unix();
				}).map(period => {
					const date = moment(period, "MMM DD");
					const filtered = lineItems.filter(item => {
						return moment(item.date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD");
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "DAY_OF_WEEK": {
				const periods = labels ?? [];

				return periods.map((period, index) => {
					const filtered = lineItems.filter(order => {
						return moment(order.date).day() === index;
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "MONTH": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM YY").unix() - moment(b, "MMM YY").unix();
				}).map(period => {
					const date = moment(period, "MMM YY");
					const filtered = lineItems.filter(order => {
						return moment(order.date).month() === date.month() && moment(order.date).year() === date.year();
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "QUARTER": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a.substring(1), "Q YY").unix() - moment(b.substring(1), "Q YY").unix();
				}).map(period => {
					const date = moment(period.substring(1), "Q YY");
					const filtered = lineItems.filter(order => {
						return moment(order.date).quarter() === date.quarter() && moment(order.date).year() === date.year();
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "WEEK": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM DD").unix() - moment(b, "MMM DD").unix();
				}).map(period => {
					const date = moment(period, "MMM DD");
					const filtered = lineItems.filter(order => {
						return moment(order.date).format("YYYY-MM-DD") >= date.startOf("week").format("YYYY-MM-DD") && moment(order.date).format("YYYY-MM-DD") <= date.endOf("week").format("YYYY-MM-DD");
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
		}
	}, [ timePeriodGroup, lineItems, labels ]);

	const isPartyMode = useAppSelector(selectIsPartyMode);

	//background-image: linear-gradient(to right, #780206 0%, #061161 51%, #780206 100%);

	const data = useMemo(() => {
		return {
			labels,
			datasets: [
				{
					type: "bar" as const,
					label: "Waste Generated",
					backgroundColor: isPartyMode ? "#07294d" : "#263D0B",
					data: dataset?.map(dataset => dataset.weightGenerated) ?? [],
					order: 1
				},
				{
					type: "bar" as const,
					label: "Waste Diverted",
					backgroundColor: isPartyMode ? "#ffc600" : "#76A96C",
					data: dataset?.map(dataset => dataset.weightDiverted) ?? [],
					order: 1
				},
				{
					type: "line" as const,
					label: "Diversion Rate",
					borderColor: "rgb(53, 162, 235)",
					backgroundColor: "rgb(53, 162, 235)",
					data: dataset?.map(dataset => dataset.diversionRate) ?? [],
					yAxisID: "y1",
				},
				localStorage.getItem("ENABLE_DEMO") === "true" ? {
					type: "line" as const,
					label: "Contamination Rate",
					borderColor: "rgb(255, 99, 132)",
					backgroundColor: "rgb(255, 99, 132)",
					data: dataset?.map(dataset => 17.25) ?? [],
					yAxisID: "y1",
				} : undefined
			].filter(isNotNull),
		};

	}, [ labels, isPartyMode, dataset ]);

	return (
		<Chart
			type="bar" data={data}
			options={{
				responsive: true,
				plugins: {
					datalabels: { display: false },
				},
				scales: {
					y: {
						type: "linear",
						title: {
							display: true,
							text: "Weight (lbs)",
							font: {
								size: 20,
								weight: "bold"
							}
						},
						display: true,
						position: "left"
					},
					y1: {
						type: "linear",
						max: 100,
						min: 0,
						title: {
							display: true,
							text: "Diversion Rate (%)",
							font: {
								size: 20,
								weight: "bold"
							}
						},
						display: true,
						position: "right",
						grid: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						}
					}
				}
			}}
		/>
	);
};

interface ChartContainerProps {
	report: WasteReportingHook;
	isMultifamily: boolean;
}

export const ChartContainer: React.FC<ChartContainerProps> = (props) => {
	const { sizeIndex } = useWindowDimensions();
	const graphType = useAppSelector(selectGraphType);

	return (
		<Box
			flex
			gap="small"
			id="chart-container"
			direction="row"
		>
			<Box width={sizeIndex > 2 ? "75%" : "100%"} justify="center" align="center" height="100%">
				{graphType === "BAR" && (
					<WasteDiversionBarChart
						report={props.report}
						isMultifamily={props.isMultifamily}
					/>
				)}
				{graphType === "PIE" && (
					<Box direction="row" flex gap="large">
						<Box width="50%" height="100%" gap="medium" justify="center">
							<Box align="center">
								<Heading level="4" margin="none">
									Waste Generated (lbs)
								</Heading>
							</Box>
							<WasteGeneratedPieChart
								report={props.report}
								isMultifamily={props.isMultifamily}
							/>
						</Box>
						<Box width="50%" height="100%" gap="medium" justify="center">
							<Box align="center">
								<Heading level="4" margin="none">
									Waste Diverted (lbs)
								</Heading>
							</Box>
							<WasteDiversionPieChart
								report={props.report}
								isMultifamily={props.isMultifamily}
							/>
						</Box>
					</Box>
				)}
			</Box>
			<Box width={sizeIndex <= 2 ? "0%" : "25%"} style={{ position: "relative" }}>
				<ChartControlsSidebar report={props.report} />
			</Box>
		</Box>
	);
};

export const TimePeriodHourlyButton: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Fragment>
			{[ "TODAY", "YESTERDAY" ].includes(timePeriodFilter ?? "") && (
				<Button
					color="primary"
					onClick={() => {
						dispatch(setTimePeriodGroup("HOUR"));
					}}
					className={isPartyMode ? "btn-grad" : undefined}
					variant={timePeriodGroup === "HOUR" ? "contained" : "outlined"}
				>
					Hourly
				</Button>
			)}
		</Fragment>
	);
};

export const TimePeriodDailyButton: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Fragment>
			{![ "LAST_YEAR", "YTD" ].includes(timePeriodFilter ?? "") && (
				<Button
					color="primary"
					onClick={() => {
						dispatch(setTimePeriodGroup("DAY"));
					}}
					className={isPartyMode ? "btn-grad" : undefined}
					variant={timePeriodGroup === "DAY" ? "contained" : "outlined"}
				>
					Daily
				</Button>
			)}
		</Fragment>
	);
};

export const TimePeriodWeeklyButton: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Fragment>
			{![ "LAST_YEAR", "YTD" ].includes(timePeriodFilter ?? "") && (
				<Button
					color="primary"
					onClick={() => {
						dispatch(setTimePeriodGroup("WEEK"));
					}}
					className={isPartyMode ? "btn-grad" : undefined}
					variant={timePeriodGroup === "WEEK" ? "contained" : "outlined"}
				>
					Weekly
				</Button>
			)}
		</Fragment>
	);
};

export const TimePeriodMonthlyButton: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Fragment>
			<Button
				color="primary"
				onClick={() => {
					dispatch(setTimePeriodGroup("MONTH"));
				}}
				className={isPartyMode ? "btn-grad" : undefined}
				variant={timePeriodGroup === "MONTH" ? "contained" : "outlined"}
			>
				Monthly
			</Button>
		</Fragment>
	);
};

export const TimePeriodQuarterlyButton: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Fragment>
			<Button
				color="primary"
				onClick={() => {
					dispatch(setTimePeriodGroup("QUARTER"));
				}}
				className={isPartyMode ? "btn-grad" : undefined}
				variant={timePeriodGroup === "QUARTER" ? "contained" : "outlined"}
			>
				Quarterly
			</Button>
		</Fragment>
	);
};

export const ChartControlsDialog: React.FC<{
	report: WasteReportingHook;
	activatorButton?: React.ReactNode;
}> = ({ report, activatorButton }) => {
	return (
		<DialogWithActivatorButton
			title="Chart Controls"
			activatorButton={activatorButton}
		>
			<ChartTimePeriodControls report={report} />
		</DialogWithActivatorButton>
	);
};

export const ChartControlsContainer: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	return (
		<Fragment>
			{sizeIndex <= 2 ? (
				<ChartControlsDialog report={report} />
			) : (
				<ChartControlsSidebar report={report} />
			)}
		</Fragment>
	);
};

import { CSVLink } from "react-csv";
import { DocumentExcel } from "grommet-icons";
import { isNotNull } from "../../helpers";

export const ChartTimePeriodControls: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const dispatch = useAppDispatch();
	const { sizeIndex } = useWindowDimensions();
	const graphType = useAppSelector(selectGraphType);
	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Box flex width="100%" gap="medium">
			<Box gap="small">
				<Box align="center">
					<Heading margin="none" level="4" textAlign="center">
						Time Period
					</Heading>
				</Box>
				<TimePeriodHourlyButton />
				<TimePeriodDailyButton />
				<TimePeriodWeeklyButton />
				<TimePeriodMonthlyButton />
				<TimePeriodQuarterlyButton />
			</Box>
			<Box justify="end" flex gap="small">
				<ReportingButton
					color="primary"
					variant="contained"
					startIcon={<Autorenew />}
					className={isPartyMode ? "btn-grad" : undefined}
					onClick={(() => {
						dispatch(setGraphType(graphType === "BAR" ? "PIE" : "BAR"));
					})}
				>
					Toggle Graph
				</ReportingButton>
				<FilterWasteDashboardDialog
					activatorButton={(
						<ReportingButton
							color="primary"
							variant="contained"
							startIcon={<FilterAlt />}
							className={isPartyMode ? "btn-grad" : undefined}
						>
							Filter
						</ReportingButton>
					)}
				/>
				<ReportExportButton report={report} />
			</Box>
		</Box>
	);
};

export const ReportExportDropdownButton: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const isPartyMode = useAppSelector(selectIsPartyMode);
	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<ReportingButton
				fullWidth
				id="export-button"
				color="primary"
				variant="contained"
				startIcon={<ImportExport />}
				endIcon={<ArrowDropDown />}
				onClick={handleClick}
				className={isPartyMode ? "btn-grad" : undefined}
			>
				Export
			</ReportingButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "export-button",
				}}
			>
				<CSVExportWrapper report={report} disabled={report.lineItems.length === 0}>
					<ListItemButton
						disabled={report.lineItems.length === 0}
					>
						<ListItemIcon>
							<DocumentExcel />
						</ListItemIcon>
						<ListItemText>
							Export to Excel (CSV)
						</ListItemText>
					</ListItemButton>
				</CSVExportWrapper>
				<ListItemButton>
					<ListItemIcon>
						<IntegrationInstructions />
					</ListItemIcon>
					<ListItemText>
						Export to ARC
					</ListItemText>
				</ListItemButton>
			</Menu>
		</Box>
	);
};

export const CSVExportWrapper: React.FC<{
	disabled?: boolean;
	children: React.ReactNode;
	report: WasteReportingHook;
}> = ({ report, children, disabled }) => {
	if(disabled) {
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	}

	return (
		<CSVLink
			data={report.lineItems.map(lineItem => {
				return {
					date: moment.tz(lineItem.date, "UTC").tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss"),
					materialId: lineItem.material?.id || "",
					materialName: lineItem.material?.groupName || "",
					totalWeightCompostedPounds: lineItem.totalWeightCompostedPounds,
					totalWeightDivertedPounds: lineItem.totalWeightDivertedPounds,
					totalWeightDonatedPounds: lineItem.totalWeightDonatedPounds,
					totalWeightGeneratedPounds: lineItem.totalWeightGeneratedPounds,
					totalWeightIncineratedPounds: lineItem.totalWeightIncineratedPounds,
					totalWeightLandfilledPounds: lineItem.totalWeightLandfilledPounds,
					totalWeightRecycledPounds: lineItem.totalWeightRecycledPounds,
					totalWeightReusedPounds: lineItem.totalWeightReusedPounds,
				};
			})}
			filename="export.csv"
			target="_blank"
			style={{ textDecoration: "none", color: "inherit" }}
		>
			{children}
		</CSVLink>
	);
};

export const ReportingButton: React.FC<{
	children: string;
} & ButtonProps> = ({ children, ...props }) => {
	return (
		<Button
			{...props}
			sx={{
				"& .MuiButton-startIcon": {
					left: 16,
					position: "absolute",
				},
				"& .MuiButton-endIcon": {
					right: 16,
					position: "absolute",
				}
			}}
		>
			{children}
		</Button>
	);
};

export const ReportExportButton: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const isPartyMode = useAppSelector(selectIsPartyMode);
	const isDemoEnabled = localStorage.getItem("ENABLE_DEMO") === "true";

	if(isDemoEnabled) {
		return (
			<ReportExportDropdownButton report={report} />
		);
	}

	return (
		<CSVExportWrapper report={report} disabled={report.lineItems.length === 0}>
			<ReportingButton
				fullWidth
				disabled={report.lineItems.length === 0}
				id="export-button"
				color="primary"
				variant="contained"
				startIcon={<ImportExport />}
				className={isPartyMode ? "btn-grad" : undefined}
			>
				Export
			</ReportingButton>
		</CSVExportWrapper>
	);
};

export const ChartControlsSidebar: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	if(sizeIndex <= 2) {
		return (
			<ChartControlsDialog
				report={report}
				activatorButton={(
					<Fab
						style={{
							position: "absolute",
							bottom: "0px",
							right: "0px"
						}}
						color="primary"
						variant="circular"
					>
						<Addchart />
					</Fab>
				)}
			/>
		);
	}

	return (
		<Box
			flex
			align="center"
			direction={sizeIndex <= 2 ? "row" : "column"}
		>
			<ChartTimePeriodControls report={report} />
		</Box>
	);
};