import { gql } from "../../../__generated__";

export const FetchWasteAuditZone = gql(/* GraphQL */ `
	query FetchWasteAuditZone($zoneId: String!) {
		FetchWasteAuditZone(zoneId: $zoneId) {
			...WasteAuditZone_HeaderFragment
			...WasteAuditZone_MetricsFragment
			...WasteAuditZone_CollectionsFragment
		}
	}
`);