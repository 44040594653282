import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { selectFilters, setFiltersApplied } from "../../../store/shop";
import { ListProductsForSaleQuery, ListProductsForSaleQueryVariables } from "../../../graphql/__generated__/graphql";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ListProductsForSale } from "../../../graphql";
import { useBrands } from "../../product/hooks/useBrands";
import { isNotNull } from "../../../helpers";

export function useStoreProducts(
	instanceId: string,
	_limit = 10
) {
	const dispatch = useAppDispatch();

	const {
		brandFilter,
		priceLowFilter,
		priceHighFilter,
		conditionFilter,
		wasFilterApplied
	} = useAppSelector(selectFilters);

	const [ { limit, page, total, lastPage }, setPagination ] = useState({
		limit: _limit,
		page: 1,
		total: 0,
		lastPage: 1
	});

	function resetPagination(): void {
		setPagination({
			limit: _limit,
			page: 1,
			total: 0,
			lastPage: 1
		});
	}

	useEffect(() => {
		if(wasFilterApplied === false) {
			resetPagination();
			setProducts([]);
			dispatch(setFiltersApplied(true));
		}
	}, [ dispatch, resetPagination, wasFilterApplied ]);

	const [
		fetchProductsQuery,
		{ data, loading, error, refetch }
	] = useLazyQuery(ListProductsForSale, {
		fetchPolicy: "no-cache"
	});

	const [ products, setProducts ] = useState<ListProductsForSaleQuery[ "ListProductsForSale" ][ "data" ]>([]);

	useEffect(() => {
		if(data?.ListProductsForSale) {
			setProducts([
				...products,
				...data.ListProductsForSale.data.filter(product => !products.find(p => p.id === product.id))
			]);
		}
	}, [ data, products ]);

	useEffect(() => {
		if(data?.ListProductsForSale) {
			setPagination({
				limit: data.ListProductsForSale.limit,
				page: data.ListProductsForSale.page,
				total: data.ListProductsForSale.total,
				lastPage: data.ListProductsForSale.lastPage
			});
		}
	}, [ data ]);

	const brands = useBrands();

	const fetchVariables = useMemo(() => {
		const variables: ListProductsForSaleQueryVariables = {
			instanceId,
			page,
			limit
		};

		if(brandFilter && brandFilter.length > 0) {
			variables.brands = brandFilter.map(brandName => {
				return brands.find(brand => brand.name === brandName)?.id;
			}).filter(isNotNull);
		}

		if(priceLowFilter) {
			variables.priceMin = Number(priceLowFilter);
		}

		if(priceHighFilter) {
			variables.priceMax = Number(priceHighFilter);
		}

		if(conditionFilter && conditionFilter.length > 0) {
			variables.conditions = conditionFilter;
		}

		return variables;
	}, [ instanceId, page, limit, brandFilter, priceLowFilter, priceHighFilter, conditionFilter, brands ]);

	useEffect(() => {
		if(!wasFilterApplied) return;

		console.log("fetching products with variables: ", fetchVariables);
		if(!data?.ListProductsForSale) {
			fetchProductsQuery({
				variables: fetchVariables
			}).catch(err => {
				console.error("Error while fetching products: ", err);
			});
		}
		else {
			console.log("refetching products with variables: ", fetchVariables);
			refetch(fetchVariables).catch(err => {
				console.error("Error while fetching products: ", err);
			});
		}
	}, [ page, wasFilterApplied ]);

	return {
		page,
		limit,
		loading,
		total,
		reset: () => {
			setProducts([]);
			setPagination({
				limit: _limit,
				page: 1,
				total: 0,
				lastPage: 1
			});
		},
		fetchNextPage: () => {
			if(page >= lastPage) {
				return;
			}
			setPagination({
				page: page + 1,
				limit,
				total,
				lastPage
			});
		},
		products
	};
}