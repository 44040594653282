import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { FetchContainerAssignmentPublic } from "../../../graphql";
import { useAppDispatch } from "../../../store";
import { goBack, goForward, push } from "redux-first-history";

export function usePublicShareContainerNavigation(assignmentId: string) {
	const dispatch = useAppDispatch();

	return {
		back: () => {
			dispatch(goBack());
		},
		forward: () => {
			dispatch(goForward());
		},
		redirect: (path: string) => {
			dispatch(push(`/c/${assignmentId}/${path}`));
		}
	};
}

export function usePublicShareContainer(
	assignmentId: string
) {
	const { data, loading } = useQuery(
		FetchContainerAssignmentPublic,
		{ skip: !assignmentId, variables: { assignmentId } }
	);

	const instance = useMemo(() => {
		return data?.FetchWasteContainerAssignment.partner.instance || null;
	}, [ data ]);

	return {
		loading,
		instance,
		container: data?.FetchWasteContainerAssignment || null
	};
}