import { gql } from "../../../__generated__";

export const CreateReferenceMedia = gql(/* GraphQL */ `
	mutation CreateReferenceMedia(
		$content: String!
		$contentType: String!
		$name: String!
		$referenceId: String!
		$context: ProductMediaContext!
	) {
		CreateReferenceMedia(
			content: $content
			contentType: $contentType
			name: $name
			referenceId: $referenceId
			context: $context
		) {
			id
		}
	}
`);