import React, { useCallback, useEffect } from "react";
import { useWasteAuditZone } from "../../../../../../graphql";
import { WasteAuditZoneType } from "../../../../../../graphql/__generated__/graphql";
import { useForm } from "react-hook-form";
import { WasteAuditZoneDialogCommon } from "./WasteAuditZoneDialogCommon";
import { WasteAuditZoneFormContext } from "./WasteAuditZoneForm";
import { useSnackbar } from "notistack";

export const UpdateWasteAuditZoneDialog: React.FC<{
	zoneId: string;
	onClose: () => void;
}> = ({ zoneId, onClose }) => {
	const snack = useSnackbar();
	const { zone, loading, update, isUpdating } = useWasteAuditZone(zoneId);

	const formContext = useForm<WasteAuditZoneFormContext>({
		defaultValues: {
			name: zone?.name || "",
			type: zone?.type || WasteAuditZoneType.Other,
			totalWeight: zone?.totalWeight || 0,
		},
	});

	useEffect(() => {
		if(zone) {
			formContext.reset({
				name: zone?.name || "",
				type: zone?.type || WasteAuditZoneType.Other,
				totalWeight: zone?.totalWeight || 0,
			});
		}
	}, [ formContext, zone ]);

	const onSubmit = useCallback(() => {
		const { name, type, totalWeight } = formContext.getValues();

		return update({
			zoneId,
			name,
			type,
			totalWeight: totalWeight || 0,
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to update waste audit zone", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}, [ formContext, onClose, snack, update, zoneId ]);

	return (
		<WasteAuditZoneDialogCommon
			title="Update Zone"
			loading={loading}
			onClose={onClose}
			formContext={formContext}
			onSubmit={onSubmit}
			onSubmitLabel="Update"
			isSubmitting={isUpdating}
		/>
	);
};