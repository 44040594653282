import React, { Fragment, useState } from "react";
import { Button } from "@mui/material";
import { Assessment } from "@mui/icons-material";
import { CreateWasteAuditDialog } from "./WasteAuditForm";

export const CreateWasteAuditButton: React.FC<{
	title?: string;
}> = ({ title }) => {
	const [ isCreating, setIsCreating ] = useState(false);

	return (
		<Fragment>
			{isCreating && (
				<CreateWasteAuditDialog
					onClose={() => setIsCreating(false)}
				/>
			)}
			<Button
				color="primary"
				variant="contained"
				startIcon={(
					<Assessment />
				)}
				onClick={() => setIsCreating(true)}
			>
				{title || "Create New"}
			</Button>
		</Fragment>
	);
};