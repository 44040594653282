import React, { ReactNode } from "react";
import { Box } from "grommet";

export const WasteAuditTabContentContainer: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	return (
		<Box height="100%" overflow={{ vertical: "scroll" }} gap="medium" style={{ display: "block" }}>
			{children}
		</Box>
	);
};