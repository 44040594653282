import React, { useMemo } from "react";
import { useWasteAudit } from "../../hooks";
import { WasteAuditTabContentContainer } from "./WasteAuditTabContentContainer";
import { CreateWasteAuditZoneDialog, ZoneAccordion, ZoneTabContent } from "./zone";
import { Box } from "grommet";
import { Button, Typography } from "@mui/material";

export const WasteAuditZonesContent: React.FC<{
	zoneId: string;
	auditId: string;
}> = ({ zoneId, auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const [ isCreating, setIsCreating ] = React.useState(false);

	const zones = useMemo(() => {
		return audit?.zones || [];
	}, [ audit ]);

	return (
		<WasteAuditTabContentContainer>
			{isCreating && (
				<CreateWasteAuditZoneDialog
					auditId={auditId}
					onClose={() => setIsCreating(false)}
				/>
			)}
			<Box align="start" style={{ marginTop: "6px" }}>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => setIsCreating(true)}
				>
					Create Zone
				</Button>
			</Box>
			{zones.length === 0 && (
				<Box align="center" justify="center">
					<Typography>
						no zones found
					</Typography>
				</Box>
			)}
			<Box>
				{zones.map(zone => (
					<ZoneAccordion
						key={zone.id}
						zoneId={zone.id}
						summary={zone.name}
					>
						<ZoneTabContent
							zoneId={zone.id}
						/>
					</ZoneAccordion>
				))}
			</Box>
		</WasteAuditTabContentContainer>
	);
};
