import { gql } from "@apollo/client";

export const Building_WasteContainerFragment = gql`
	fragment Building_WasteContainerFragment on Building {
		id
		name
		address {
			...Address_HeaderFragment
			...Address_CountryFragment
		}
		containers {
			id
			name
			location
			volume
			volumeUnit
			collectionFrequency {
				period
				dayOfWeek
			}
			container {
				id
				name
				iconUrl
			}
			material {
				id
				name
				groupName
			}
			disposition
			averageFillRate
			weeklyCollectionWeight
			averageCollectionWeight
			monthlyCollectionWeight
		}
		weeklyCollectionWeight
		monthlyCollectionWeight
	}
`;