import { Box } from "grommet";
import { useOutlet } from "../../../graphql";
import { IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Logout, Visibility } from "@mui/icons-material";
import { useWindowDimensions } from "../../../hooks";
import { AddressStopDetailsDialog } from "../../../components";

export const OutletDetailsListItem: React.FC<{
	outletId: string;
}> = ({ outletId }) => {
	const { size } = useWindowDimensions();
	const { outlet } = useOutlet(outletId);

	return (
		<AddressStopDetailsDialog
			outletName={outlet?.name ?? ""}
			addressId={outlet?.address?.id ?? ""}
			activatorButton={(
				<ListItemButton
					divider
				>
					<ListItemIcon>
						<Logout />
					</ListItemIcon>
					<ListItemText>
						<Box>
							<Typography
								className="text-max-1-line"
								sx={{
									maxWidth: size === "small" ? "240px" : "300px"
								}}
							>
								{outlet?.name ?? ""}
							</Typography>
							<Typography
								className="text-max-1-line"
								sx={{
									maxWidth: size === "small" ? "240px" : "300px"
								}}
							>
								{`${outlet?.address?.locality}, ${outlet?.address?.administrativeArea} ${outlet?.address?.postalCode}`}
							</Typography>
						</Box>
					</ListItemText>
					<ListItemSecondaryAction>
						<IconButton>
							<Visibility />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItemButton>
			)}
		/>
	);
};