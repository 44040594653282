import React, { useEffect, useMemo, useState } from "react";
import { FormContainer, SelectElement, TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { Box, Carousel, Heading, Image } from "grommet";
import { ProductDisposition, ProductMediaContext } from "../../../../../../graphql/__generated__/graphql";
import { Button, Collapse, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import { CreateMediaDTO } from "../../../../../../types";
import { AddAPhoto, CloudUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { fileToBase64, toProperCase } from "../../../../../../helpers";
import { VisuallyHiddenInput } from "../../../../../../components";
import { useWindowDimensions } from "../../../../../../hooks";

export interface WasteAuditCollectionFormContext {
	label: string;
	roomName: string;
	totalWeight: number;
	containerName: string;
	disposition: ProductDisposition | null;
	media: { id: string, name: string, contentUrl: string; }[];
	mediaUploadQueue: CreateMediaDTO[];
}

export const WasteAuditCollectionForm: React.FC<{
	formContext: UseFormReturn<WasteAuditCollectionFormContext>;
}> = ({ formContext }) => {
	const theme = useTheme();
	const { size } = useWindowDimensions();
	const [ isUploading, setIsUploading ] = useState(false);
	const [ collapseMetadata, setCollapseMetadata ] = useState(true);
	const [ mediaUploadQueue, setMediaUploadQueue ] = useState<CreateMediaDTO[]>([]);
	const ref = React.createRef<HTMLInputElement>();

	const originalMedia = formContext.watch("media");

	const media = useMemo(() => {
		return [
			...originalMedia,
			...mediaUploadQueue.map(media => ({
				id: media.name,
				name: media.name,
				contentUrl: URL.createObjectURL(media.file)
			}))
		];
	}, [ originalMedia, mediaUploadQueue ]);

	function onFileUploaded(file: File): void {
		setIsUploading(true);
		fileToBase64(file).then(base64Content => {
			setMediaUploadQueue((media) => {
				return [
					...media,
					{
						base64Content,
						contentType: file.type,
						file,
						name: file.name,
						context: ProductMediaContext.Default
					}
				];
			});
		}).finally(() => {
			setIsUploading(false);
		});
	}

	useEffect(() => {
		formContext.setValue("mediaUploadQueue", mediaUploadQueue);
	}, [ formContext, mediaUploadQueue ]);

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				<TextFieldElement
					name="label"
					label="Label / Description"
					helperText="A label to help identify this bag / upload"
				/>
				<SelectElement
					name="disposition"
					label="Disposition"
					options={
						Object.values(ProductDisposition).map(disposition => ({
							id: disposition,
							label: toProperCase(disposition)
						}))
					}
					helperText="Where is this waste supposed to go?"
				/>
				<TextFieldElement
					name="totalWeight"
					required
					label="Total Weight"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								lb(s)
							</InputAdornment>
						)
					}}
					type="number"
					validation={{
						required: "Weight is required",
						min: 0,
						pattern: /^[0-9]+(\.[0-9]+)?$/
					}}
					helperText="The total weight of the contents"
				/>
				<Box direction="row" justify="between" align="center">
					<Box>
						<Typography
							fontWeight="bold"
							color={theme.palette.primary.main}
						>
							Reporting Metadata
						</Typography>
						<Typography
							variant="caption"
							sx={{
								maxWidth: size === "small" ? "250px" : undefined
							}}
						>
							This data is used for reporting and filtering but is not required.
						</Typography>
					</Box>
					<Box>
						<Button
							size="small"
							color="primary"
							variant="outlined"
							onClick={() => setCollapseMetadata(!collapseMetadata)}
						>
							{collapseMetadata ? "Show" : "Hide"}
						</Button>
					</Box>
				</Box>
				<Collapse in={!collapseMetadata}>
					<Box gap="medium">
						<TextFieldElement
							name="containerName"
							label="Container Name / Label"
						/>
						<TextFieldElement
							name="roomName"
							label="Room Name / Label"
						/>
					</Box>
				</Collapse>
				<Box gap="medium">
					<Box gap="small">
						<Heading margin="none" level="3">
							Upload Image(s)
						</Heading>
						<Typography variant="body2">
							Upload an image and our Waste Inspector tool with automatically analyze the waste composition of your image. Make sure the contents of the container are spread out (on something like a tarp) and visible in the image.
						</Typography>
					</Box>
					<Box gap="small">
						{media.length > 0 && (
							<Box height="small">
								<Carousel wrap height="100%">
									{media.map((media,) => (
										<Image
											key={media.name}
											src={media.contentUrl}
											fit="contain"
										/>
									))}
								</Carousel>
							</Box>
						)}
						{media.length === 0 && (
							<Box
								round
								height="small"
								background="light-1"
								align="center" justify="center"
							>
								<IconButton
									onClick={() => {
										ref.current?.click();
									}}
								>
									<AddAPhoto
										color="primary"
										fontSize="large"
									/>
								</IconButton>
							</Box>
						)}
						<LoadingButton
							color="primary"
							variant="contained"
							loading={isUploading}
							endIcon={<CloudUpload />}
							loadingPosition="end"
							onClick={(event) => {
								event.stopPropagation();
								ref.current?.click();
							}}
						>
							Upload Image
							<VisuallyHiddenInput
								ref={ref}
								type="file"
								accept=".png, .jpg, .jpeg"
								style={{ display: "none" }}
								onClick={(event) => {
									event.stopPropagation();
								}}
								onChange={(event) => {
									if(event.target.files) {
										for(const file of event.target.files) {
											onFileUploaded(file);
										}
									}
								}} />
						</LoadingButton>
					</Box>
				</Box>
			</Box>
		</FormContainer>
	);
};