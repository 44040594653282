import { Moment } from "moment";
import { DayOfWeek, PickupWindow, ProductMediaContext } from "./graphql/__generated__/graphql";

export interface QuoteDetails {
	subtotal: number;
	discounts: {
		amount: number;
		label: string;
	}[];
	tax: number;
	total: number;
	prohibitedDays: Moment[];
	availableDays: DayOfWeek[];
	availableWindows: PickupWindow[];
	lastEstimateId?: string;
}

export enum TimePeriod {
	CUSTOM = "Custom",
	THIS_MONTH = "This Month",
	LAST_MONTH = "Last Month",
	THIS_YEAR = "This Year",
	LAST_YEAR = "Last Year"
}

export interface MediaLike {
	id?: string;
	name: string;
	content: string;
	contentUrl?: string;
	contentType: string;
}

export interface CreateMediaDTO {
	file: File;
	name: string;
	contentType: string;
	base64Content: string;
	context: ProductMediaContext;
}