import { gql } from "../../../__generated__";

export const FindCountries = gql(/* GraphQL */ `
	query FindCountries {
		FindCountries {
			code
			name
			dialCode
		}
	}
`);