import { gql } from "../../../__generated__";

export const CreateWasteAuditCollection = gql(/* GraphQL */ `
	mutation CreateWasteAuditCollection(
		$zoneId: String!
		$label: String!
		$totalWeight: Float!
		$disposition: ProductDisposition!
		$assignmentId: String
		$media: [CreateMediaDTO!]!
		$metadata: WasteAuditMetadataInputDTO!
	) {
		CreateWasteAuditCollection(
			zoneId: $zoneId
			label: $label
			totalWeight: $totalWeight
			disposition: $disposition
			assignmentId: $assignmentId
			media: $media
			metadata: $metadata
		) {
			id
		}
	}
`);

export const UpdateWasteAuditCollection = gql(/* GraphQL */ `
	mutation UpdateWasteAuditCollection(
		$collectionId: String!
		$label: String
		$totalWeight: Float
		$disposition: ProductDisposition
		$assignmentId: String
		$media: [CreateMediaDTO!]
		$metadata: WasteAuditMetadataInputDTO
	) {
		UpdateWasteAuditCollection(
			collectionId: $collectionId
			label: $label
			totalWeight: $totalWeight
			disposition: $disposition
			assignmentId: $assignmentId
			media: $media
			metadata: $metadata
		) {
			id
		}
	}
`);