import { useMutation, useQuery } from "@apollo/client";
import { FetchWasteAudit, FindWasteAudit, UpdateWasteAudit } from "../../../../graphql";
import { FetchWasteAuditMetrics } from "../../../../graphql/documents/reporting/queries/WasteAuditMetrics";
import { UpdateWasteAuditMutationVariables, WasteAuditAggregationType } from "../../../../graphql/__generated__/graphql";
import { useCallback } from "react";

export function useWasteAudit(auditId: string) {
	const { data, loading } = useQuery(FetchWasteAudit, {
		variables: { auditId },
		skip: !auditId
	});

	const { data: metricsData, loading: metricsLoading } = useQuery(FetchWasteAuditMetrics, {
		variables: {
			auditId,
			aggregateBy: WasteAuditAggregationType.Zone
		},
		skip: !auditId
	});

	const [
		update,
		{ loading: isUpdating }
	] = useMutation(UpdateWasteAudit, {
		refetchQueries: [ FetchWasteAudit ]
	});

	const handleUpdate = useCallback(({ name, type, scheduledDate }: UpdateWasteAuditMutationVariables) => {
		return update({
			variables: {
				auditId,
				name,
				type,
				scheduledDate
			}
		});
	}, [ auditId, update ]);

	return {
		update: handleUpdate,
		isUpdating,
		audit: data?.FetchWasteAudit || null,
		metrics: metricsData?.FetchWasteAuditMetrics || null,
		loading,
		metricsLoading
	};
}

export function useWasteAudits(
	instanceId: string,
	name?: string
) {
	const { data, loading } = useQuery(FindWasteAudit, {
		variables: {
			instance: { id: { equals: instanceId } },
			name: name ? { ilike: name } : undefined
		},
		skip: !instanceId
	});

	return {
		loading,
		audits: data?.FindWasteAudit || []
	};
}