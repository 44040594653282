import React from "react";
import { DirectionsMap, PickupDetailsContainer, PickupIncomingItemsContainer, ViewContainer } from "../../../../components";
import { Box, Grid, Heading } from "grommet";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { PickupDetailsComponent } from "../components";
import { usePickup } from "../hooks";
import { PickupRouteContainer } from "../../../hauler/components";

export const GlobalAdminPickupDetailsView: React.FC = () => {
	const { sizeIndex } = useWindowDimensions();
	const pickupId = useRequiredPathParam("pickupId", "/admin/dashboard");
	const { pickup, loading } = usePickup(pickupId);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Grid columns={{ count: sizeIndex <= 2 ? 1 : 2, size: "auto" }} gap="medium">
					<PickupDetailsContainer
						loading={loading}
						pickup={pickup}
					>
						<PickupDetailsComponent
							pickup={pickup}
							loading={loading}
						/>
					</PickupDetailsContainer>
					<Box>
						<Heading level="3" margin="none">
							Pickup Items
						</Heading>
						<PickupIncomingItemsContainer
							pickup={pickup}
							loading={loading}
						/>
					</Box>
				</Grid>
				<Grid columns={{ count: 1, size: "auto" }} gap="small">
					<Box>
						<PickupRouteContainer
							pickupId={pickup?.id ?? ""}
							stops={(pickup?.stops ?? []).map((stop) => ({
								id: stop.id,
								index: stop.index,
								incomingCount: stop.incomingCount,
								outgoingCount: stop.outgoingCount,
								outlet: stop.outlet || null,
								address: {
									addressLineOne: stop.address.addressLineOne,
									addressLineTwo: stop.address.addressLineTwo ?? "",
									city: stop.address.locality,
									state: stop.address.administrativeArea,
									zipCode: stop.address.postalCode
								}
							}))} />
					</Box>
					<Box flex align="center" justify="center" margin="small">
						<DirectionsMap
							stops={(pickup?.stops ?? []).map((stop) => ({
								index: stop.index,
								addressLineOne: stop.address.addressLineOne,
								addressLineTwo: stop.address.addressLineTwo ?? "",
								city: stop.address.locality,
								state: stop.address.administrativeArea,
								zipCode: stop.address.postalCode
							}))}
						/>
					</Box>
				</Grid>
			</Box>
		</ViewContainer>
	);
};