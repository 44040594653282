import React, { Fragment, ReactNode, useEffect, useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, List, ListItemButton, ListItemIcon, ListItemText, MobileStepper, Typography } from "@mui/material";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { AutocompleteElement, CheckboxElement, FormContainer, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { DialogWithActivatorButton, DialogWithClose } from "../../../../../components";
import { Box, Heading } from "grommet";
import { useBuildings } from "../../hooks";
import { CreatePropertyDialog } from "../property";
import { Business, Delete, Help, MeetingRoom } from "@mui/icons-material";
import { ProductDisposition, VolumeUnit } from "../../../../../graphql/__generated__/graphql";
import { useToggleForceClose } from "../../../components";
import { MaterialDispositionElement } from "../form";

export interface ContainerDTO {
	id: string;
	label: string;
	name: string;
	volume?: number;
	volumeUnit?: VolumeUnit;
	disposition: ProductDisposition;
}

export interface WasteAuditFormState {
	name: string;
	partnerId: string;
	configType: "floor" | "room" | "container" | "other";
	totalSteps: number;
	currentStepIndex: number;
	rooms: string[];
	floors: string[];
	addLater?: boolean;
	floorFrom?: number;
	floorTo?: number;
	containerName?: string;
	containerDispotition?: ProductDisposition;
	containers: ContainerDTO[];
}

export const WasteAuditFormController: React.FC<{
	loading?: boolean;
	onClose: () => void;
	onSubmit: () => void;
	formContext: UseFormReturn<WasteAuditFormState>;
}> = ({ onSubmit, loading, onClose, formContext }) => {
	const {
		totalSteps,
		configType,
		currentStepIndex
	} = formContext.watch();

	const hasNext = useMemo(() => {
		return currentStepIndex < totalSteps - 1;
	}, [ currentStepIndex, totalSteps ]);

	const hasBack = useMemo(() => {
		return currentStepIndex > 0;
	}, [ currentStepIndex ]);

	function handleBack(): void {
		if(!hasBack) return;
		formContext.setValue("currentStepIndex", currentStepIndex - 1);
	}

	function handleNext(): void {
		if(!hasNext) {
			formContext.handleSubmit(onSubmit)();
			return;
		}

		formContext.handleSubmit(() => {
			formContext.setValue("currentStepIndex", currentStepIndex + 1);
		})();
	}

	const content = useMemo(() => {
		switch(currentStepIndex) {
			case 0:
				return (
					<WasteAuditDetailsStep
						formContext={formContext}
					/>
				);
			case 1:
				return (
					<WasteAuditConfigStep
						configType={configType}
						updateConfigType={(configType) => formContext.setValue("configType", configType)}
					/>
				);
			case 2:
				return (
					<WasteAuditSetupStep
						configType={configType}
					/>
				);
		}
	}, [ configType, currentStepIndex, formContext ]);

	return (
		<DialogWithClose
			onClose={onClose}
			title="Waste Audit Setup"
			content={(
				<FormContainer formContext={formContext}>
					{content}
				</FormContainer>
			)}
			actions={(
				<MobileStepper
					steps={totalSteps}
					position="static"
					activeStep={currentStepIndex}
					backButton={(
						<Button variant="outlined" onClick={handleBack} disabled={!hasBack}>
							Back
						</Button>
					)}
					nextButton={(
						<LoadingButton loading={loading} variant="contained" onClick={handleNext}>
							{hasNext ? "Next" : "Submit"}
						</LoadingButton>
					)}
				/>
			)}
		/>
	);
};

export const WasteAuditFormV1: React.FC<{
	title: string;
	buttonTitle: string;
	loading?: boolean;
	onSubmit: () => void;
	forceClose?: boolean;
	toggleForceClose?: () => void;
	activatorButton: React.ReactNode;
	formContext: UseFormReturn<{ name: string; partnerId: string; }>;
}> = ({ title, buttonTitle, loading, forceClose, formContext, activatorButton, toggleForceClose, onSubmit }) => {
	const totalSteps = 3;
	const { buildings } = useBuildings();
	const [ isCreaingLocation, setIsCreatingLocation ] = React.useState(false);


	return (
		<DialogWithActivatorButton
			activatorButton={activatorButton}
			forceClose={forceClose}
			title={title}
			onClose={toggleForceClose}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={toggleForceClose}>
						Cancel
					</Button>
					<LoadingButton
						loading={loading}
						color="primary"
						variant="contained"
						onClick={formContext.handleSubmit(onSubmit)}
					>
						{buttonTitle}
					</LoadingButton>
				</Box>
			)}
		>
			<FormContainer
				formContext={formContext}
			>
				{isCreaingLocation && (
					<CreatePropertyDialog onClose={() => setIsCreatingLocation(false)} />
				)}
				<Box gap="medium">
					<TextFieldElement
						fullWidth
						required
						name="name"
						label="Audit Title"
						helperText="Give your audit a name"
					/>
					<SelectElement
						required
						fullWidth
						name="partnerId"
						label="Location"
						options={buildings.map(building => ({
							id: building.id,
							label: building.name
						}))}
					/>
					<Box align="start">
						<Button
							color="primary"
							variant="outlined"
							onClick={() => setIsCreatingLocation(true)}
						>
							New Location
						</Button>
					</Box>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};

function toFloorRange(floor: string): { floorFrom: number; floorTo: number; } {
	if(!floor.includes(" - ")) {
		const floorNumber = Number(floor);
		if(isNaN(floorNumber) || !floorNumber) throw new Error("Invalid floor number");
		return {
			floorFrom: floorNumber,
			floorTo: floorNumber
		};
	}

	const [ floorFrom, floorTo ] = floor.split(" - ").map(f => f.trim()).map(m => Number(m)).map(m => {
		if(isNaN(m) || !m) throw new Error("Invalid floor range");
		return m;
	});

	return {
		floorFrom,
		floorTo
	};
}

function fromFloorRange(floorFrom: number | string, floorTo: number | string): string {
	if(floorFrom === floorTo) return `${floorFrom}`;
	return `${floorFrom} - ${floorTo}`;
}

function isOverlappingRange(a: { floorFrom: number, floorTo: number; }, b: { floorFrom: number, floorTo: number; }): boolean {
	return a.floorFrom <= b.floorTo && a.floorTo >= b.floorFrom;
}

function isExtendedRange(a: { floorFrom: number, floorTo: number; }, b: { floorFrom: number, floorTo: number; }): boolean {
	return a.floorFrom - 1 === b.floorTo || a.floorTo + 1 === b.floorFrom;
}

export const AddFloorDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const { watch, setValue } = useFormContext<WasteAuditFormState>();
	const { floors, floorFrom, floorTo } = watch();

	useEffect(() => {
		if(floorFrom && !floorTo) {
			setValue("floorTo", floorFrom);
		}
	}, [ floorFrom, setValue ]);

	function handleSave() {
		if(!floorFrom || !floorTo) return;

		const floor = {
			floorFrom,
			floorTo
		};

		const toRanges = floors.map(toFloorRange);

		if(toRanges.some(range => isOverlappingRange(floor, range))) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const overlap = toRanges.find(range => isOverlappingRange(floor, range))!;

			const newRange = {
				floorFrom: Math.min(overlap.floorFrom, floor.floorFrom),
				floorTo: Math.max(overlap.floorTo, floor.floorTo)
			};

			const filtered = toRanges.filter(range => range !== overlap);
			const sorted = [ ...filtered, newRange ].sort((a, b) => a.floorFrom - b.floorFrom);

			setValue("floors", sorted.map(range => fromFloorRange(range.floorFrom, range.floorTo)));
		}
		else if(toRanges.some(range => isExtendedRange(floor, range))) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const extend = toRanges.find(range => isExtendedRange(floor, range))!;

			const newRange = {
				floorFrom: Math.min(extend.floorFrom, floor.floorFrom),
				floorTo: Math.max(extend.floorTo, floor.floorTo)
			};

			const filtered = toRanges.filter(range => range !== extend);
			const sorted = [ ...filtered, newRange ].sort((a, b) => a.floorFrom - b.floorFrom);

			setValue("floors", sorted.map(range => fromFloorRange(range.floorFrom, range.floorTo)));
		}
		else {
			const sorted = [ ...floors, fromFloorRange(floorFrom, floorTo) ].sort((a, b) => {
				const aRange = toFloorRange(a);
				const bRange = toFloorRange(b);
				return aRange.floorFrom - bRange.floorFrom;
			});

			setValue("floors", sorted);
		}

		toggleForceClose();
		setValue("floorFrom", undefined);
		setValue("floorTo", undefined);
	}

	return (
		<DialogWithActivatorButton
			activatorButton={activatorButton}
			forceClose={forceClose}
			title="Add Floor"
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={() => toggleForceClose()}>
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						color="primary"
						onClick={() => {
							handleSave();
						}}
					>
						Save
					</LoadingButton>
				</Box>
			)}
		>
			<Box direction="row" justify="between" gap="medium">
				<TextFieldElement
					fullWidth
					name="floorFrom"
					label="From"
					validation={{
						min: 1,
						max: 100,
						required: true,
						pattern: /^[0-9]+$/
					}}
				/>
				<TextFieldElement
					fullWidth
					name="floorTo"
					label="To"
					validation={{
						min: 1,
						max: 100,
						required: true,
						pattern: /^[0-9]+$/
					}}
				/>
			</Box>
		</DialogWithActivatorButton>
	);
};

export const SelectFloorNumbersElement: React.FC = () => {
	const { watch, setValue } = useFormContext<WasteAuditFormState>();
	const { addLater } = watch();

	return (
		<Box gap="small">
			<Box gap="small">
				<AutocompleteElement
					multiple
					options={[]}
					name="floors"
					autocompleteProps={{
						disabled: addLater,
					}}
					textFieldProps={{
						helperText: "Add the floors you will be auditing"
					}}
				/>
			</Box>
			<AddFloorDialog
				activatorButton={(
					<Button variant="outlined" color="primary" disabled={addLater}>
						Add Floor
					</Button>
				)}
			/>
			<CheckboxElement
				name="addLater"
				label={(
					<Typography variant="h6">
						Add floors later?
					</Typography>
				)}
			/>
		</Box>
	);
};

export const AddContainerDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const { setValue, watch } = useFormContext<WasteAuditFormState>();
	const { containers, containerName, containerDispotition } = watch();

	useEffect(() => {
		console.log("Containers", containers);
	}, [ containers ]);

	function handleSave() {
		if(!containerName || !containerDispotition) return;

		console.log("Saving container", containerName, containerDispotition);

		setValue("containers", [ ...containers, {
			id: containerName,
			label: containerName,
			name: containerName,
			disposition: containerDispotition
		} ]);

		setValue("containerName", undefined);
		setValue("containerDispotition", undefined);
	}

	return (
		<DialogWithActivatorButton
			activatorButton={activatorButton}
			title="Add Container"
			forceClose={forceClose}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error">
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						color="primary"
						onClick={() => {
							handleSave();
							toggleForceClose();
						}}
					>
						Save
					</LoadingButton>
				</Box>
			)}
		>
			<Box gap="medium">
				<TextFieldElement
					fullWidth
					required
					name="containerName"
					label="Container Name"
					helperText="Give your container a label"
				/>
				<MaterialDispositionElement
					name="containerDispotition"
					label="Container Disposition"
					helperText="What is supposed to be in this container?"
				/>
			</Box>
		</DialogWithActivatorButton>
	);
};

export const SelectContainersElement: React.FC = () => {
	const { watch, setValue } = useFormContext<WasteAuditFormState>();
	const { addLater, containers } = watch();

	return (
		<Box gap="small">
			<Box gap="small">
				<AutocompleteElement
					multiple
					options={[]}
					name="containers"
					autocompleteProps={{
						disabled: addLater,
					}}
					textFieldProps={{
						helperText: "Add the containers you will be auditing"
					}}
				/>
			</Box>
			<AddContainerDialog
				activatorButton={(
					<Button variant="outlined" color="primary" disabled={addLater}>
						Add Container
					</Button>
				)}
			/>
			<CheckboxElement
				name="addLater"
				label={(
					<Typography variant="h6">
						Add containers later?
					</Typography>
				)}
			/>
		</Box>
	);
};


export const SelectRoomsElement: React.FC = () => {
	const { watch, setValue } = useFormContext<WasteAuditFormState>();
	const { addLater } = watch();

	return (
		<Box gap="small">
			<Box gap="small">
				<AutocompleteElement
					multiple
					options={[]}
					name="rooms"
					autocompleteProps={{
						freeSolo: true,
						disabled: addLater,
					}}
					textFieldProps={{
						helperText: "Add the rooms you will be auditing"
					}}
				/>
			</Box>
			<CheckboxElement
				name="addLater"
				label={(
					<Typography variant="h6">
						Add rooms later?
					</Typography>
				)}
			/>
		</Box>
	);
};

export const WasteAuditSetupStep: React.FC<{
	configType: WasteAuditFormState[ "configType" ];
}> = ({ configType }) => {
	return (
		<Box gap="small">
			{configType === "floor" && (
				<Box gap="medium">
					<Box gap="small">
						<Heading
							level={3}
							margin="none"
						>
							Configure Floors
						</Heading>
						<SelectFloorNumbersElement />
					</Box>
				</Box>
			)}
			{configType === "room" && (
				<Box gap="small">
					<Heading
						level={3}
						margin="none"
					>
						Configure Rooms
					</Heading>
					<SelectRoomsElement />
				</Box>
			)}
			{(configType === "container" || configType === "other") && (
				<Box gap="small">
					<Heading
						level={3}
						margin="none"
					>
						Configure Containers
					</Heading>
					<SelectContainersElement />
				</Box>
			)}
		</Box>
	);
};

export const WasteAuditConfigStep: React.FC<{
	configType: WasteAuditFormState[ "configType" ];
	updateConfigType: (configType: WasteAuditFormState[ "configType" ]) => void;
}> = ({ configType, updateConfigType }) => {
	const options = useMemo(() => {
		return [
			{
				id: "floor",
				label: "Organize by Floor",
				icon: <Business />,
				desription: "Select this option if you want to track and group waste by floor (ex. Floors 1-5, 8, 9)"
			},
			{
				id: "room",
				label: "Organize by Room",
				icon: <MeetingRoom />,
				desription: "Select this option if you want to track and group waste by room (ex. Office #1, Office #2)"
			},
			{
				id: "container",
				label: "Organize by Container",
				icon: <Delete />,
				desription: "Select this option if you want to track and group waste by bin or container (ex. Trash Bin #1, Recycling Bin #2)"
			},
			{
				id: "other",
				label: "Other",
				icon: <Help />,
				desription: "Select this option if you want to track waste another way or if you have a more specific use case"
			}
		] as { id: WasteAuditFormState[ "configType" ]; label: string; desription: string; icon: ReactNode; }[];
	}, []);

	return (
		<Box gap="small">
			<Heading level={3} margin="none">
				How would you like to organize this audit?
			</Heading>
			<List>
				{options.map(option => (
					<ListItemButton
						divider
						key={option.id}
						selected={configType === option.id}
						onClick={() => {
							updateConfigType(option.id);
						}}
					>
						<ListItemIcon>
							{option.icon}
						</ListItemIcon>
						<ListItemText
							primary={(
								<Typography fontWeight="bold">
									{option.label}
								</Typography>
							)}
							secondary={option.desription}
						/>
					</ListItemButton>
				))}
			</List>
		</Box>
	);
};

export const WasteAuditDetailsStep: React.FC<{
	formContext: UseFormReturn<WasteAuditFormState>;
}> = ({ formContext }) => {
	const { buildings } = useBuildings();
	const [ isCreaingLocation, setIsCreatingLocation ] = React.useState(false);

	return (
		<Fragment>
			{isCreaingLocation && (
				<CreatePropertyDialog onClose={() => setIsCreatingLocation(false)} />
			)}
			<Box gap="medium">
				<TextFieldElement
					fullWidth
					required
					name="name"
					label="Audit Title"
					helperText="Give your audit a name"
				/>
				<SelectElement
					required
					fullWidth
					name="partnerId"
					label="Location"
					options={buildings.map(building => ({
						id: building.id,
						label: building.name
					}))}
				/>
				<Box align="start">
					<Button
						color="primary"
						variant="outlined"
						onClick={() => setIsCreatingLocation(true)}
					>
						New Location
					</Button>
				</Box>
			</Box>
		</Fragment>
	);
};