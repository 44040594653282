import React from "react";
import { StandardAddressListItem } from "../../../components";
import { Address_HeaderFragmentFragment } from "../../../graphql/__generated__/graphql";
import { useCheckoutSessionAddress } from "../hooks";

export const CheckoutAddressSummary: React.FC = () => {
	const { address } = useCheckoutSessionAddress();
	return (
		<StandardAddressListItem
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			///@ts-ignore
			address={address || {} as Address_HeaderFragmentFragment}
			isLoading={false}
			selected={true}
			onSelect={() => {
				//
			}}
			onDelete={() => {
				//
			}}
		/>
	);
};
