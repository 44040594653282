import React, { useEffect, useMemo, useState } from "react";
import { Pagination, TabController, ViewContainer } from "../../../../components";
import { Box, Grid, Heading, Spinner } from "grommet";
import { Badge, Button, Card, CardContent, CardHeader, Chip, Divider, IconButton, InputAdornment, LinearProgress, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { CallSplit, Close, Edit, Fullscreen, ReportProblem, Settings, SettingsSuggest, TrendingUp, Visibility } from "@mui/icons-material";
import { useBuildings, useWasteAudit } from "../hooks";
import { useRequiredPathParam } from "../../../../hooks";

export const AuditRecommendationsButton: React.FC<{
	onClick?: () => void;
	auditId: string;
}> = ({ onClick, auditId }) => {
	const { audit } = useWasteAudit(auditId);

	return (
		<Box align="center" justify="center">
			<Badge badgeContent={(audit?.recommendations || []).length} color="primary">
				<Button
					size="small"
					color="primary"
					variant="outlined"
					onClick={onClick}
					startIcon={<SettingsSuggest />}
				>
					Advisor
				</Button>
			</Badge>
		</Box>
	);
};

export const AuditSettingsButton: React.FC<{
	onClick?: () => void;
}> = ({ onClick }) => {
	return (
		<Box align="center" justify="center">
			<Button
				size="small"
				color="primary"
				variant="outlined"
				startIcon={<Settings />}
				onClick={onClick}
			>
				Settings
			</Button>
		</Box>
	);
};

export const WasteAuditResultsContainer: React.FC = () => {
	return (
		<Box direction="row" gap="medium">
			<Box align="center" justify="center" gap="large">
				<Heading level="3" margin="none">
					Diversion Rate (%)
				</Heading>
				<Heading level="1" margin="none">
					20%
				</Heading>
			</Box>
		</Box>
	);
};

export const AuditDetailsV2View: React.FC = () => {
	const auditId = useRequiredPathParam("auditId", "/admin/audits");
	const theme = useTheme();
	const [ activeTab, setActiveTab ] = useState("details");

	return (
		<ViewContainer gap="small">
			<Box style={{ display: "block" }} height="100%">
				<Box gap="small">
					<Box direction="row" justify="between">
						<Box justify="center">
							<Heading
								level="3"
								margin="none"
							>
								Audit Details
							</Heading>
						</Box>
						<Box gap="small" direction="row" align="center" justify="end">
							<AuditRecommendationsButton
								onClick={() => {
									setActiveTab("recommendations");
								}}
								auditId={auditId}
							/>
							<AuditSettingsButton

							/>
						</Box>
					</Box>
					<Divider
						sx={{
							borderColor: theme.palette.primary.main
						}}
					/>
				</Box>
				<Grid columns={[ "2/3", "1/3" ]} height="100%">
					<Box gap="small" margin={{ right: "medium" }} height="100%" style={{ display: "block" }}>
						<TabController
							value={activeTab}
							onValueChange={setActiveTab}
							defaultValue="details"
							tabs={[
								{
									label: "Details",
									value: "details",
									component: <AuditDetailsTabContent auditId={auditId} />
								},
								{
									label: "Uploads",
									value: "uploads",
									component: <AuditUploadsTabContent auditId={auditId} />
								},
								{
									label: "Recommendations",
									value: "recommendations",
									component: <AuditRecommendationsTabContent auditId={auditId} />
								},
								{
									label: "Results",
									value: "results",
									component: (
										<Box height="100%" style={{ display: "block" }} overflow={{ vertical: "scroll" }}>
											<AuditResultsTabContent auditId={auditId} />
										</Box>
									)
								}
							]}
						/>
					</Box>
					<Box gap="medium" height="100%" margin={{ vertical: "small" }}>
						<Card
							variant="outlined"
						>
							<CardHeader
								avatar={(
									<IconButton sx={{ padding: 0 }} style={{ zIndex: 2 }}>
										<Fullscreen />
									</IconButton>
								)}
								title={(
									<Heading level="4" margin="none">
										Waste Generated
									</Heading>
								)}
							/>
							<Divider />
							<CardContent sx={{ pt: 0, pb: 0 }}>
								<Box height="200px">
									<WasteGeneratedPieChart
										auditId={auditId}
									/>
								</Box>
							</CardContent>
						</Card>
						<Card
							variant="outlined"
						>
							<CardHeader
								avatar={(
									<IconButton sx={{ padding: 0 }} style={{ zIndex: 2 }}>
										<Fullscreen />
									</IconButton>
								)}
								title={(
									<Heading level="4" margin="none">
										Waste Diverted
									</Heading>
								)}
							/>
							<Divider />
							<CardContent sx={{ pt: 0, pb: 0 }}>
								<Box height="200px">
									<WasteDivertedPieChart
										auditId={auditId}
									/>
								</Box>
							</CardContent>
						</Card>
						{/* <Box
							flex
							hoverIndicator
							onClick={noop}
							background="white" round elevation="medium" pad="small"
						>
							<Stack style={{ height: "100%", width: "100%" }}>
								<Box direction="row" justify="between" align="start">
									<Heading level="4" margin="none">
										Waste Generated
									</Heading>
									<IconButton sx={{ padding: 0 }} style={{ zIndex: 2 }}>
										<Fullscreen />
									</IconButton>
								</Box>
								<WasteGeneratedPieChart />

							</Stack>
							
						</Box>
						<Box
							flex
							hoverIndicator
							onClick={noop}
							background="white" round elevation="medium" pad="small"
						>
							<Stack style={{ height: "100%", width: "100%" }}>
								<Box direction="row" justify="between" align="start">
									<Heading level="4" margin="none">
										Waste Diverted
									</Heading>
									<IconButton style={{ zIndex: 2 }}>
										<Fullscreen />
									</IconButton>
								</Box>
								<WasteGeneratedPieChart />

							</Stack>
						</Box> */}
					</Box>
				</Grid>
			</Box>
		</ViewContainer>
	);
};

export const AuditDetailsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const { buildings } = useBuildings();

	const formContext = useForm({
		defaultValues: {
			name: audit?.name,
			partnerId: audit?.partner?.id,
			createdAt: audit?.createdAt ? moment(audit.createdAt).format("MMMM Do YYYY") : "",
		}
	});

	useEffect(() => {
		formContext.reset({
			name: audit?.name,
			partnerId: audit?.partner?.id,
			createdAt: audit?.createdAt ? moment(audit.createdAt).format("MMMM Do YYYY") : "",
		});
	}, [ audit, formContext ]);

	const [ titleSaving, setTitleSaving ] = useState(false);
	useEffect(() => {
		if(titleSaving) {
			const timer = setTimeout(() => {
				setTitleSaving(false);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [ titleSaving ]);

	const [ parnterSaving, setPartnerSaving ] = useState(false);

	useEffect(() => {
		if(parnterSaving) {
			const timer = setTimeout(() => {
				setPartnerSaving(false);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [ parnterSaving ]);

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				<TextFieldElement
					name="name"
					label="Name"
					InputProps={{
						onBlur: () => {
							setTitleSaving(true);
						},
						endAdornment: titleSaving && (
							<InputAdornment
								position="end"
								sx={{ mr: 2 }}
							>
								<Spinner size="small" />
							</InputAdornment>
						)
					}}
				/>
				<TextFieldElement
					name="createdAt"
					label="Created At"
					InputProps={{
						readOnly: true
					}}
				/>
				<SelectElement
					required
					fullWidth
					name="partnerId"
					label="Location"
					options={buildings.map(building => ({
						id: building.id,
						label: building.name
					}))}
					InputProps={{
						onChange: () => {
							setPartnerSaving(true);
						},
						endAdornment: parnterSaving && (
							<InputAdornment
								position="end"
								sx={{ mr: 2 }}
							>
								<Spinner size="small" />
							</InputAdornment>
						)
					}}
				/>
			</Box>
		</FormContainer>
	);
};

export const AuditRecommendationsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit } = useWasteAudit(auditId);
	const recommendations = useMemo(() => audit?.recommendations || [], [ audit ]);

	function dismissRecommendation(recommendationId: string) {
		//
	}

	if(recommendations.length === 0) {
		return (
			<Box height="small" align="center" justify="center" gap="small">
				<Typography variant="body2">
					no recommendations found
				</Typography>
			</Box>
		);
	}

	return (
		<Pagination pageSize={3}>
			{recommendations.map((recommendation) => (
				<ListItemButton key={recommendation.id} divider style={{ height: "80px" }}>
					<ListItemIcon>
						<Chip
							color="info"
							label="BETA"
						/>
					</ListItemIcon>
					<ListItemText sx={{ ml: 1 }}>
						<Typography fontWeight="bold" variant="body2" className="text-max-1-line">
							{recommendation.title}
						</Typography>
						<Typography
							variant="caption"
							sx={{ maxWidth: "80%" }}
							className="text-max-2-lines"
						>
							{recommendation.content}
						</Typography>
					</ListItemText>
					<ListItemSecondaryAction>
						<Box justify="end" direction="row" gap="xsmall">
							<IconButton color="primary">
								<Visibility />
							</IconButton>
							<IconButton
								color="error"
								onClick={() => {
									dismissRecommendation(recommendation.id);
								}}
							>
								<Close />
							</IconButton>
						</Box>
					</ListItemSecondaryAction>
				</ListItemButton>
			))}
		</Pagination>
	);
};

export const AuditUploadsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const dispatch = useAppDispatch();

	function handleSelect(
		collectionId: string
	) {
		dispatch(push(`/admin/inspector/${collectionId}`));
		// dispatch(push("/admin/inspector/f01d8aca-7f21-4a8f-ba31-913bd16fa021"));
		// dispatch(push("/admin/inspector/c1477647-cfcc-4273-b937-bbac8bfc10e8"));
	}


	if(loading) {
		return (
			<LinearProgress />
		);
	}

	if(audit?.collections?.length === 0 && !loading) {
		return (
			<Box height="small" align="center" justify="center" gap="small">
				<Typography variant="body2">
					no uploads found
				</Typography>
				<CreateWasteAuditCollectionDialog
					auditId={auditId}
					activatorButton={(
						<Button
							startIcon={(
								<Edit />
							)}
							size="small"
							variant="contained"
						>
							Upload Now
						</Button>
					)}
				/>
			</Box>
		);
	}

	return (
		<Box gap="small" flex>
			<Box align="start">
				<CreateWasteAuditCollectionDialog
					auditId={auditId}
					activatorButton={(
						<Button
							startIcon={(
								<Edit />
							)}
							size="small"
							variant="contained"
						>
							Upload
						</Button>
					)}
				/>
			</Box>
			<Pagination pageSize={4}>
				{(audit?.collections || []).map((collection) => (
					<ListItemButton key={collection.id} divider onClick={() => handleSelect(collection.id)}>
						<ListItemText>
							<Typography fontWeight="bold">
								{collection.metadata.containerName}
							</Typography>
							<Typography variant="caption">
								{moment(collection.createdAt).format("dddd MMMM Do, YYYY")}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							<Box direction="row" gap="small" justify="end" align="center">
								{Number(collection.totalWeight) > 0 && (
									<Typography fontWeight="bold">
										{formatNumber(collection.totalWeight)} lb(s)
									</Typography>
								)}
								{/* {collection.metadata.containerDisposition && (
									<Chip
										color="info"
										label={collection.metadata.containerDisposition}
									/>
								)} */}
								{collection.identificationCompleted && (
									<Chip
										color="success"
										label="Processed"
									/>
								)}
							</Box>
						</ListItemSecondaryAction>
					</ListItemButton>
				))}
			</Pagination>
		</Box>
	);
};

import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import moment from "moment";
import { formatNumber } from "../../../../helpers";
import { CreateWasteAuditCollectionDialog } from "./AuditDetails";
import { FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

interface WasteAuditMaterialMetrics {
	totalWeightRatio: number;
	totalWeightPredicted: number;
	material: { id: string; name: string; };
}

interface WasteAuditMetrics {
	diversionRate: number;
	contaminationRate: number;
	potentialDiversionRate: number;
	totalWeightDiverted: number;
	totalWeightGenerated: number;
	totalWeightContamination: number;
	materials: WasteAuditMaterialMetrics[];
	lineItems: WasteAuditLineItemMetrics[];
}

interface WasteAuditLineItemMetrics {
	floor: string;
	diversionRate: number;
	contaminationRate: number;
	potentialDiversionRate: number;
	totalWeightDiverted: number;
	totalWeightGenerated: number;
	totalWeightContamination: number;
	materials: WasteAuditMaterialMetrics[];
}

export const WasteAuditMetricsCard: React.FC<{
	title: string;
	metric: number;
	icon: React.ReactNode;
}> = ({ title, metric, icon }) => {
	const theme = useTheme();
	return (
		<Card elevation={2} variant="outlined">
			<CardHeader
				// avatar={icon}
				title={(
					<Heading textAlign="center" margin="none" level="4">
						{title}
					</Heading>
				)}
			/>
			<Divider sx={{ color: theme.palette.primary.main }} />
			<CardContent>
				<Box align="center" justify="center">
					<Heading level="2">
						{formatNumber(metric * 100, 2)}%
					</Heading>
				</Box>
			</CardContent>
		</Card>
	);
};


export const AuditResultsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, metrics, loading } = useWasteAudit(auditId);
	const theme = useTheme();

	if(loading || !metrics) {
		return (
			<LinearProgress />
		);
	}

	return (
		<Box gap="small" height="100" overflow={{ vertical: "scroll" }} style={{ display: "block" }}>
			<Grid columns={{ count: 3, size: "auto" }} gap="medium">
				<WasteAuditMetricsCard
					title="Diversion Rate"
					metric={metrics.diversionRate}
					icon={<CallSplit />}
				/>
				<WasteAuditMetricsCard
					title="Potential Diversion Rate"
					metric={metrics.potentialDiversionRate}
					icon={<TrendingUp />}
				/>
				<WasteAuditMetricsCard
					title="Contamination Rate"
					metric={metrics.contaminationRate}
					icon={<ReportProblem />}
				/>
			</Grid>
			<Box>
				<Heading level="3" margin="none">
					Area Breakdown
				</Heading>
				<Typography variant="caption">
					{audit?.name} has been broken down into the following zone(s).
				</Typography>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Zone Comparison</TableCell>
								<TableCell align="right">Total Weight (lbs)</TableCell>
								<TableCell align="right">Diversion Rate (%)</TableCell>
								<TableCell align="right">Potential Diversion (%)</TableCell>
								<TableCell align="right">Contamination Rate (%)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.from(metrics.lineItems).sort((a, b) => b.totalWeightGenerated - a.totalWeightGenerated).map((row) => (
								<TableRow
									key={row.aggregateBy}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.aggregateBy}
									</TableCell>
									<TableCell align="right">{formatNumber(row.totalWeightGenerated, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.diversionRate * 100, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.potentialDiversionRate * 100, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.contaminationRate * 100, 2)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
};

function generateRandomColor() {
	return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

const RADIAN = Math.PI / 180;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
///@ts-ignore
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export const WasteDivertedPieChart: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { metrics } = useWasteAudit(auditId);
	const materials = useMemo(() => {
		return metrics?.materials || [];
	}, [ metrics ]);

	const totalWeight = useMemo(() => {
		return materials.reduce((acc, { totalWeightDiverted }) => acc + totalWeightDiverted, 0);
	}, [ materials ]);

	const data = useMemo(() => {
		return materials.map(({ material, totalWeightDiverted }) => ({
			name: material.name,
			value: totalWeightDiverted / totalWeight
		}));
	}, [ materials, totalWeight ]);

	if(data.length === 0 || totalWeight <= 0) {
		return (
			<Box align="center" justify="center" flex>
				<Typography fontWeight="bold">
					no diversion data found
				</Typography>
			</Box>
		);
	}

	return (
		<PieChartContainer data={data} />
	);
};

export const WasteGeneratedPieChart: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { metrics } = useWasteAudit(auditId);
	const materials = useMemo(() => {
		return metrics?.materials || [];
	}, [ metrics ]);

	const data = useMemo(() => {
		const totalWeight = materials.reduce((acc, { totalWeightGenerated }) => acc + totalWeightGenerated, 0);
		return materials.map(({ material, totalWeightGenerated }) => ({
			name: material.name,
			value: totalWeightGenerated / totalWeight
		}));
	}, [ materials ]);

	return (
		<PieChartContainer data={data} />
	);
};

export const PieChartContainer: React.FC<{
	data: { name: string; value: number; }[];
}> = ({ data }) => {
	return (
		<ResponsiveContainer height="100%" width="100%">
			<PieChart>
				<Legend
					align="left"
					verticalAlign="middle"
					layout="vertical"
				/>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={80}
					fill="#8884d8"
					dataKey="value"
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={ColorConfig.find(m => entry.name.toLocaleLowerCase().includes(m.material.toLocaleLowerCase()))?.hexColor || generateRandomColor()} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};

export const ColorConfig = [
	{
		material: "Paper",
		hexColor: "#FFC0CB"
	},
	{
		material: "Cardboard",
		hexColor: "#FFA07A"
	},
	{
		material: "Plastic",
		hexColor: "#FFD700"
	},
	{
		material: "Metal",
		hexColor: "#FF6347"
	},
	{
		material: "Glass",
		hexColor: "#00FF00"
	},
	{
		material: "Organic",
		hexColor: "#00FFFF"
	},
	{
		material: "Mixed Waste",
		hexColor: "#0000FF"
	}
];