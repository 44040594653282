import React, { useCallback, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { Box, Spinner } from "grommet";
import moment, { Moment } from "moment-timezone";
import { UseFormReturn, useForm } from "react-hook-form";
import { FormContainer, TextFieldElement, DatePickerElement, ToggleButtonGroupElement, SelectElement } from "react-hook-form-mui";
import { DialogWithClose } from "../../../../../components";
import { WasteAuditType } from "../../../../../graphql/__generated__/graphql";
import { toProperCase } from "../../../../../helpers";
import { useBuildings, useWasteAudit } from "../../hooks";
import { CreatePropertyDialog } from "../property";
import { LocalizationProvider } from "../../../../../provider";
import { useMutation } from "@apollo/client";
import { CreateWasteAudit, FindWasteAudit } from "../../../../../graphql";
import { useInstance } from "../../../../../hooks";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";

export interface WasteAuditFormContext {
	name: string;
	partnerId: string;
	type: WasteAuditType;
	scheduledDate: Moment | null;
}

export const WasteAuditForm: React.FC<{
	isUpdate?: boolean;
	formContext: UseFormReturn<WasteAuditFormContext>;
}> = ({ formContext, isUpdate }) => {
	const { buildings } = useBuildings();
	const [ isCreatingLocation, setIsCreatingLocation ] = React.useState(false);

	return (
		<FormContainer formContext={formContext}>
			{isCreatingLocation && (
				<CreatePropertyDialog onClose={() => setIsCreatingLocation(false)} />
			)}
			<Box gap="medium">
				<TextFieldElement
					required
					name="name"
					label="Audit Title"
				/>
				<LocalizationProvider>
					<DatePickerElement
						name="scheduledDate"
						label="Scheduled Date"
						format="MMMM Do, YYYY"
					/>
				</LocalizationProvider>
				<SelectElement
					required
					fullWidth
					disabled={isUpdate}
					name="partnerId"
					label="Location"
					options={buildings.map(building => ({
						id: building.id,
						label: building.name
					}))}
				/>
				{!isUpdate && (
					<Box align="start">
						<Button
							color="primary"
							variant="outlined"
							onClick={() => setIsCreatingLocation(true)}
						>
							New Location
						</Button>
					</Box>
				)}
				<Box gap="small">
					<ToggleButtonGroupElement
						name="type"
						fullWidth
						exclusive
						required
						label="Audit Type"
						color="primary"
						options={[
							{
								id: WasteAuditType.Assessment,
								label: toProperCase(WasteAuditType.Assessment)
							},
							{
								id: WasteAuditType.Comprehensive,
								label: toProperCase(WasteAuditType.Comprehensive)
							}
						]}
					/>
					<Box gap="xsmall">
						<Typography variant="caption">
							<em style={{ fontWeight: "bold" }}>Assessment:</em> A quick audit to assess the current waste management practices.
						</Typography>
						<Typography variant="caption">
							<em style={{ fontWeight: "bold" }}>Comprehensive:</em> A detailed audit to assess the current waste management practices. Requires opening bags and taking pictures of the waste spread out.
						</Typography>
					</Box>
				</Box>
			</Box>
		</FormContainer>
	);
};

export const WasteAuditDialogCommon: React.FC<{
	title: string;
	loading: boolean;
	isUpdate?: boolean;
	onClose: () => void;
	formContext: UseFormReturn<WasteAuditFormContext>;
	onSubmit: () => void;
	onSubmitLabel: string;
	isSubmitting?: boolean;
}> = ({ title, loading, onClose, isUpdate, formContext, onSubmit, onSubmitLabel, isSubmitting }) => {
	return (
		<DialogWithClose
			title={title}
			onClose={onClose}
			content={
				(loading) ? (
					<Box height="small" align="center" justify="center">
						<Spinner />
					</Box>
				) : (
					<WasteAuditForm
						isUpdate={isUpdate}
						formContext={formContext}
					/>
				)
			}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={isSubmitting}
						onClick={formContext.handleSubmit(onSubmit)}
					>
						{onSubmitLabel}
					</LoadingButton>
				</Box>
			)}
		/>
	);
};

export const UpdateWasteAuditDialog: React.FC<{
	auditId: string;
	onClose: () => void;
}> = ({ auditId, onClose }) => {
	const snack = useSnackbar();
	const { audit, loading, isUpdating, update } = useWasteAudit(auditId);

	const formContext = useForm<WasteAuditFormContext>({
		defaultValues: {
			name: audit?.name || "",
			partnerId: audit?.partner?.id || "",
			type: audit?.type || WasteAuditType.Comprehensive,
			scheduledDate: audit?.scheduledDate
				? moment.tz(audit.scheduledDate, "UTC").tz(moment.tz.guess())
				: null
		}
	});

	useEffect(() => {
		formContext.reset({
			name: audit?.name || "",
			partnerId: audit?.partner?.id || "",
			type: audit?.type || WasteAuditType.Comprehensive,
			scheduledDate: audit?.scheduledDate
				? moment.tz(audit.scheduledDate, "UTC").tz(moment.tz.guess())
				: null
		});
	}, [ audit, formContext ]);

	const handleUpdate = useCallback(() => {
		const { name, type, scheduledDate } = formContext.getValues();
		if(!audit || !name) return;

		update({
			name,
			type,
			auditId,
			scheduledDate: (scheduledDate)
				? scheduledDate.toISOString()
				: null
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to update audit", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}, [ audit, auditId, formContext, onClose, snack, update ]);

	return (
		<WasteAuditDialogCommon
			isUpdate
			loading={loading}
			onClose={onClose}
			formContext={formContext}
			onSubmit={handleUpdate}
			onSubmitLabel="Update"
			title="Update Audit Settings"
			isSubmitting={isUpdating}
		/>
	);
};

export const CreateWasteAuditDialog: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { instance } = useInstance();
	const formContext = useForm<WasteAuditFormContext>({
		defaultValues: {
			name: "",
			type: WasteAuditType.Comprehensive,
			scheduledDate: null
		}
	});

	const [
		create,
		{ loading }
	] = useMutation(CreateWasteAudit, {
		refetchQueries: [ FindWasteAudit ]
	});

	function handleCreate() {
		const { name, type, scheduledDate, partnerId } = formContext.getValues();
		if(!name || !instance || !partnerId) return;

		create({
			variables: {
				name,
				type: type || WasteAuditType.Comprehensive,
				scheduledDate: scheduledDate?.toISOString() || null,
				partnerId: partnerId,
				instanceId: instance.id
			}
		}).then((res) => {
			onClose();
			if(res.data?.CreateWasteAudit.id) {
				dispatch(push(`/admin/audits/${res.data.CreateWasteAudit.id}`));
			}
		}).catch(err => {
			console.error("Failed to create audit", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}

	return (
		<WasteAuditDialogCommon
			loading={loading}
			onClose={onClose}
			formContext={formContext}
			onSubmit={handleCreate}
			onSubmitLabel="Create"
			title="Create New Audit"
		/>
	);
};