import React from "react";
import { LinearProgress } from "@mui/material";
import { useStop } from "../../../graphql";
import { useRequiredPathParam } from "../../../hooks";
import { CurrentStopDialog } from "../components/stop";

export const ManualStopUpload: React.FC = () => {
	const stopId = useRequiredPathParam("stopId", "/hauler/dashboard");


	const { stop, loading } = useStop(stopId);

	if(!stop || loading) {
		return (
			<LinearProgress />
		);
	}

	return (
		<CurrentStopDialog
			stopId={stopId}
			haulerId={stop.movement.assignedTo?.id || ""}
			movementId={stop.movement.id}
		/>
	);
};