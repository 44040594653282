import { useMutation, useQuery } from "@apollo/client";
import { FetchWasteAudit, FetchWasteAuditZone } from "../queries";
import { UpdateWasteAuditZoneMutationVariables } from "../../../__generated__/graphql";
import { UpdateWasteAuditZone } from "../mutations";

export function useWasteAuditZone(zoneId: string) {
	const { data, loading, error } = useQuery(FetchWasteAuditZone, {
		skip: !zoneId,
		variables: { zoneId },
	});

	const [
		update,
		{ loading: updateLoading, error: updateError }
	] = useMutation(UpdateWasteAuditZone, {
		refetchQueries: [
			FetchWasteAudit,
			FetchWasteAuditZone
		]
	});

	function handleUpdate(
		dto: UpdateWasteAuditZoneMutationVariables
	) {
		return update({
			variables: dto
		});
	}

	return {
		update: handleUpdate,
		isUpdating: updateLoading,
		updateError,
		zone: data?.FetchWasteAuditZone || null,
		loading,
		error
	};
}