import React from "react";
import { useRequiredPathParam, useWindowDimensions } from "../../hooks";
import { usePublicShareContainer, usePublicShareContainerNavigation } from "./hooks";
import { SplashScreen } from "../../components";
import { Box, Image } from "grommet";
import { Outlet } from "react-router";
import { Button, createTheme, ThemeProvider, useTheme } from "@mui/material";
import { Home, NavigateBefore, NavigateNext } from "@mui/icons-material";

export const PublicShareContainerController: React.FC = () => {
	const { size } = useWindowDimensions();
	const containerId = useRequiredPathParam("containerId", "/");
	const { instance, container, loading } = usePublicShareContainer(containerId);

	const theme = useTheme();
	const { back, forward, redirect } = usePublicShareContainerNavigation(containerId);

	return (
		<SplashScreen visible={loading || !container || !instance} delayRender>
			<ThemeProvider
				theme={createTheme({
					palette: {
						primary: {
							main: instance?.customizations?.primaryColor || theme.palette.primary.main
						},
						secondary: {
							main: instance?.customizations?.secondaryColor || theme.palette.secondary.main
						}
					}
				})}
			>
				<Box width="100%" height="100%">
					<Box gap="medium" align="center" justify="center" margin="medium" id="header-content" height="10%">
						<Box>
							<Image
								fit="contain"
								src={instance?.customizations.logoUrl || ""}
							/>
						</Box>
					</Box>
					<Box id="body-content" align="center" width="100%" height="80%" style={{ display: "block" }}>
						<Box gap="medium" margin={size === "small" ? "medium" : "small"} height="100%" style={{ display: "block" }}>
							<Box direction="row" justify="between">
								<Box
									align="start"
									justify="center"
								>
									<Button
										color="primary"
										variant="outlined"
										sx={{
											pl: 1,
											pr: 1,
											borderRadius: "12px",
											minWidth: 0
										}}
										onClick={() => {
											redirect("");
										}}
									>
										<Home />
									</Button>
								</Box>
								<Box
									gap="small"
									align="center"
									justify="end"
									direction="row"
								>
									<Button
										color="primary"
										variant="outlined"
										sx={{
											pl: 1,
											pr: 1,
											borderRadius: "12px",
											minWidth: 0
										}}
										onClick={back}
									>
										<NavigateBefore />
									</Button>
									<Button
										color="primary"
										variant="outlined"
										sx={{
											pl: 1,
											pr: 1,
											borderRadius: "12px",
											minWidth: 0
										}}
										onClick={forward}
									>
										<NavigateNext />
									</Button>
								</Box>
							</Box>
							<Box height="100%" style={{ display: "block" }} overflow={{ vertical: "auto" }}>
								<Outlet />
							</Box>
						</Box>
					</Box>
					<Box
						height="10%"
						id="footer-content"
						gap="small"
					>
						<Box
							style={{
								position: "relative",
								backgroundColor: "#1e4c40"
							}}
						>
							<Box width="100%" id="header-content">
								<Box margin={size === "small" ? "medium" : "small"} align="center">
									<Image
										onClick={() => window.open("https://www.liverego.com")}
										src="https://assets.regoapp.io/img/rego_cream_logo.png"
										fit="contain"
									/>
								</Box>
							</Box>
							<Box
								margin="small"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
								}}
							>
								{/* <Typography
								color="#fff5f0"
								variant="body2"
								fontWeight="bold"
							>
								powered by
							</Typography> */}
							</Box>
						</Box>
					</Box>
				</Box>
			</ThemeProvider>
		</SplashScreen>
	);
};