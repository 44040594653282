import { gql } from "@apollo/client";

export const Address_HeaderFragment = gql`
	fragment Address_HeaderFragment on Address {
		id
		addressLineOne
		addressLineTwo
		locality
		administrativeArea
		postalCode
	}
`;

export const Address_CountryFragment = gql`
	fragment Address_CountryFragment on Address {
		country {
			code
			name
			dialCode
		}
	}
`;

export const Address_MarketFragment = gql`
	fragment Address_MarketFragment on Address {
		market {
			id
			code
			name
			displayName
		}
	}
`;

export const Address_InstructionsFragment = gql`
	fragment Address_InstructionsFragment on Address {
		floor
		hasParking
		hasElevator
		instructions
		instructionsParking
	}
`;

export const Address_GeolocationFragment = gql`
	fragment Address_GeolocationFragment on Address {
		lat
		lng
		timezone
	}
`;

export const AddressFragment = gql`
	fragment AddressFragment on Address {
		...Address_HeaderFragment
		...Address_MarketFragment
		...Address_CountryFragment
		...Address_GeolocationFragment
		...Address_InstructionsFragment
	}
`;