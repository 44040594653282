import React from "react";
import { useForm } from "react-hook-form";
import { CityElement, DialogWithClose, InternationalLocalityElement, InternationalPostalCodeElement, StateZipElement, StreetAddressElement, SuiteUnitElement } from "../../../../../components";
import { AutocompleteElement, FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Button } from "@mui/material";
import { Box } from "grommet";
import { useBuildings } from "../../hooks";
import { SimpleAddress, useAutocomplete } from "../../../../../hooks";
import { LoadingButton } from "@mui/lab";
import { useCountries } from "../../../../../graphql/documents/address/useCountries";
import { useSnackbar } from "notistack";

export const CreatePropertyDialog: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const { create, isCreating } = useBuildings();
	const { countries } = useCountries();

	const formContext = useForm({
		defaultValues: {
			name: "",
			addressLineOne: "",
			addressLineTwo: "",
			locality: "",
			administrativeArea: "",
			postalCode: "",
			lat: "",
			lng: "",
			timezone: "",
			country: { id: "US", label: "United States" }
		}
	});

	const snack = useSnackbar();
	const country = formContext.watch("country");

	useAutocomplete(
		document.getElementById("address-element") as HTMLInputElement,
		(address: SimpleAddress) => {
			console.log("Address selected", address);
			const values = formContext.getValues();
			formContext.reset({
				...values,
				country: address.countryCode
					? {
						id: address.countryCode,
						label: countries.find(c => c.code === address.countryCode)?.name
					} : values.country,
				name: values.name || address.addressLineOne,
				addressLineOne: address.addressLineOne,
				locality: address.locality,
				administrativeArea: address.administrativeArea,
				postalCode: address.postalCode,
				lat: address.lat,
				lng: address.lng,
				timezone: address.timezone
			});
		}
	);

	function handleSubmit() {
		const { name, ...address } = formContext.getValues();

		const addressDTO = {
			...address,
			countryCode: country.id,
			floor: 1,
			hasElevator: false,
			hasParking: false,
			userId: ""
		};

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		///@ts-ignore
		delete addressDTO.country;

		create(name, addressDTO).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to create building", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again later.", {
				variant: "error"
			});
		});
	}

	return (
		<DialogWithClose
			onClose={onClose}
			title="Location Details"
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
					<LoadingButton
						color="primary"
						loading={isCreating}
						variant="contained"
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
			content={(
				<FormContainer
					formContext={formContext}
					FormProps={{
						style: { height: "100%" }
					}}
				>
					<Box gap="medium">
						<TextFieldElement
							name="name"
							label="Location Name"
						/>
						<AutocompleteElement
							name="country"
							label="Country"
							options={countries.map(c => ({
								label: c.name,
								id: c.code
							}))}
						/>
						<StreetAddressElement />
						<SuiteUnitElement />
						{country?.id === "US"
							? (
								<CityElement />
							)
							: (
								<InternationalLocalityElement />
							)}
						{country?.id === "US"
							? (
								<StateZipElement />
							)
							: (
								<InternationalPostalCodeElement />
							)}
					</Box>
				</FormContainer>
			)}
		/>
	);
};