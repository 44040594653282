import { gql } from "../../../__generated__";

export const FetchWasteAuditCollection = gql(/* GraphQL */ `
	query FetchWasteAuditCollection($collectionId: String!) {
		FetchWasteAuditCollection(collectionId: $collectionId) {
			id
			label
			totalWeight
			disposition
			tasks {
				id
			}
			media {
				id
				name
				contentUrl
			}
			audit {
				id
				partner { id name }
			}
			zone {
				id
				name
			}
			metadata {
				roomName
				roomNumber
				floorName
				floorNumber
				containerName
				containerNumber
				containerLocation
			}
		}
	}
`);