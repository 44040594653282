import { ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Anchor, Box, Image } from "grommet";
import { useState } from "react";
import { AddToCartIcon } from "..";
import { formatCurrency } from "../../../../helpers";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { ProductQuantityDialog } from "@features/shop/components/cart/ProductQuantityDialog";

interface CartProductListItemProps {
	productId: string;
	title: string;
	thumbnail: string;
	price: number;
	quantity: number;
	quantityAvailable: number;
}

export const CartProductListItem: React.FC<CartProductListItemProps> = (props) => {
	const dispatch = useAppDispatch();

	function handleSelectProduct() {
		dispatch(push(`/store/product/${props.productId}`));
	}

	const [ isUpdatingQuantity, setIsUpdatingQuantity ] = useState(false);

	function handleUpdateQuantity(event: React.MouseEvent<HTMLAnchorElement>) {
		event.stopPropagation();
		if(props.quantityAvailable > 1) {
			setIsUpdatingQuantity(true);
		}
	}

	return (
		<Box>
			{isUpdatingQuantity && (
				<ProductQuantityDialog
					onClose={() => setIsUpdatingQuantity(false)}
					productId={props.productId}
					quantity={props.quantity}
					quantityAvailable={props.quantityAvailable} />
			)}
			<ListItemButton
				divider
				onClick={handleSelectProduct}
			>
				<ListItemAvatar>
					<Box height="6em" width="6em">
						<Image
							src={props.thumbnail}
							fit="contain" />
					</Box>
				</ListItemAvatar>
				<ListItemText>
					<Box direction="row" justify="between" flex>
						<Box flex pad="small">
							<Typography fontWeight="bold" className="product-card-title">
								{props.title}
							</Typography>
							<Box direction="row" gap="xsmall">
								<Typography variant="caption">
									{formatCurrency(props.price)}
								</Typography>
								<Typography variant="caption">
									|
								</Typography>
								<Typography variant="caption">
									Qty: {props.quantity ?? 1}
								</Typography>
								{props.quantityAvailable > 1 && (
									<Typography variant="caption">
										|
									</Typography>
								)}
								{props.quantityAvailable > 1 && (
									<Typography variant="caption">
										<Anchor onClick={handleUpdateQuantity}>Edit</Anchor>
									</Typography>
								)}
							</Box>
						</Box>
						<Box align="end" justify="center">
							<AddToCartIcon
								productId={props.productId}
								sx={{}} />
						</Box>
					</Box>
				</ListItemText>
			</ListItemButton>
		</Box>
	);
};
