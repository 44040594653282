import { gql } from "../../../__generated__";

export const UpdateWasteAuditAnnotation = gql(/* GraphQL */ `
	mutation UpdateWasteAuditAnnotationMaterial(
		$materialId: String!,
		$annotationId: String!
	) {
		UpdateWasteAuditAnnotationMaterial(
			materialId: $materialId
			annotationId: $annotationId
		) {
			id
		}
	}
`);