import React from "react";
import { usePathParam, useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { useWasteAudit } from "../hooks";
import { TabController, ViewContainer } from "../../../../components";
import { Button, CircularProgress, Collapse, Skeleton, useTheme } from "@mui/material";
import { Fullscreen, FullscreenExit, Lock, LockOpen } from "@mui/icons-material";
import { Box, Heading } from "grommet";
import { AuditRecommendationsButton, AuditRecommendationsTabContent, AuditResultsTabContent, AuditSettingsButton } from "./AuditDetailsV2";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { WasteAuditDetailsContainer, WasteAuditZonesContent } from "../components";
import { UpdateWasteAuditDialog, BreadcrumbController } from "../components";

export const AuditDetailsV3: React.FC = () => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const auditTab = usePathParam("auditTab");
	const auditId = useRequiredPathParam("auditId", "/admin/audits");
	const { loading, audit } = useWasteAudit(auditId);
	const { width } = useWindowDimensions();
	const [ hideTitle, setHideTitle ] = React.useState(false);

	const [ isUpdateDialogOpen, setIsUpdateDialogOpen ] = React.useState(false);

	return (
		<ViewContainer>
			{isUpdateDialogOpen && (
				<UpdateWasteAuditDialog
					auditId={auditId}
					onClose={() => setIsUpdateDialogOpen(false)}
				/>
			)}
			<Box height="100%">
				<Box gap="small">
					<Box height={size === "small" ? "80px" : "30px"}>
						<Box
							gap={size === "small" ? "medium" : "small"}
							align={size === "small" ? "start" : "center"}
							justify="between"
							direction={size === "small" ? "column" : "row"} >
							<BreadcrumbController
								crumbs={[
									{
										label: "Audits",
										path: "/admin/audits"
									},
								]}
							/>
							<Box gap="small" direction="row" align="center" justify="end">
								<ToggleAuditPublishButton
									auditId={auditId}
								/>
								<AuditRecommendationsButton
									onClick={() => {
										dispatch(push(`/admin/audits/${auditId}/advisor`));
									}}
									auditId={auditId}
								/>
								<AuditSettingsButton
									onClick={() => setIsUpdateDialogOpen(true)}
								/>
								{size !== "small" && (
									<Button
										size="small"
										variant="outlined"
										onClick={() => setHideTitle(!hideTitle)}
										sx={{
											minWidth: "10px"
										}}
									>
										{hideTitle ? <Fullscreen /> : <FullscreenExit />}
									</Button>
								)}
							</Box>
						</Box>
					</Box>
					<Collapse
						in={!hideTitle}
					>
						<Box gap="small" height={size === "small" ? "100px" : "120px"}>
							{loading && (
								<Skeleton
									variant="rectangular"
									height={32}
								// width="100%"
								/>
							)}
							{!loading && (
								<Heading
									level="3"
									margin={"none"}
								>
									{audit?.name}
								</Heading>
							)}
							<Box width={`${width}px`}>
								<WasteAuditDetailsContainer
									auditId={auditId}
								/>
							</Box>
						</Box>
					</Collapse>
				</Box>
				<TabController
					overrideGap="small"
					defaultValue={auditTab || "zones"}
					value={auditTab || "zones"}
					onValueChange={(value) => {
						dispatch(push(`/admin/audits/${auditId}/${value}`));
					}}
					tabs={[
						{
							label: "Zones",
							value: "zones",
							component: (
								<WasteAuditZonesContent
									auditId={auditId}
									zoneId={"1"}
								/>
							)
						},
						{
							label: "Reporting",
							value: "reporting",
							component: (
								<AuditResultsTabContent
									auditId={auditId}
								/>
							)
						},
						{
							label: "Advisor",
							value: "advisor",
							component: (
								<Box height="100%" gap="xsmall">
									<Box flex>
										<AuditRecommendationsTabContent
											auditId={auditId}
										/>
									</Box>
								</Box>
							)
						}
					]}
				/>
			</Box>
		</ViewContainer>
	);
};

export const ToggleAuditPublishButton: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const theme = useTheme();
	const [ loading, setLoading ] = React.useState(false);
	const [ isPublished, setIsPublished ] = React.useState(false);

	function toggleLockStatus() {
		setLoading(true);
		setIsPublished(!isPublished);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}

	return (
		<Box align="center" justify="center">
			<Button
				size="small"
				color="warning"
				variant="outlined"
				startIcon={
					loading ? (
						<CircularProgress color="warning" size={16} />
					) : isPublished ? (
						<LockOpen />
					) : (
						<Lock />
					)
				}
				onClick={toggleLockStatus}
			>
				{isPublished ? "Unpublish" : "Publish"}
			</Button>
		</Box>
	);
};