import { Paper, Typography, useTheme } from "@mui/material";
import { Box, Heading, Spinner } from "grommet";
import React from "react";

export const ZoneMetricsCard: React.FC<{
	value: string;
	title: string;
	loading?: boolean;
}> = ({ value, title, loading }) => {
	const theme = useTheme();
	return (
		<Paper
			variant="outlined"
			sx={{
				":hover": {
					background: theme.palette.grey[ 200 ]
				},
				borderRadius: 1,
				padding: theme.spacing(1),
				background: theme.palette.grey[ 100 ]
			}}
		>
			<Box
				width="100%"
				height="100%"
				align="center"
				style={{ position: "relative" }}
			>
				<Box
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0
					}}
				>
					<Box flex align="center" justify="center">
						{loading && (
							<Spinner />
						)}
						{!loading && (
							<Heading level="3" margin="none">
								{value}
							</Heading>
						)}
					</Box>
				</Box>
				<Box
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0
					}}
				>
					<Box flex justify="end" align="center">
						<Typography
							variant="body2"
							fontWeight="bold"
						>
							{title}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};