import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { PublicContainerView } from "./views/Container";
import { useAppDispatch } from "../../store";
import { toggleHeader } from "../../store/theme";
import { PublicShareContainerController } from "./controller";
import { Box } from "grommet";

export const PublicRouter: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(toggleHeader(true));

		return () => {
			dispatch(toggleHeader(false));
		};
	}, [ dispatch ]);

	return (
		<Routes>
			<Route path="c/:containerId" element={<PublicShareContainerController />}>
				<Route path="" element={<PublicContainerView />} />
				<Route path="diversion" element={
					<Box>
						DIVERSION
					</Box>
				} />
				<Route path="contamination" element={
					<Box>
						CONTAMINATION
					</Box>
				} />
				<Route path="*" element={
					<Box>
						NOT FOUND
					</Box>
				} />
			</Route>
		</Routes>
	);
};