import React, { ReactNode, useMemo } from "react";
import { useWasteAudit } from "../../hooks";
import { Box } from "grommet";
import { noop, toProperCase } from "../../../../../helpers";
import moment from "moment-timezone";
import { Chip, Skeleton, Tooltip, Typography } from "@mui/material";
import { WrapSkeleton } from "../../../../../components";
import { Info } from "@mui/icons-material";

export const WasteAuditDetailsContainer: React.FC<{ auditId: string; }> = ({ auditId }) => {
	const { loading, audit } = useWasteAudit(auditId);

	const tooltipContent = useMemo(() => {
		return "This is a small description of the type";
	}, [ audit ]);

	return (
		<Box width="100%" overflow={{ horizontal: "hidden" }} id="details-container">
			<Box width="100%" direction="row" gap="small" overflow={{ horizontal: "scroll" }} id="details-scroll">
				<DetailsItem
					title="AUDIT TYPE"
					value={audit?.type ? toProperCase(audit.type) : "N/A"}
					loading={loading}
					tooltipContent={tooltipContent}
				/>
				<DetailsItem
					title="AUDIT LOCATION"
					loading={loading}
					value={audit?.partner.name || "N/A"}
				/>
				<DetailsItem
					title="AUDIT DATE"
					loading={loading}
					value={audit?.scheduledDate
						? moment.tz(audit.scheduledDate, "UTC").format("MMMM Do, YYYY")
						: "N/A"
					}
				/>
				<DetailsItem
					title="PUBLISH STATUS"
					loading={loading}
					value={"Published"}
					tooltipContent="Publish your audit to make it available in reporting."
				/>
			</Box>
		</Box>
	);
};

export const DetailsItem: React.FC<{
	title: string;
	value: ReactNode;
	loading?: boolean;
	tooltipContent?: string;
}> = ({ title, value, loading, tooltipContent }) => {
	return (
		<Box
			gap="xsmall"
			style={{ minWidth: "fit-content", maxWidth: "200px" }}
		>
			<Typography
				fontSize={".8rem"}
				variant="body2"
				color="text.secondary"
				ml={.50}
			>
				{title}
			</Typography>
			{loading && (
				<Skeleton
					variant="rounded"
					height={32}
					width={120}
				/>
			)}
			{!loading && (
				<Chip
					label={(
						<WrapSkeleton fillHeight isLoading={loading || false}>
							<Typography fontSize="1rem" fontWeight={500}>
								{value}
							</Typography>
						</WrapSkeleton>
					)}
					color="secondary"
					variant="outlined"
					deleteIcon={tooltipContent && (
						<Tooltip title={tooltipContent}>
							<Info />
						</Tooltip>
					) || undefined}
					onDelete={tooltipContent ? noop : undefined}
					sx={{
						// padding: 0,
						borderRadius: 2,
						// borderColor: "white"
					}}
				/>
			)}
		</Box >
	);
};