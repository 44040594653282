import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DialogWithClose, ViewContainer } from "../../../../components";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Heading, Meter, Spinner } from "grommet";
import { FormContainer, SelectElement, TextFieldElement, ToggleButtonGroupElement, useForm } from "react-hook-form-mui";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Button, InputAdornment, ListItem, ListItemText, Slider, Typography } from "@mui/material";
import { appendLeadingZero, formatNumber } from "../../../../helpers";
import { ContaminationRateChart } from "../components/ContaminationRateChart";
import { FetchWasteAuditTask } from "../../../../graphql/documents/reporting/queries/WasteAuditTask";
import { AnnotationSource, WasteAuditAggregationType } from "../../../../graphql/__generated__/graphql";
import { goBack } from "redux-first-history";
import { useMaterials } from "../../../product/hooks/useMaterials";
import { UpdateWasteAuditAnnotation } from "../../../../graphql/documents/reporting/mutations/UpdateAnnotation";
import { useSnackbar } from "notistack";
import { useWasteAuditCollection } from "../../../../graphql";

export type MaskData = {
	binaryMask: Uint8Array;
	originalWidth: number;
	originalHeight: number;
};

export const UpdateWeightDialog: React.FC<{
	weight: number;
	material: string;
	onUpdateWeight: (weight: number) => void;
}> = ({ weight, material, onUpdateWeight }) => {
	const formContext = useForm({
		defaultValues: {
			weight,
			material
		}
	});

	function handleSubmit() {
		const values = formContext.getValues();
		onUpdateWeight(values.weight);
	}

	return (
		<DialogWithClose
			title="Update Weight"
			onClose={() => onUpdateWeight(weight)}
			content={(
				<Box>
					<FormContainer formContext={formContext}>
						<Box gap="medium">
							<TextFieldElement
								name="material"
								label="Material"
								disabled
							/>
							<TextFieldElement
								name="weight"
								label="Weight"
								type="number"
								validation={{
									required: "Weight is required",
									min: 0,
									pattern: /^[0-9]+(\.[0-9]+)?$/
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											lb(s)
										</InputAdornment>
									)
								}}
							/>
						</Box>
					</FormContainer>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button color="error" variant="outlined" onClick={() => onUpdateWeight(weight)}>
						Cancel
					</Button>
					<Button color="primary" variant="contained" onClick={formContext.handleSubmit(handleSubmit)}>
						Update
					</Button>
				</Box>
			)}
		/>
	);
};

export function useWasteAuditTask(
	taskId: string,
	aggregateBy: WasteAuditAggregationType
) {
	const { data, loading } = useQuery(FetchWasteAuditTask, {
		variables: {
			taskId,
			aggregateBy
		},
		skip: !taskId
	});

	const task = useMemo(() => {
		return data?.FetchWasteAuditTask || null;
	}, [ data ]);

	return { task, loading };
}

export const InspectorView: React.FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const collectionId = useRequiredPathParam("collectionId", "/admin/dashboard");

	const {
		collection,
		loading: collectionLoading
	} = useWasteAuditCollection(collectionId);

	const [ taskId, setTaskId ] = useState("");

	useEffect(() => {
		if(collection) {
			if(collection.tasks.length <= 0) {
				goBack();
				return;
			}

			setTaskId(collection.tasks[ 0 ].id);
		}
	}, [ collection ]);

	const { task, loading: taskLoading } = useWasteAuditTask(
		taskId,
		WasteAuditAggregationType.Floor
	);

	const loading = useMemo(() => {
		return taskLoading || collectionLoading;
	}, [ taskLoading, collectionLoading ]);

	const annotations = useMemo(() => {
		return task?.annotations || [];
	}, [ task ]);

	const [ { width, height }, setDimensions ] = useState({ width: 0, height: 0 });

	const mediaUrl = useMemo(() => {
		return task?.media?.contentUrl || "";
	}, [ task ]);

	const image = useMemo(() => {
		const image = new Image();
		image.src = mediaUrl;

		return image;
	}, [ mediaUrl ]);

	useEffect(() => {
		const updateSize = () => {
			if(containerRef.current) {
				const { width, height } = containerRef.current.getBoundingClientRect();
				setDimensions({ width, height });
			}
		};

		// Use requestAnimationFrame to ensure layout is complete before getting the size
		const handleInitialSize = () => {
			requestAnimationFrame(updateSize);
		};

		// Call the function immediately to capture the initial size
		handleInitialSize();

		const resizeObserver = new ResizeObserver((entries) => {
			if(!entries || entries.length === 0) return;

			// Extract the width and height of the container
			const { width, height } = entries[ 0 ].contentRect;
			setDimensions({ width, height });
		});

		const current = containerRef.current;

		if(current) {
			resizeObserver.observe(containerRef.current);
		}

		// Clean up observer when component unmounts
		return () => {
			if(current) {
				resizeObserver.unobserve(current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty dependency array to only attach observer on mount

	const [ transparency, setTransparency ] = useState(0.0);
	const { mainContentHeight } = useWindowDimensions();
	const [ clickedAnnotationId, setClickedAnnotationId ] = useState("");

	const formContext = useForm({
		defaultValues: {
			view: "composition" as "composition" | "contamination",
			zone: "",
			floor: "",
			location: ""
		}
	});

	useEffect(() => {
		collection && formContext.reset({
			view: "composition",
			zone: collection.zone.name || "",
			floor: collection.metadata.floorName || "",
			location: collection.audit.partner.name || ""
		});
	}, [ collection, formContext ]);

	const { view } = formContext.watch();

	return (
		<ViewContainer>
			<Grid gap="small" columns={[ "1/3", "2/3" ]} height={`${mainContentHeight - 50}px`}>
				<Box height="100%" overflow={{ vertical: "scroll" }}>
					{clickedAnnotationId
						? (
							<BoundingBoxCanvas
								image={image}
								onClose={() => setClickedAnnotationId("")}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								annotation={annotations.find(a => a.id === clickedAnnotationId)!}
							/>
						)
						: (
							<FormContainer
								FormProps={{ style: { height: `${mainContentHeight - 50}px` } }}
								formContext={formContext}
							>
								<Box gap="medium" height="100%">
									<ToggleButtonGroupElement
										name="view"
										exclusive
										unselectable="on"
										fullWidth
										color="primary"
										options={[
											{ id: "composition", label: "Composition" },
											{ id: "contamination", label: "Contamination" }
										]}
									/>
									<Typography
										fontWeight="bold"
									>
										Attribution
									</Typography>
									<TextFieldElement
										name="location"
										label="Location"
									/>
									<TextFieldElement
										name="zone"
										label="Zone"
									/>
									{view === "contamination" && (
										<Fragment>
											<Typography
												fontWeight="bold"
												margin="small"
											>
												Contamination Rate
											</Typography>
											<Box align="center" justify="center" margin={{ top: "medium" }}>
												<Heading level={2} margin="none">
													{formatNumber((task?.metrics.contaminationRate || 0) * 100, 2)}%
												</Heading>
											</Box>
											<Box height="100%" align="center" justify="center">
												<ContaminationRateChart
													contaminationRate={(task?.metrics.contaminationRate || 0) * 100 || 0}
													stoplightConfig={[ 10, 20 ]}
												/>
											</Box>
										</Fragment>
									)}
									{view === "composition" && (
										<Fragment>
											<Typography fontWeight="bold">Materials</Typography>
											<Box height="100%" overflow={{ vertical: "scroll" }}>
												{Array.from(task?.metrics.materials || []).sort((a, b) => b.totalWeightGenerated - a.totalWeightGenerated).map(({ material, totalWeightGenerated, totalWeightRatio }) => (
													<ListItem key={material.id}>
														<Box>
															<Box direction="row" justify="between">
																<ListItemText>
																	<Typography fontWeight="bold">
																		{material.name}
																	</Typography>
																</ListItemText>
																<Box direction="row" gap="xsmall">
																	<Typography >
																		{formatNumber(
																			totalWeightGenerated,
																			2
																		)} lb(s)
																	</Typography>
																	<Typography>
																		|
																	</Typography>
																	<Typography>
																		{appendLeadingZero(
																			formatNumber(
																				totalWeightRatio * 100,
																				2
																			)
																		)}%
																	</Typography>
																</Box>
															</Box>
															<Box direction="row" align="center" gap="small">
																<Meter
																	value={totalWeightRatio * 100}
																/>
															</Box>
														</Box>
													</ListItem>
												))}
											</Box>
										</Fragment>
									)}
								</Box>
							</FormContainer>
						)}
				</Box>
				<Box height="100%" width="100%">
					<Box height="100%" align="center" justify="center" background="light-2" ref={containerRef}>
						{loading ? (
							<Spinner />
						) : (
							<SegmentationCanvas
								view={view}
								image={image}
								width={width}
								height={height}
								transparency={transparency}
								annotations={annotations}
								onClickedMask={setClickedAnnotationId}
							/>
						)}
					</Box>
					<Box height="50px" width="100%" justify="end">
						<Slider
							value={transparency}
							onChange={(_, value) => setTransparency(
								Array.isArray(value) ? value[ 0 ] : value
							)}
							aria-label="Transparency"
							step={0.05}
							min={0}
							max={1}
							valueLabelDisplay="on"
						/>
					</Box>
				</Box>

			</Grid>
		</ViewContainer>
	);
};

function updateAlphaInHsla(hslaColor: string, newAlpha: number): string {
	// Match and extract the hue, saturation, and lightness values using regex
	const hslRegex = /hsla?\((\d+),\s*(\d+)%,\s*(\d+)%.*\)/;
	const match = (hslaColor || "").match(hslRegex);

	if(match) {
		const hue = match[ 1 ];
		const saturation = match[ 2 ];
		const lightness = match[ 3 ];

		// Return the new hsla color string with updated alpha
		return `hsla(${hue}, ${saturation}%, ${lightness}%, ${newAlpha})`;
	}
	else {
		// If the input is not in the correct format, return the original color
		console.warn("Invalid HSLA color format");
		return hslaColor;
	}
}

function generateDistinctColorsWithAlpha(numColors: number, alpha: number): Array<string> {
	const colors = [];
	const saturation = 70; // Keep saturation constant for vibrant colors
	const lightness = 50;  // Keep lightness constant for equal brightness

	for(let i = 0; i < numColors; i++) {
		// Evenly space the hue values
		const hue = Math.floor((i * 360) / numColors);
		colors.push(`hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`);
	}

	return colors;
}

export const BoundingBoxCanvas: React.FC<{
	onClose?: () => void;
	image: HTMLImageElement,
	annotation: ResultOf<typeof FetchWasteAuditTask>[ "FetchWasteAuditTask" ][ "annotations" ][ 0 ];
}> = ({ image, annotation, onClose }) => {
	const ref = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const availableWidth = useMemo(() => {
		return containerRef.current?.clientWidth || 0;
	}, [ containerRef ]);

	const { scaledWidth, scaledHeight, scale } = useMemo(() => {
		// Calculate the scale factor to fit the image within the available width or maximum 300 pixels (height or width)
		const maxWidth = Math.min(availableWidth || 300, 300);
		const maxHeight = 300;

		const widthScale = maxWidth / image.width;
		const heightScale = maxHeight / image.height;

		const scale = Math.min(widthScale, heightScale);

		// Calculate the scaled width and height
		const width = image.width * scale;
		const height = image.height * scale;

		return { scaledWidth: width, scaledHeight: height, scale };
	}, [ availableWidth, image ]);

	const bbox = useMemo(() => {
		const { minX, minY, maxX, maxY } = annotation.contours.reduce((acc, contour) => {
			const [ x, y ] = contour;
			return {
				minX: Math.min(acc.minX, x),
				minY: Math.min(acc.minY, y),
				maxX: Math.max(acc.maxX, x),
				maxY: Math.max(acc.maxY, y)
			};
		}, { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity });

		return {
			x: minX,
			y: minY,
			width: maxX - minX,
			height: maxY - minY
		};
	}, [ annotation ]);

	const materials = useMaterials();

	const draw = useCallback((width: number, height: number, scale: number) => {
		if(!ref.current) return;
		const ctx = ref.current.getContext("2d");
		if(!ctx) return;

		const { x, y, width: boxWidth, height: boxHeight } = bbox;
		ctx.clearRect(0, 0, width, height);

		ctx.drawImage(
			image,        // image element
			x, y,       // source position (bounding box start)
			boxWidth, boxHeight, // source size (bounding box size)
			0, 0,       // destination position on canvas
			image.width * scale, image.height * scale // destination size on canvas (scaled)
		);
	}, [ bbox, image ]);

	useEffect(() => {
		if(image) {
			if(image.complete) {
				// If the image is already loaded, draw it immediately
				draw(scaledWidth, scaledHeight, scale);
			}
			else {
				// Listen for when the image is fully loaded, then draw it
				image.onload = () => {
					draw(scaledWidth, scaledHeight, scale);
				};
			}
		}
	}, [ image, draw, scaledWidth, scaledHeight, scale ]);

	const [
		updateAnnotation,
		{ loading: updateLoading }
	] = useMutation(UpdateWasteAuditAnnotation, {
		refetchQueries: [ FetchWasteAuditTask ]
	});

	const formContext = useForm({
		defaultValues: {
			source: annotation.source,
			material: annotation.material
		}
	});
	const { material } = formContext.watch();

	const snack = useSnackbar();

	useEffect(() => {
		const materialId = (typeof material === "string"
			? material
			: material?.id) || "";

		if(!materialId) return;
		if(!annotation) return;

		const annotationMaterialId = annotation.material?.id || "";

		if(annotationMaterialId !== materialId) {
			console.log("Updating material");
			// updateAnnotation({
			// 	variables: {
			// 		annotationId: annotation.id,
			// 		materialId: materialId
			// 	}
			// }).then(() => {
			// 	snack.enqueueSnackbar("Material updated", { variant: "success" });
			// }).catch(err => {
			// 	console.error(err);
			// 	snack.enqueueSnackbar("We ran into an issue updating your information.", { variant: "error" });
			// });
		}
	}, [ annotation, material, snack, updateAnnotation ]);

	useEffect(() => {
		formContext.reset({
			source: annotation.source || AnnotationSource.PredictionUpdated,
			material: annotation.material
		});
	}, [ annotation, formContext ]);

	return (
		<Box flex gap="medium" ref={containerRef}>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<Typography fontWeight="bold">
						Material Classification
					</Typography>
					<SelectElement
						name="material"
						label="Material"
						options={
							materials
								.filter(m => m.detectionClasses.length > 0)
								.map(material => ({
									id: material.id,
									label: material.name
								}))}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{updateLoading && <Spinner />}
								</InputAdornment>
							)
						}}
					/>
					<SelectElement
						name="source"
						label="Source"
						options={[
							{ id: AnnotationSource.Manual, label: "Manual" },
							{ id: AnnotationSource.Prediction, label: "Automated" },
							{ id: AnnotationSource.PredictionUpdated, label: "Automated (Corrected)" }
						]}
					/>
				</Box>
			</FormContainer>
			<Box flex justify="end" gap="medium">
				<Box align="center" justify="center" background="light-2">
					<canvas
						ref={ref}
						width={scaledWidth}
						height={scaledHeight}
						style={{
							width: `${scaledWidth}px`,
							height: `${scaledHeight}px`
						}}
					/>
				</Box>
				{onClose && (
					<Button color="primary" variant="contained" onClick={onClose}>
						Close
					</Button>
				)}
			</Box>
		</Box>
	);
};

export const SegmentationCanvas: React.FC<{
	view: "composition" | "contamination";
	width: number;
	height: number;
	transparency: number;
	image: HTMLImageElement;
	onClickedMask: (maskId: string) => void;
	annotations: ResultOf<typeof FetchWasteAuditTask>[ "FetchWasteAuditTask" ][ "annotations" ];
}> = ({ view, width, height, image, annotations, transparency, onClickedMask }) => {
	const ref = useRef<HTMLCanvasElement>(null);
	const [ hoveredAnnotationId, setHoveredAnnotationID ] = useState("");

	const masks = useMemo(() => {
		// const imageArea = width * height;
		return annotations.filter(annotation => {
			if(annotation.segmentationArea === undefined) return false;
			if(view === "contamination" && !annotation.isContamination) {
				return false;
			}

			return true;
		}).map((annotation) => {
			return {
				id: annotation.id,
				contours: annotation.contours.map(([ x, y ]) => ({ x, y }))
			};
		});
	}, [ annotations, view ]);

	const [ colors, setColors ] = useState<string[]>([]);

	const drawImage = useCallback((mouseX: number, mouseY: number) => {
		const canvas = ref.current;
		if(!canvas) return;
		const context = canvas.getContext("2d");

		if(context && image && image.complete) {
			const hRatio = width / image.width;
			const vRatio = height / image.height;
			const ratio = Math.min(hRatio, vRatio);
			const centerShift_x = (width - image.width * ratio) / 2;
			const centerShift_y = (height - image.height * ratio) / 2;
			context.clearRect(0, 0, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);

			let localColors = colors;
			if(!colors.length) {
				localColors = generateDistinctColorsWithAlpha(masks.length, transparency);
				setColors(localColors);
			}

			const pointsScaledAndShifted = masks.map((mask) => {
				return mask.contours.map(({ x, y }) => ({
					id: mask.id,
					x: x * ratio + centerShift_x,
					y: y * ratio + centerShift_y
				}));
			});

			for(let i = 0; i < pointsScaledAndShifted.length; i++) {
				const mask = pointsScaledAndShifted[ i ];
				context.fillStyle = (hoveredAnnotationId === masks[ i ].id)
					? updateAlphaInHsla(localColors[ i ], 1)
					: localColors[ i ];
				context.beginPath();
				context.moveTo(mask[ 0 ].x, mask[ 0 ].y);

				for(let j = 1; j < mask.length; j++) {
					context.lineTo(mask[ j ].x, mask[ j ].y);
				}

				context.closePath();
				context.fill();

				if(context.isPointInPath(mouseX, mouseY)) {
					// console.log(identifications.find(i => i.id === masks[ 0 ].id));
					setHoveredAnnotationID(mask[ 0 ].id);
				}
			}
		}
	}, [ colors, height, hoveredAnnotationId, image, masks, transparency, width ]);

	useEffect(() => {
		setColors(
			[ ...colors.map(color => updateAlphaInHsla(color, transparency)) ]
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ transparency ]);

	const handleMouseMove = useCallback((event: any) => {
		const { offsetX, offsetY } = event;
		drawImage(offsetX, offsetY);
	}, [ drawImage ]);

	const handleClick = useCallback((event: any) => {
		if(hoveredAnnotationId) {
			onClickedMask(hoveredAnnotationId);
		}
	}, [ hoveredAnnotationId, onClickedMask ]);

	useEffect(() => {
		const current = ref.current;
		current?.addEventListener("mousemove", handleMouseMove);
		current?.addEventListener("click", handleClick);

		return () => {
			current?.removeEventListener("mousemove", handleMouseMove);
			current?.removeEventListener("click", handleClick);
		};
	}, [ handleClick, handleMouseMove ]);

	useEffect(() => {
		if(image) {
			if(image.complete) {
				// If the image is already loaded, draw it immediately
				drawImage(0, 0);
			}
			else {
				// Listen for when the image is fully loaded, then draw it
				image.onload = () => {
					drawImage(0, 0);
				};
			}
		}
	}, [ image, drawImage ]);

	return (
		<canvas ref={ref} width={width} height={height} />
	);
};

interface RLE {
	counts: string;
	size: Array<number>;
}

interface Point {
	x: number;
	y: number;
}

const douglasPeucker = (points: Point[], epsilon: number): Point[] => {
	if(points.length < 3) return points;

	const dMax = (points: Point[], start: number, end: number): { index: number, dist: number; } => {
		let index = -1;
		let maxDist = 0;
		const startPt = points[ start ];
		const endPt = points[ end ];

		for(let i = start + 1; i < end; i++) {
			const pt = points[ i ];
			const dist = perpendicularDistance(pt, startPt, endPt);
			if(dist > maxDist) {
				index = i;
				maxDist = dist;
			}
		}
		return { index, dist: maxDist };
	};

	const perpendicularDistance = (point: Point, start: Point, end: Point): number => {
		const num = Math.abs((end.y - start.y) * point.x - (end.x - start.x) * point.y + end.x * start.y - end.y * start.x);
		const denom = Math.sqrt((end.y - start.y) ** 2 + (end.x - start.x) ** 2);
		return num / denom;
	};

	const simplify = (points: Point[], start: number, end: number, epsilon: number): Point[] => {
		const { index, dist } = dMax(points, start, end);

		if(dist > epsilon) {
			const left = simplify(points, start, index, epsilon);
			const right = simplify(points, index, end, epsilon);
			return [ ...left.slice(0, left.length - 1), ...right ];
		}
		else {
			return [ points[ start ], points[ end ] ];
		}
	};

	return simplify(points, 0, points.length - 1, epsilon);
};