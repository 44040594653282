import React from "react";
import { Box, Grid } from "grommet";
import { ZoneMetricsCard } from "./ZoneMetricsCard";
import { useWasteAuditZone } from "../../../../../../graphql";
import { formatNumber } from "../../../../../../helpers";

export const ZoneMetricsContainer: React.FC<{
	zoneId: string;
}> = ({ zoneId }) => {
	const { zone, loading } = useWasteAuditZone(zoneId);
	return (
		<Box height="100%" width="100%">
			<Grid height="100%" columns={{ count: 2, size: "auto" }} gap="small">
				<ZoneMetricsCard
					title="Total Uploads"
					loading={loading}
					value={String(zone?.collections.length || 0)}
				/>
				<ZoneMetricsCard
					title="Total Weight"
					loading={loading}
					value={
						formatNumber(zone?.collections.reduce((acc, curr) => acc + curr.totalWeight, 0) || 0) + " lb(s)"
					}
				/>
				<ZoneMetricsCard
					title="Diversion"
					value={
						formatNumber((zone?.metrics?.diversionRate || 0) * 100, 2) + "%"
					}
				/>
				<ZoneMetricsCard
					title="Contamination"
					value={
						formatNumber((zone?.metrics?.contaminationRate || 0) * 100, 2) + "%"
					}
				/>
			</Grid>
		</Box>
	);
};