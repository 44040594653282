import { List, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Box } from "grommet";
import React, { ReactNode } from "react";

export const CheckoutListItem: React.FC<{
	title: string;
	subtitle: string;
	details: string | ReactNode;
	onClick?: () => void;
}> = ({ title, subtitle, details, onClick }) => {
	return (
		<List disablePadding>
			<ListItemButton selected divider onClick={onClick}>
				<ListItemText>
					<Box gap="xsmall">
						<Typography fontWeight="bold">
							{title}
						</Typography>
						<Typography variant="caption">
							{subtitle}
						</Typography>
					</Box>
				</ListItemText>
				<ListItemSecondaryAction>
					{typeof details === "string" && (
						<Typography fontWeight="bold">
							{details}
						</Typography>
					)}
					{typeof details !== "string" && details}
				</ListItemSecondaryAction>
			</ListItemButton>
		</List>
	);
};