import { gql } from "../../../__generated__";

export const UpdateAddress = gql(/* GraphQL */ `
	mutation UpdateAddress(
		$addressId: String!
		$addressLineOne: String!
		$addressLineTwo: String
		$locality: String!
		$administrativeArea: String!
		$postalCode: String!
		$countryCode: String!
		$floor: Int!
		$hasParking: Boolean!
		$hasElevator: Boolean!
		$instructions: String
		$instructionsParking: String
		$lat: String!
		$lng: String!
		$timezone: String!
	) {
		UpdateAddress(
			addressId: $addressId
			addressLineOne: $addressLineOne
			addressLineTwo: $addressLineTwo
			locality: $locality
			administrativeArea: $administrativeArea
			postalCode: $postalCode
			countryCode: $countryCode
			floor: $floor
			hasParking: $hasParking
			hasElevator: $hasElevator
			instructions: $instructions
			instructionsParking: $instructionsParking
			lat: $lat
			lng: $lng
			timezone: $timezone
		) {
			id
		}
	}
`);